import format from "string-template";
import { REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validator = (messages) => {
  const requiredString = yup
    .string()
    .min(3, format(messages.MIN_LENGTH, { length: 3 }))
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);

  return yup.object().shape({
    contact: yup.object().shape({
      user: yup.object().shape({
        lastname: requiredString,
        firstname: requiredString,
      }),
    }),

    phoneNumber: yup.object().shape({
      value: yup
        .string()
        .typeError(messages.INVALID_POLYNISIE)
        .nullable()
        .test("isValid", messages.INVALID_POLYNISIE, async function (value) {
          return !value
            ? true
            : new RegExp(REGEX_EXPS.phonePolynisie).test(
                value?.replace(/\s+/g, "")
              );
        }),
    }),
  });
};

export default validator;
