import { Col, Row } from "antd";
import { get, isEmpty, set } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAddRoad,
  MdOutlineAlternateEmail,
  MdOutlineCall,
  MdOutlineCheckCircle,
  MdOutlineFlaky,
  MdOutlinePinDrop,
  MdOutlineSouthAmerica,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { useLazyQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { FRANCE_ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import { CONTACT_POINT_SYSTEM, SUBSCRIPTION_FORM_KEYS } from "utils/constants";

const Contact = ({
  formProps,
  values,
  setPercent,
  setFieldValue,
  isFieldRequired,
  initialValues,
  errors,
}) => {
  const [cities, setCities] = useState([]);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });
  const [addressZipCodeCity] = useLazyQuery(FRANCE_ADDRESS_ZIP_CODE_CITY);
  useEffect(() => {
    addressZipCodeCity({
      variables: {
        where: {
          zipCode: get(
            initialValues,
            `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
          ),
        },
      },
      onCompleted: ({ franceAddressZipCodeCity: { response } }) => {
        setCities(response);
      },
    });
  }, []);
  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.TITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Form
              type="highlight"
              title={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.ADDRESS.TITLE
              }
            >
              <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.ADDRESS.LABEL}
                    icon={<MdOutlineAddRoad size={16} />}
                    placeholder={SUBSCRIPTION.FORM.ADDRESS.PLACEHOLDER}
                    defaultValue={get(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                    )}
                    errors={
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                      ]
                    }
                    touched={
                      !isEmpty(
                        errors[
                          `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                        ]
                      )
                    }
                    onChange={({ target: { value } }) => {
                      set(
                        initialValues,
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                        value
                      );
                    }}
                    onBlur={() => setPercent(initialValues)}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.COMPLEMENT.LABEL}
                    optional={SUBSCRIPTION.FORM.OPTIONAL}
                    icon={<MdOutlineAddRoad size={16} />}
                    placeholder={SUBSCRIPTION.FORM.COMPLEMENT.PLACEHOLDER}
                    defaultValue={get(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                    )}
                    errors={
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                      ]
                    }
                    touched={
                      !isEmpty(
                        errors[
                          `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                        ]
                      )
                    }
                    onChange={({ target: { value } }) => {
                      set(
                        initialValues,
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`,
                        value
                      );
                    }}
                    onBlur={() => setPercent(initialValues)}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.POSTAL_CODE.LABEL}
                    icon={<MdOutlinePinDrop size={16} />}
                    placeholder={SUBSCRIPTION.FORM.POSTAL_CODE.PLACEHOLDER}
                    defaultValue={get(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                    )}
                    errors={
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                      ]
                    }
                    touched={
                      !isEmpty(
                        errors[
                          `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                        ]
                      )
                    }
                    disabled
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Select
                    icon={<MdOutlineSouthAmerica size={16} />}
                    label={SUBSCRIPTION.FORM.CITY.LABEL}
                    placeholder={SUBSCRIPTION.FORM.CITY.PLACEHOLDER}
                    theme="light"
                    showSearch
                    options={cities}
                    defaultValue={get(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                    )}
                    errors={
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                      ]
                    }
                    touched={
                      !isEmpty(
                        errors[
                          `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                        ]
                      )
                    }
                    onChange={(value) => {
                      set(
                        initialValues,
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`,
                        value
                      );
                    }}
                    onBlur={() => setPercent(initialValues)}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                    )}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col lg={24} xs={24}>
            <Form
              type="highlight"
              title={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.PHONE.TITLE
              }
              subtitle={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.PHONE.SUBTITLE
              }
            >
              <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                  <Alert
                    showIcon
                    type="info"
                    message={
                      SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.PHONE
                        .INFO
                    }
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.MOBILE.LABEL}
                    icon={<MdOutlineCall size={16} />}
                    placeholder={SUBSCRIPTION.FORM.MOBILE.PLACEHOLDER}
                    defaultValue={get(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                    )}
                    errors={
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                      ]
                    }
                    touched={
                      !isEmpty(
                        errors[
                          `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                        ]
                      )
                    }
                    onChange={({ target: { value } }) => {
                      set(
                        initialValues,
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                        value
                      );
                    }}
                    onBlur={() => setPercent(initialValues)}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.LANDLINE.LABEL}
                    optional={SUBSCRIPTION.FORM.OPTIONAL}
                    icon={<MdOutlineCall size={16} />}
                    placeholder={SUBSCRIPTION.FORM.LANDLINE.PLACEHOLDER}
                    defaultValue={get(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.LANDLINE}.value`
                    )}
                    errors={
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.LANDLINE}.value`
                      ]
                    }
                    touched={
                      !isEmpty(
                        errors[
                          `telecoms.contact.${CONTACT_POINT_SYSTEM.LANDLINE}.value`
                        ]
                      )
                    }
                    onChange={({ target: { value } }) => {
                      set(
                        initialValues,
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.LANDLINE}.value`,
                        value
                      );
                    }}
                    onBlur={() => setPercent(initialValues)}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.LANDLINE}.value`
                    )}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col lg={24} xs={24}>
            <Input
              type="switch"
              label={SUBSCRIPTION.FORM.TELETRANSMISSION.LABEL}
              icon={<MdOutlineCheckCircle size={16} />}
              defaultChecked={get(
                initialValues,
                "project.contact.fields.teletransmission"
              )}
              errors={errors["project.contact.fields.teletransmission"]}
              touched={
                !isEmpty(errors["project.contact.fields.teletransmission"])
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  "project.contact.fields.teletransmission",
                  value
                );
              }}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.EMAIL.LABEL}
              icon={<MdOutlineAlternateEmail size={16} />}
              placeholder={SUBSCRIPTION.FORM.EMAIL.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
              )}
              errors={
                errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`]
              }
              touched={
                !isEmpty(
                  errors[`telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`]
                )
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              type="radio"
              variant="group"
              icon={<MdOutlineFlaky size={16} />}
              label={SUBSCRIPTION.FORM.CONFIRM_CONTACT.LABEL}
              values={[
                {
                  value: true,
                  label: SUBSCRIPTION.FORM.CONFIRM_CONTACT.OPTIONS.YES,
                },
                {
                  value: false,
                  label: SUBSCRIPTION.FORM.CONFIRM_CONTACT.OPTIONS.NO,
                },
              ]}
              value={get(initialValues, "project.contact.acceptContact")}
              errors={errors["project.contact.acceptContact"]}
              touched={!isEmpty(errors["project.contact.acceptContact"])}
              onChange={(value) => {
                set(initialValues, "project.contact.acceptContact", value);
                setPercent(initialValues);
              }}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Alert
              showIcon
              type="info"
              message={SUBSCRIPTION.FORM.CONFIRM_CONTACT.INFO_SOLYON}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              type="radio"
              variant="group"
              icon={<MdOutlineFlaky size={16} />}
              label={SUBSCRIPTION.FORM.DIGITAL_OPTION.LABEL}
              values={[
                {
                  value: true,
                  label: SUBSCRIPTION.FORM.DIGITAL_OPTION.OPTIONS.YES,
                },
                {
                  value: false,
                  label: SUBSCRIPTION.FORM.DIGITAL_OPTION.OPTIONS.NO,
                },
              ]}
              value={get(initialValues, "project.contact.acceptDigital")}
              errors={errors["project.contact.acceptDigital"]}
              touched={!isEmpty(errors["project.contact.acceptDigital"])}
              onChange={(value) => {
                set(initialValues, "project.contact.acceptDigital", value);
                setPercent(initialValues);
              }}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Alert
              showIcon
              type="info"
              message={SUBSCRIPTION.FORM.DIGITAL_OPTION.INFO}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Contact;
