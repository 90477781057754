import { useState } from "react";
import HelpModal from "./HelpModal";
import close from "./svg/close.svg";
import contactUs from "./svg/contact-us.svg";

const ContactUs = ({ text, phone, help, setHelp }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <HelpModal open={open} setOpen={setOpen} />
      <div className="__LMF__contact">
        {help && (
          <a href={"tel:" + phone} className="__LMF__contact--need-help">
            {text}
          </a>
        )}
        <span className="__LMF__contact--img" onClick={() => setOpen(true)}>
          <img src={contactUs} />
        </span>
        {help && (
          <span
            className="__LMF__contact--close"
            onClick={() => setHelp(false)}
          >
            <img src={close} />
          </span>
        )}
      </div>
    </>
  );
};

export default ContactUs;
