import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper as SwiperReact, SwiperSlide } from "swiper/react";

function Swiper({ children, items, ...props }) {
  return (
    <div className="solyon-swiper relative">
      <SwiperReact
        slidesPerView={3}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        modules={[Navigation]}
        spaceBetween={16}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        breakpoints={{
          100: { slidesPerView: 1, initialSlide: 1 },
          320: { slidesPerView: 1, centeredSlides: true, initialSlide: 1 },
          520: { slidesPerView: 1, centeredSlides: true, initialSlide: 1 },
          640: { slidesPerView: 2 },
          768: { slidesPerView: 2 },
          840: { slidesPerView: 3 },
          1024: { slidesPerView: 3 },
          1099: { slidesPerView: 3 },
          1199: { slidesPerView: 3 },
        }}
        {...props}
      >
        {children.map((child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </SwiperReact>
    </div>
  );
}

export default React.memo(Swiper);
