import { get } from "lodash";
import { useMemo } from "react";
import { JOB_LIST } from "utils/constants";
import ViewActive from "./ViewActive";
import ViewFPH from "./ViewFPH";
import ViewRetired from "./ViewRetired";

const VIEWS = {
  DEFAULT: ViewActive,
  [JOB_LIST.RETIRED]: ViewRetired,
  [JOB_LIST.HOSPITAL_CIVIL_SERVICE]: ViewFPH,
};

const Income = ({ project, onNext, onBack }) => {
  const View = useMemo(
    () => VIEWS[project?.contact?.profession] || VIEWS.DEFAULT,
    [project?.contact?.profession]
  );
  return (
    <View
      contact={get(project, "contact", {})}
      onNext={onNext}
      onBack={onBack}
    />
  );
};

export default Income;
