import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "shared/hooks/useApi";
import { OFFERS } from "utils/api/graphql/queries/offers";
import Card from "./components/Card";
import DetailsDrawer from "./components/DetailsDrawer";
import ProductsDrawer from "./components/ProductsDrawer";
import useInsurancePlans from "./hooks/useInsurancePlans";

const List = ({
  selectedModules,
  setSelectedModules,
  cost,
  currency,
  insurancePlan,
  disabled,
  translation,
  project,
}) => {
  const { id: projectId } = useParams();
  const [showMore, setShowMore] = useState(null);
  const [openDetails, setOpenDetails] = useState(null);

  const [getOffers, { loading: offersLoading }] = useLazyQuery(OFFERS);
  const { selectedInsurancePlans, fetchMainOffers } = useInsurancePlans({
    insurancePlan,
    getOffers,
    project,
    setSelectedModules,
  });

  useQuery(OFFERS, {
    variables: {
      where: {
        AND: [
          { offerInsurancePlans: { insurancePlan: { id: insurancePlan.id } } },
          { offerInsurancePlans: { main: true } },
        ],
      },
    },
    onCompleted: fetchMainOffers,
  });
  const handleModuleCheck = (module) => {
    const id = get(module, "insurancePlan.id");
    setSelectedModules((prev) =>
      get(prev, insurancePlan.id)?.find(
        ({ insurancePlan }) => insurancePlan.id === id
      )
        ? {
            ...prev,
            [insurancePlan.id]: get(prev, insurancePlan.id, []).filter(
              ({ insurancePlan }) => insurancePlan.id !== id
            ),
          }
        : {
            ...prev,
            [insurancePlan.id]: [...get(prev, insurancePlan.id, []), module],
          }
    );
  };
  return (
    <>
      <Card
        onCheck={handleModuleCheck}
        selectedModules={selectedModules}
        products={selectedInsurancePlans}
        onOpenDetails={setOpenDetails}
        onShowMore={setShowMore}
        mainProduct={insurancePlan.id}
        translation={translation}
        loading={offersLoading}
        disabled={disabled}
      />
      {openDetails && (
        <DetailsDrawer
          open={openDetails}
          onClose={() => setOpenDetails(null)}
          filter={{
            where: {
              insurancePlan: { id: openDetails },
              project: { id: projectId },
            },
          }}
          translation={translation}
        />
      )}
      {showMore && (
        <ProductsDrawer
          open={showMore}
          onClose={() => setShowMore(false)}
          selectedInsurancePlans={selectedInsurancePlans}
          selectedModules={selectedModules}
          onCheck={handleModuleCheck}
          insurancePlan={insurancePlan}
          cost={cost}
          currency={currency}
          disabled={disabled}
          translation={translation}
        />
      )}
    </>
  );
};

export default List;
