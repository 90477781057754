import { useState } from "react";

import { Radio } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import Popover from "shared/components/Popover";
import Label from "./Label";

const Component = ({
  errors,
  touched,
  label,
  icon,
  onChange,
  values,
  value,
  required,
  size,
  subLabel,
  ...rest
}) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const onSelect = (e) => {
    setCheckedValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="solyon-radio">
      <Label
        label={label}
        subLabel={subLabel}
        icon={icon}
        required={required}
      />
      <Radio.Group
        onChange={onSelect}
        value={checkedValue}
        defaultValue={value}
        optionType="button"
        {...rest}
      >
        <div
          className={classNames({
            [`grid--${size}`]: !!size,
            "d-flex align--stretch justify--between flex--wrap": !size,
          })}
        >
          {values.map(
            ({
              label,
              value: _value,
              popover,
              subLabel,
              row,
              image,
              checkedImage,
            }) => (
              <div
                className="solyon-radio__button-container"
                key={`radio.${_value}`}
              >
                <Radio
                  value={_value}
                  className={`${_value === checkedValue && "checked"}`}
                >
                  {popover && <Popover text={popover} />}
                  <div className="solyon-radio__body">
                    {image && (
                      <img
                        src={_value === checkedValue ? checkedImage : image}
                        alt=""
                      />
                    )}
                    <div className="solyon-radio__body--top">
                      <div className="solyon-radio__label">{label}</div>
                      {subLabel && (
                        <div
                          className={classNames("solyon-radio__subLabel", {
                            row,
                          })}
                        >
                          {subLabel}
                        </div>
                      )}
                    </div>
                    <div className="solyon-radio__button" />
                  </div>
                </Radio>
              </div>
            )
          )}
        </div>
      </Radio.Group>
      {touched && errors && <p className={"input--errors"}>{errors}</p>}
    </div>
  );
};

Component.propTypes = {
  loading: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  placeholder: PropTypes.node,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.any,
    })
  ),
  value: PropTypes.any,
  size: PropTypes.number,
};

Component.defaultProps = {
  onChange: () => {},
  values: [],
};
export default Component;
