import { TEMPLATE_TYPES } from "utils/constants";
import DEFAULT from "./Default";
import B2C_LMF from "./LMF";
import RADIANCE from "./Radiance";
import RADIANCE_COSA from "./RadianceCosa";
import SOLYON from "./Solyon";

const Templates = {
  [TEMPLATE_TYPES.DEFAULT]: DEFAULT,
  [TEMPLATE_TYPES.RADIANCE]: RADIANCE,
  [TEMPLATE_TYPES.RADIANCE_COSA]: RADIANCE_COSA,
  [TEMPLATE_TYPES.B2C_SOLYON]: SOLYON,
  [TEMPLATE_TYPES.B2C_LMF]: B2C_LMF,
};
export default Templates;
