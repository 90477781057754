import { get } from "lodash";
import { useQuery } from "shared/hooks/useApi";
import { COVERAGE_CATEGORIES } from "utils/api/graphql/queries/coverage-categories";
import Loader from "../Components/Loader";
import View from "./View";

const Needs = ({ project, onNext, onBack }) => {
  const { data, loading } = useQuery(COVERAGE_CATEGORIES, {
    variables: { gt: { weight: 0 } },
  });
  if (loading) return <Loader />;
  return (
    <View
      categories={get(data, "coverageCategories.data") || []}
      fields={get(project, "fields", {})}
      onNext={onNext}
      onBack={onBack}
    />
  );
};

export default Needs;
