import classnames from "classnames";
import PropTypes from "prop-types";

const Checkbox = ({
  label,
  subLabel,
  errors,
  touched,
  icon,
  required,
  ...rest
}) => {
  const inputClass = classnames({
    "disable--input": rest.disabled,
  });

  return (
    <div className="solyon-checkbox">
      <input id={label} type="checkbox" className={inputClass} {...rest} />
      <div className="solyon-checkbox__label">
        <div className="solyon-checkbox__label--text">{label}</div>
        {subLabel && (
          <div className="solyon-checkbox__label--subLabel">{subLabel}</div>
        )}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

Checkbox.defaultProps = {
  label: "",
  placeholder: "",
};

export default Checkbox;
