import { Input } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import Label from "../Label";

const { TextArea } = Input;

const Textarea = ({
  label,
  placeholder,
  errors,
  touched,
  icon,
  required,
  ...rest
}) => (
  <div className={classNames("input__wrapper", { __error: touched && errors })}>
    <Label label={label} icon={icon} required={required} />
    <div className={`input__wrapper--field`}>
      <TextArea
        className={classNames({
          "input--borderd--red": touched && errors,
          "disable--input": rest.disabled,
        })}
        placeholder={placeholder}
        {...rest}
      />
      {touched && errors && <p className={"input--errors"}>{errors}</p>}
    </div>
  </div>
);

Textarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  icon: PropTypes.node,
};

Textarea.defaultProps = {
  label: "",
  placeholder: "",
};

export default Textarea;
