import { gql } from "@apollo/client";

export const INSURANCE_PLANS = gql`
  query InsurancePlans(
    $where: InsurancePlanWhereInput
    $take: Int
    $skip: Int
    $isIn: JSONObject
    $like: InsurancePlanWhereInput
    $orderBy: [InsurancePlanWhereInput]
  ) {
    insurancePlans(
      where: $where
      take: $take
      skip: $skip
      isIn: $isIn
      like: $like
      orderBy: $orderBy
    ) {
      data {
        id
        name
        alias
        icon
        description
        risk
        params {
          id
          rules {
            id
            name
            value
          }
        }
        fields
        config
        active
        internal
        commissions {
          id
          type
          value
          withholding
        }
      }
      count
    }
  }
`;

export const INSURANCE_PLAN = gql`
  query InsurancePlan($where: InsurancePlanWhereInput) {
    insurancePlan(where: $where) {
      id
      name
      alias
      icon
      description
      active
      internal
      isStandalone
      risk
      params {
        id
        rules {
          id
          name
          value
        }
      }
      fields
      config
      startDate
      endDate
      commissions {
        id
        type
        value
        withholding
      }
      supportingInformation {
        id
        category
        value
        weight
      }
    }
  }
`;

const products = { INSURANCE_PLANS, INSURANCE_PLAN };

export default products;
