import { Col, Row } from "antd";
import dayjs from "dayjs";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAddRoad,
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineMap,
  MdPeopleOutline,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  BIRTH_COUNTRY_PARAMS,
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  GENDER,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";
import { isMajor } from "utils/helpers/date";

const Adherent = ({
  formProps,
  values,
  setFieldValue,
  setPercent,
  isFieldRequired,
  setDisabled,
  initialValues,
  onFormSubmit,
  errors,
}) => {
  const { id } = useParams();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("ANSET_SUBSCRIPTION", { returnObjects: true });

  const BIRTH_COUNTRY = {
    [BIRTH_COUNTRY_PARAMS.FRANCE]: (value) => {
      set(
        initialValues,
        `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`,
        value
      );
    },
    [BIRTH_COUNTRY_PARAMS.OTHER]: () => {
      set(
        initialValues,
        `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`,
        null
      );
    },
  };

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ADHERENT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ADHERENT.TITLE}
        subtitle={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ADHERENT.SUBTITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              type="radio"
              variant="group"
              values={[
                {
                  value: GENDER.MALE,
                  label: SUBSCRIPTION.FORM.GENDER.OPTIONS.MR,
                },
                {
                  value: GENDER.FEMALE,
                  label: SUBSCRIPTION.FORM.GENDER.OPTIONS.MRS,
                },
              ]}
              label={
                <div className="subscription-form--group-label">
                  <MdPeopleOutline icon="man" size={16} />
                  <span className="group-label--text">
                    {SUBSCRIPTION.FORM.GENDER.LABEL}
                  </span>
                </div>
              }
              value={get(initialValues, "project.contact.user.gender")}
              errors={errors["project.contact.user.gender"]}
              touched={!isEmpty(errors["project.contact.user.gender"])}
              onChange={(value) => {
                set(initialValues, "project.contact.user.gender", value);
                setPercent(initialValues);
              }}
              required={isFieldRequired("project.contact.user.gender")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
              label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
              icon={<MdOutlineBadge size={16} />}
              defaultValue={get(initialValues, "project.contact.user.lastname")}
              errors={errors["project.contact.user.lastname"]}
              touched={!isEmpty(errors["project.contact.user.lastname"])}
              required={isFieldRequired("project.contact.user.lastname")}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.contact.user.lastname", value);
              }}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.BIRTH_NAME.PLACEHOLDER}
              label={SUBSCRIPTION.FORM.BIRTH_NAME.LABEL}
              icon={<MdOutlineBadge size={16} />}
              defaultValue={get(
                initialValues,
                "project.contact.user.birthName"
              )}
              errors={errors["project.contact.user.birthName"]}
              touched={!isEmpty(errors["project.contact.user.birthName"])}
              required={isFieldRequired("project.contact.user.birthName")}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.contact.user.birthName", value);
              }}
              onBlur={() => {
                setPercent(initialValues);
              }}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
              icon={<MdOutlineBadge size={16} />}
              placeholder={SUBSCRIPTION.FORM.FIRST_NAME.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                "project.contact.user.firstname"
              )}
              errors={errors["project.contact.user.firstname"]}
              touched={!isEmpty(errors["project.contact.user.firstname"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.contact.user.firstname", value);
              }}
              required={isFieldRequired("project.contact.user.firstname")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              icon={<MdOutlineCake size={22} />}
              label={SUBSCRIPTION.FORM.BIRTH_DATE.LABEL}
              placeholder={SUBSCRIPTION.FORM.BIRTH_DATE.PLACEHOLDER}
              type="date"
              variant="simple"
              format={DATE_FORMAT}
              disabledDate={(current) => current > isMajor}
              defaultPickerValue={
                !get(values, "project.contact.user.birthDate") &&
                dayjs().subtract(30, "years")
              }
              defaultValue={
                get(initialValues, "project.contact.user.birthDate")
                  ? dayjs(get(initialValues, "project.contact.user.birthDate"))
                  : null
              }
              onBlur={({ target: { value } }) => {
                const date = dayjs(value, DATE_FORMAT);
                if (date.isValid())
                  set(
                    initialValues,
                    "project.contact.user.birthDate",
                    dayjs(value, DATE_FORMAT)
                  );
                setPercent(initialValues);
              }}
              value={
                get(initialValues, "project.contact.user.birthDate")
                  ? dayjs(get(initialValues, "project.contact.user.birthDate"))
                  : null
              }
              errors={errors["project.contact.user.birthDate"]}
              touched={!isEmpty(errors["project.contact.user.birthDate"])}
              onChange={(date) => {
                set(initialValues, "project.contact.user.birthDate", date);
              }}
              required={isFieldRequired("project.contact.user.birthDate")}
            />
          </Col>
          <Col lg={12} xs={12}>
            <Input
              label={SUBSCRIPTION.FORM.DEPARTMENT_OF_BIRTH.LABEL}
              icon={<MdOutlineAddRoad size={16} />}
              placeholder={SUBSCRIPTION.FORM.DEPARTMENT_OF_BIRTH.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`
              )}
              errors={
                errors[
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`
                ]
              }
              touched={
                !isEmpty(
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`
                  ]
                )
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`
              )}
            />
          </Col>
          <Col lg={12} xs={12}>
            <Input
              label={SUBSCRIPTION.FORM.BIRTH_ZIP_CODE.LABEL}
              icon={<MdOutlineAddRoad size={16} />}
              placeholder={SUBSCRIPTION.FORM.BIRTH_ZIP_CODE.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_ZIP_CODE}.value`
              )}
              errors={
                errors[
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_ZIP_CODE}.value`
                ]
              }
              touched={
                !isEmpty(
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_ZIP_CODE}.value`
                  ]
                )
              }
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_ZIP_CODE}.value`,
                  value
                );
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_ZIP_CODE}.value`
              )}
            />
          </Col>
          <Col lg={get(initialValues, `birthCountry`) === "OTHER" ? 12 : 24}>
            <Select
              icon={<MdOutlineMap size={16} />}
              label={SUBSCRIPTION.FORM.BIRTH_COUNTRY.LABEL}
              placeholder={SUBSCRIPTION.FORM.BIRTH_COUNTRY.PLACEHOLDER}
              showSearch
              options={Object.entries(SUBSCRIPTION.FORM.COUNTRIES).map(
                ([value, label]) => ({ value, label })
              )}
              defaultValue={get(initialValues, `birthCountry`)}
              errors={errors[`birthCountry`]}
              touched={!isEmpty(errors[`birthCountry`])}
              onChange={(value) => {
                set(initialValues, `birthCountry`, value);
                BIRTH_COUNTRY[value](value);
                setPercent(initialValues);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired(`birthCountry`)}
            />
          </Col>
          {get(initialValues, `birthCountry`) === "OTHER" && (
            <Col lg={12}>
              <Input
                placeholder={SUBSCRIPTION.FORM.OTHER_BIRTH_COUNTRY.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.OTHER_BIRTH_COUNTRY.LABEL}
                icon={<MdOutlineMap size={16} />}
                defaultValue={get(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`
                )}
                errors={
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`
                  ]
                }
                touched={
                  !isEmpty(
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`
                    ]
                  )
                }
                required={isFieldRequired(
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`
                )}
              />
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default Adherent;
