import { CONTACT_POINT_SYSTEM } from "utils/constants";
import * as yup from "yup";

const validation = (messages) => {
  const string = yup.string().typeError(messages.REQUIRED);
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  return yup.object().shape({
    telecoms: yup.object().shape({
      [CONTACT_POINT_SYSTEM.STREET]: yup.object().shape({
        value: requiredString,
      }),
      [CONTACT_POINT_SYSTEM.ADDRESS_LOCATION]: yup.object().shape({
        value: string,
      }),
      [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
        value: requiredString,
      }),
      [CONTACT_POINT_SYSTEM.CITY]: yup.object().shape({
        value: requiredString,
      }),
      [CONTACT_POINT_SYSTEM.COMPLEMENT]: yup.object().shape({
        value: string,
      }),
      [CONTACT_POINT_SYSTEM.RESIDENCE]: yup.object().shape({
        value: string,
      }),
    }),
  });
};

export default validation;
