import { DatePicker } from "antd";
import locale from "antd/locale/fr_FR";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdCalendarMonth, MdOutlineKeyboard } from "react-icons/md";
import { NAME_SPACES } from "shared/locales/constants";
import Label from "../../Label";

const Simple = ({
  label,
  errors,
  icon,
  touched,
  props,
  direction,
  required,
  optional,
  ...rest
}) => {
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const DATE_PICKER = t("DATE_PICKER", { returnObjects: true });

  const [clickCount, setClickCount] = useState(0);
  const [showHint, setShowHint] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const formatDate = (event) => {
    const input = event.target;
    const inputValue = input.value.replace(/\D/g, "");
    let formattedDate = inputValue;
    if (inputValue.length > 2)
      formattedDate = `${inputValue.slice(0, 2)}/${inputValue.slice(2)}`;
    if (inputValue.length > 4)
      formattedDate = `${formattedDate.slice(0, 5)}/${inputValue.slice(4)}`;
    if (inputValue.length > 8) formattedDate = formattedDate.slice(0, 10);
    input.value = formattedDate;
  };

  const handleCalendarClick = () => {
    setClickCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (clickCount >= 3) {
      setShowHint(true);
    }
  }, [clickCount]);

  useEffect(() => {
    const datePickerButtons = document.querySelectorAll(".ant-picker-header");

    if (isCalendarOpen) {
      datePickerButtons.forEach((button) => {
        button.addEventListener("click", handleCalendarClick);
      });
    } else {
      datePickerButtons.forEach((button) => {
        button.removeEventListener("click", handleCalendarClick);
      });
    }

    return () => {
      datePickerButtons.forEach((button) => {
        button.removeEventListener("click", handleCalendarClick);
      });
    };
  }, [isCalendarOpen]);

  const handleOpenChange = (open) => {
    setIsCalendarOpen(open);
    if (!open) {
      setClickCount(0);
      setShowHint(false);
    }
  };

  return (
    <div
      className={classnames(
        "solyon-date-picker",
        {
          "solyon-date-picker__error": touched && errors,
        },
        direction
      )}
    >
      <Label
        label={label}
        icon={icon}
        required={required}
        optional={optional}
      />
      <div className={`solyon-date-picker--field`}>
        <DatePicker
          suffixIcon={<MdCalendarMonth size={20} />}
          locale={locale}
          {...props}
          className={classnames({ "disabled--input": rest.disabled })}
          {...rest}
          onKeyDown={formatDate}
          onOpenChange={handleOpenChange}
          renderExtraFooter={() =>
            showHint && (
              <p className="datePicker-hint-text">
                <MdOutlineKeyboard size={24} />
                <p>{DATE_PICKER.HINT}</p>
              </p>
            )
          }
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Simple;
