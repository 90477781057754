import { CONTACT_POINT_SYSTEM, REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (messages) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  return yup.object().shape({
    contact: yup.object().shape({
      user: yup.object().shape({
        firstname: requiredString,
        lastname: requiredString,
      }),
      fields: yup.object().shape({
        acceptDigital: yup.boolean().oneOf([true], " "),
        acceptContact: yup.boolean().oneOf([true], " "),
      }),
      telecoms: yup.object().shape({
        [CONTACT_POINT_SYSTEM.EMAIL]: yup.object().shape({
          value: yup
            .string()
            .nullable()
            .email(messages.EMAIL)
            .required(messages.REQUIRED),
        }),
        [CONTACT_POINT_SYSTEM.PHONE]: yup.object().shape({
          value: yup
            .string()
            .typeError(messages.REQUIRED)
            .required(messages.REQUIRED)
            .test("isValid", messages.INVALID_VALUE, async function (value) {
              return new RegExp(REGEX_EXPS.phone).test(value);
            }),
        }),
      }),
    }),
  });
};

export default validation;
