import Loading from "shared/components/Spin";
import { TEMPLATES } from "utils/constants";
import AdherentAddress from "./AdherentAddress";
import AdherentDetails from "./AdherentDetails";
import AdherentInfo from "./AdherentInfo";
import AdherentTelecoms from "./AdherentTelecoms";
import CollectionNeeds from "./CollectionNeeds";
import Default from "./Default";
import EffectiveDate from "./EffectiveDate";
import Products from "./Products";
import Profile from "./Profile";
import Reinforcement from "./Reinforcement";
import SocialSecurityNumber from "./SocialSecurityNumber";
import ThankYou from "./ThankYou";

const Templates = {
  Loading: <Loading />,
  [TEMPLATES.B2C.THEMES.B2C_LMF.PROFILE]: Profile,
  [TEMPLATES.B2C.THEMES.B2C_LMF.CONTACT]: AdherentInfo,
  [TEMPLATES.B2C.THEMES.B2C_LMF.COLLECTION_NEEDS]: CollectionNeeds,
  [TEMPLATES.B2C.THEMES.B2C_LMF.PRODUCTS]: Products,
  [TEMPLATES.B2C.THEMES.B2C_LMF.ADHERENT_TELECOMS]: AdherentTelecoms,
  [TEMPLATES.B2C.THEMES.B2C_LMF.THANK_YOU]: ThankYou,
  [TEMPLATES.B2C.THEMES.B2C_LMF.ADHERENT_DETAILS]: AdherentDetails,
  [TEMPLATES.B2C.THEMES.B2C_LMF.MODULES]: Reinforcement,
  [TEMPLATES.B2C.THEMES.B2C_LMF.EFFECTIVE_DATE]: EffectiveDate,
  [TEMPLATES.B2C.THEMES.B2C_LMF.ADDRESS_ADHERENT]: AdherentAddress,
  [TEMPLATES.B2C.THEMES.B2C_LMF.SOCIAL_SECURITY_NUMBER]: SocialSecurityNumber,
  default: Default,
};
export default Templates;
