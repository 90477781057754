import cuid from "cuid";
import { find, get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { filterByConditions } from "screens/Private/Broker/Projects/Detail";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  ACTION_TYPES,
  PROCESS_TYPES,
  SCREENS,
  TEMPLATES,
  TEMPLATE_TYPES,
} from "utils/constants";
import View from "./View";

const Cart = ({ tracer, contact, modules, project, subscriptionId }) => {
  const [loading, setLoading] = useState(true);
  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT);
  const [getSubscription, { data }] = useLazyQuery(
    graphql.queries.SUBSCRIPTION
  );

  const [getGeneralCost, { data: generalCost }] = useLazyQuery(
    graphql.queries.GENERAL_COST,
    {
      variables: {
        where: {
          project: { id: get(data, "subscription.project.id") },
          insurancePlan: {
            id: get(data, "subscription.insurancePlan.id"),
          },
        },
      },
    }
  );

  useEffect(() => {
    if (subscriptionId)
      getSubscription({
        variables: { where: { id: subscriptionId } },
        onCompleted: ({ subscription: data }) => {
          getGeneralCost();
          const subscription = omitDeep(data, "__typename");
          if (isEmpty(subscription)) return;
          setLoading(false);
        },
      });
  }, []);
  const navigate = useNavigate();

  const findStatus = (data, template) => {
    return find(data, ({ template: _ }) => _ === template);
  };

  const onEdit = () => {
    updateProjectStatus({
      template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_LMF].PRODUCTS,
    });
  };

  const onProfileEdit = () => {
    updateProjectStatus({
      template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_LMF]
          .ADHERENT_DETAILS,
    });
  };

  const updateProjectStatus = ({ template }) => {
    const status = findStatus(
      get(tracer, "flow.process.processStatuses", []),
      template
    );
    const actionsToExecute = filterByConditions(
      get(status, "actions", []),
      project
    );
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({
        id: cuid(),
        status: { id: status },
      }));
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          statuses: [{ id: cuid(), status: { id: status.id } }, ...syncActions],
        },
      },
      onCompleted: ({ updateProject }) => {
        navigate(
          `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${updateProject?.id}`,
          {
            state: {
              process: get(omitDeep(tracer, "__typename"), "flow.process"),
              tracer: omitDeep(tracer, "__typename"),
            },
          }
        );
      },
    });
  };

  if (loading) return <span></span>;

  return (
    <View
      onEdit={onEdit}
      onProfileEdit={onProfileEdit}
      contact={contact}
      modules={
        modules ||
        get(
          project,
          `fields.upsells.${get(data, "subscription.insurancePlan.id")}`
        )
      }
      generalCost={get(generalCost, "generalCost")}
    />
  );
};

export default Cart;
