import { get } from "lodash";
import { useState } from "react";
import { CURRENCY_SYMBOL, INCOME_TYPES } from "utils/constants";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";

function View({ contact, onNext, onBack }) {
  const [selectedValue, setSelectedValue] = useState({
    income: get(contact, "fields.income"),
    incomeType: INCOME_TYPES.ANNUAL,
    establishment: undefined,
    activityTime: undefined,
    cappedIndex: undefined,
    situation: undefined,
    optMadelin: undefined,
  });
  return (
    <div className="solyon">
      <div className="solyon__container">
        <Header
          title="Quel est votre revenus annuel ?"
          highlight="revenus annuel"
          subtitle="Informations de l’adhérent"
          onBack={onBack}
        />
        <div className="solyon__body">
          <Input
            width={242}
            suffix={CURRENCY_SYMBOL.EUR}
            type="number"
            placeholder={"0,00"}
            label={"Revenus annuel"}
            defaultValue={selectedValue.income}
            onChange={({ target: { value } }) =>
              setSelectedValue((prev) => ({ ...prev, income: value }))
            }
          />

          <Button
            type={"primary"}
            alignment={"center"}
            disabled={!selectedValue.income}
            onClick={() =>
              onNext({
                payload: {
                  contact: {
                    ...contact,
                    fields: {
                      ...get(contact, "fields", {}),
                      ...selectedValue,
                    },
                  },
                },
              })
            }
          >
            Continuer
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default View;
