import { Select } from "antd";
import classNames from "classnames";
import Label from "../Label";

const Simple = ({
  label,
  errors,
  icon,
  touched,
  required,
  direction,
  optional,
  optionFilterProp = "label",
  theme,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        "solyon-select",
        { "solyon-select__error": touched && errors },
        direction,
        theme
      )}
    >
      <Label
        label={label}
        icon={icon}
        required={required}
        optional={optional}
      />
      <div className={`solyon-input--field`}>
        <Select
          size="large"
          className={classNames({ "disable--input": rest.disabled })}
          optionFilterProp={optionFilterProp}
          {...rest}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Simple;
