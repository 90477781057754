import { get } from "lodash";
import * as yup from "yup";

const validation = (errors, { formula }) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);
  return yup.object().shape({
    formula: requiredString,
    ...(get(formula, "value") === "CUSTOM" && {
      budget: yup.number().typeError(errors.REQUIRED).required(errors.REQUIRED),
    }),
  });
};

export default validation;
