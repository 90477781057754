import PropTypes from "prop-types";
import React from "react";

function Card({ children }) {
  return (
    <div className="solyon-card">
      <div className="solyon-card__container">{children}</div>
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  highlight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  subtitle: PropTypes.string,
  onBack: PropTypes.func,
};

export default React.memo(Card);
