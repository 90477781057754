import { Radio } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";

const Card = ({
  errors,
  touched,
  label,
  icon,
  onChange,
  values,
  value,
  required,
  subLabel,
  style,
  ...rest
}) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const onSelect = ({ target: { value } }) => {
    setCheckedValue(value);
    onChange(value);
  };

  return (
    <div className="b2c-radio b2c-lmf-radio--card">
      <Radio.Group
        onChange={onSelect}
        value={checkedValue}
        defaultValue={value}
        optionType="button"
        {...rest}
      >
        {values.map(
          ({ label, icon, value: _value, popover, subLabel, row }) => (
            <Radio
              value={_value}
              className={`custom--item  ${
                _value === checkedValue && "checked"
              }`}
              key={`radio-${_value}`}
            >
              <img src={icon} />
              <p className="radio-title">{label}</p>
              <p className="sub-label">{subLabel}</p>
            </Radio>
          )
        )}
      </Radio.Group>
      {touched && errors && <p className={"input--errors"}>{errors}</p>}
    </div>
  );
};

Card.propTypes = {
  loading: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  placeholder: PropTypes.node,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.any,
    })
  ),
  value: PropTypes.any,
};

Card.defaultProps = {
  onChange: () => {},
  values: [],
};
export default Card;
