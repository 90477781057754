import Icon from "@ant-design/icons";
import CheckCircleFilled from "@ant-design/icons/CheckCircleFilled";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import FireFilled from "@ant-design/icons/FireFilled";
import { Col, Divider, Modal, Row, Table, Tag } from "antd";
import axios from "axios";
import cuid from "cuid";
import { find, get } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdCheckCircleOutline, MdOutlineDeleteOutline, MdOutlineLocalFireDepartment } from "react-icons/md";
import Select from "shared/components/Form/Select";
import Header from "shared/components/Header/Header";
import Input from "shared/components/Input";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import useSubscription from "shared/services/subscriptions";
import { names } from "shared/services/subscriptions/constants";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import {
  CONTACT_POINT_SYSTEM,
  CONTACT_TYPES,
  DATE_TIME_FORMAT,
  MOMENT_UNITS_IN_MINUTES,
  RELATIONSHIP,
} from "utils/constants";

function List() {
  const [where, setWhere] = useState({});
  const [visible, setVisible] = useState(false);
  const [note, setNote] = useState(null);
  const [contact, setContact] = useState(null);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.CONTACT);
  useSubscription({
    query: names.CONTACTS,
    variables: {
      where: {},
      orderBy: [{ createdDate: "desc" }],
    },
  });
  const LIST = t("LIST", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.CONTACTS, {
    variables: { where },
  });
  const [createContact] = useMutation(graphql.mutations.CREATE_CONTACT, {
    onCompleted: () => { },
    onError: () => { },
  });
  const [updateContact] = useMutation(graphql.mutations.UPDATE_CONTACT, {
    onCompleted: () => { },
    onError: () => { },
  });
  const [createNote] = useMutation(graphql.mutations.CREATE_NOTE, {
    onCompleted: () => {
      setNote(null);
      setContact(null);
      setVisible(false);
    },
    onError: () => { },
  });
  const [deleteNote] = useMutation(graphql.mutations.DELETE_NOTE, {
    onCompleted: () => { },
    onError: () => { },
  });
  const createFakeContact = () => {
    axios.post(
      `${process.env.REACT_APP_REST_BFF_URL.replace(
        "graphql",
        ""
      )}hooks/contacts/fake`,
      {}
    );
  };
  const columns = [
    {
      title: LIST.TABLE.NAME,
      dataIndex: "name",
      render: (_, __) => (
        <span>
          {`${get(__, "user.firstname", "")} ${get(__, "user.lastname", "")}`}
        </span>
      ),
    },
    {
      title: LIST.TABLE.CREATED_AT,
      dataIndex: "createdDate",
      render: (_, __) => (
        <span>{moment(__.createdDate).format(DATE_TIME_FORMAT)}</span>
      ),
    },
    {
      title: LIST.TABLE.QUALIFICATION.TITLE,
      dataIndex: "qualification",
      render: (_, __) => {
        const states = {
          [moment().diff(moment(__.createdDate), "m") <=
            MOMENT_UNITS_IN_MINUTES.ONE_HOUR]: {
            label: LIST.TABLE.QUALIFICATION.HOT,
            color: "red",
          },
          [moment().diff(moment(__.createdDate), "m") >
            MOMENT_UNITS_IN_MINUTES.ONE_HOUR &&
            moment().diff(moment(__.createdDate), "m") <=
            12 * MOMENT_UNITS_IN_MINUTES.ONE_HOUR]: {
            label: LIST.TABLE.QUALIFICATION.MEDIUM,
            color: "orange",
          },
          [moment().diff(moment(__.createdDate), "m") >
            12 * MOMENT_UNITS_IN_MINUTES.ONE_HOUR]: {
            label: LIST.TABLE.QUALIFICATION.COLD,
            color: "transparent",
          },
        };
        return (
          <span>
            {states[true].label}{" "}
            <MdOutlineLocalFireDepartment
              color={states[true].color}
              size={24}
            />
          </span>
        );
      },
    },
    {
      title: LIST.TABLE.STATUS,
      dataIndex: "qualifier",
      render: (_) => <Tag>{LIST.TABLE.TYPES[_]}</Tag>,
    },
    {
      title: LIST.TABLE.INFO,
      dataIndex: "info",
      render: (_, __) => (
        <>
          <Row>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.GENDER}</b>: {get(__, "user.gender", "")}
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.ZIP_CODE}</b>:{" "}
              {
                find(get(__, "telecoms", []), {
                  system: CONTACT_POINT_SYSTEM.ZIP_CODE,
                })?.value
              }
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.PREFIX}</b>:{" "}
              {get(__, "fields.indicative", "")}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.PHONE}</b>:{" "}
              {
                find(get(__, "telecoms", []), {
                  system: CONTACT_POINT_SYSTEM.PHONE,
                })?.value
              }
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.OPTICAL}</b>:{" "}
              {get(__, "fields.needs.optique", "-----")}
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.DENTAL}</b>:{" "}
              {get(__, "fields.needs.dental", "-----")}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.HOSPITALIZATION}</b>:{" "}
              {get(__, "fields.needs.hospitalization", "-----")}
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.MEDICAL_CARE}</b>:{" "}
              {get(__, "fields.needs.medicalCare", "-----")}
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.HEARING_AIDS}</b>:{" "}
              {get(__, "fields.needs.hearingAids", "-----")}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.SOCIAL_REGIME}</b>:{" "}
              {get(__, "fields.socialRegime", "-------")}
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.RETIRED}</b>:{" "}
              {get(__, "fields.retirement", "-------")}
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.REST_RETIREMENT}</b>:{" "}
              {get(__, "fields.yearsToRetirement", "-------")}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.MARITAL_STATUS}</b>:{" "}
              {get(__, "fields.situation", "-------")}
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.SPOUSE}</b>:{" "}
              {find(
                get(__, "relatedPersons", [], {
                  relationship: RELATIONSHIP.SPOUSE,
                }).identity
              )
                ? "YES"
                : "NON"}
            </Col>
            <Col span={8}>
              <b>{LIST.TABLE.INFOS.EMAIL}</b>: {get(__, "user.email", "")}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: LIST.TABLE.ACTIONS.TITLE,
      render: (_, __) => (
        <Tag
          onClick={() => {
            setContact({ id: __.id });
            setVisible(true);
          }}
        >
          {LIST.TABLE.ACTIONS.NOTES.CREATE}
        </Tag>
      ),
    },
    {
      title: "",
      render: (_, __) => (
        <Select
          value={__.qualifier}
          items={Object.keys(CONTACT_TYPES).map((el) => ({
            label: LIST.TABLE.TYPES[el],
            value: el,
          }))}
          onChange={(___) => {
            return updateContact({
              variables: { where: { id: __.id }, data: { qualifier: ___ } },
            });
          }}
        />
      ),
    },
  ];
  const expandedRowRender = (row) => {
    const columns = [
      {
        title: "Notes",
        dataIndex: "content",
        render: (_, __) => {
          return (
            <span>
              <MdOutlineDeleteOutline
                size={16}
                color={COLORS.C_RED}
                onClick={() =>
                  deleteNote({ variables: { where: { id: __.id } } })
                }
              />
              {`${moment(__.createdDate).format(DATE_TIME_FORMAT)}: ${_}`}
            </span>
          );
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={row.notes}
        rowKey={"id"}
        pagination={false}
        size="small"
        scroll={{ x: true }}
      />
    );
  };

  if (loading || error) return "Loading";

  const { contacts } = data;

  return (
    <>
      <Header
        key={cuid()}
        title={LIST.HEADER.TITLE}
        okText={LIST.HEADER.SAVE}
        hideButtons={false}
        cancelText={LIST.HEADER.REFRESH}
        onCancelClick={() => {
          createFakeContact();
        }}
        onOkClick={() => {
          createContact({
            variables: {
              data: {
                id: cuid(),
                name: "JD",
                raw: {
                  gender: "M",
                  zipCode: "20400",
                  firstname: "",
                  lastname: "",
                  prefix: "+33",
                  phone: "0626665522",
                  optical: "Fort",
                  dental: "Fort",
                  hospitalization: "Fort",
                  medicalCare: "Fort",
                  hearingAids: "Fort",
                  socialRegime: "Sécurité Sociale",
                  retired: "Retraité",
                  restRetirement: "Retraité",
                  maritalStatus: "Divorcé",
                  spouse: "Non",
                  email: "",
                },
              },
            },
          });
        }}
      />
      <Row>
        {Object.keys(CONTACT_TYPES).map((type) => (
          <Tag
            icon={
              where.qualifier === type ? (
                <MdCheckCircleOutline key={type} />
              ) : null
            }
            key={cuid()}
            onClick={() => setWhere({ qualifier: type })}
          >
            {LIST.FILTERS[type]}
          </Tag>
        ))}
        <Tag onClick={() => setWhere({})}>{LIST.FILTERS.RESET}</Tag>
      </Row>
      <Divider />
      <Table
        dataSource={contacts.data}
        columns={columns}
        rowKey={"id"}
        expandable={{ expandedRowRender }}
        size="small"
        scroll={{ x: true }}
      />
      <Modal
        open={visible}
        okText={LIST.MODAL.SAVE}
        cancelText={LIST.MODAL.CANCEL}
        onOk={() => {
          createNote({
            variables: {
              data: {
                id: cuid(),
                contact,
                content: note,
              },
            },
          });
        }}
        onCancel={() => {
          setNote(null);
          setContact(null);
          setVisible(false);
        }}
      >
        <Input
          placeholder={LIST.TABLE.ACTIONS.NOTES.CREATE}
          onChange={(_) => setNote(_.target.value)}
          value={note}
        />
      </Modal>
    </>
  );
}

export default List;
