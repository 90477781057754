import classNames from "classnames";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { find, get, head, isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { DATE_FORMAT, INSURANCE_PLAN_PARAMS_RULES } from "utils/constants";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Cart from "../Components/Cart";
import Header from "../Components/Header";
import Lamp from "../Components/svg/Lamp.svg";
import About from "../Components/svg/about.svg";
import Back from "../Components/svg/back.svg";
import UpdateCostModal from "./Widgets/UpdateCostModal";
import validation from "./validation";

const View = ({
  initialValues,
  progress,
  subscription,
  tracer,
  project,
  onNext,
  locked,
  onBack,
  recalculateCost,
}) => {
  const [occupation, setOccupation] = useState(false);
  const [resiliation, setResiliation] = useState(false);
  const [validStartDate, setValidStartDate] = useState();
  const [validEndDate, setValidEndDate] = useState();
  const [deltaRia, setDeltaRia] = useState(30);
  const [deltaStart, setDeltaStart] = useState(1);
  const [deltaEndRia, setDeltaEndRia] = useState();
  const [deltaEnd, setDeltaEnd] = useState();
  const [showChangeCostModal, setShowChangeCostModal] = useState({});
  const [oldIssuanceDate, setOldIssuanceDate] = useState(
    get(initialValues, "project.contract.issuanceDate")
  );
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.EFFECTIVE_DATE", { returnObjects: true });
  const [pickerValue, setPickerValue] = useState(
    !get(project, "contract.issuanceDate") && dayjs()
  );
  useEffect(() => {
    const rules = get(
      head(get(subscription, "insurancePlan.params", [])),
      "rules",
      []
    );
    const deltaRia = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
      }),
      "value",
      30
    );
    const deltaStart = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
      }),
      "value",
      1
    );
    const deltaEndRia = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
      }),
      "value",
      0
    );
    const deltaEnd = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
      }),
      "value",
      0
    );
    setDeltaRia(deltaRia);
    setDeltaStart(deltaStart);
    setDeltaEndRia(deltaEndRia);
    setDeltaEnd(deltaEnd);
    setValidStartDate(
      get(subscription, "project.ria")
        ? dayjs(
            moment
              .max(
                moment().add(deltaRia, "days"),
                moment().startOf("y").add(5, "h")
              )
              .toDate()
          )
        : dayjs(
            moment
              .max(
                moment().add(deltaStart, "days"),
                moment().startOf("y").add(5, "h")
              )
              .toDate()
          )
    );
    setValidEndDate(
      get(subscription, "project.ria")
        ? deltaEndRia === 0
          ? dayjs().endOf("year")
          : dayjs().add(deltaEndRia, "days")
        : deltaEnd === 0
        ? dayjs().endOf("year")
        : dayjs().add(deltaEnd, "days")
    );
  }, []);

  const checkExpired = useCallback(({ issuanceDate }) => {
    const minIssuanceDate = dayjs().startOf("day").add(deltaRia, "days");
    const expired = dayjs(issuanceDate).isBefore(minIssuanceDate);
    return expired;
  }, []);

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
      { hasRia: resiliation, occupation }
    ),
  });

  const onChangeCost = () => {
    setShowChangeCostModal({});
  };

  const onCancelChangeCost = () => {
    if (showChangeCostModal?.isRia) {
      setFieldValue("resiliation", false);
      setFieldValue("project.ria", false);
      setFieldValue("project.fields.selfOccupation", undefined);
      setFieldValue(
        "project.contract.issuanceDate",
        get(initialValues, "project.contract.issuanceDate")
      );
      setValidStartDate(dayjs().add(deltaStart, "days"));
      setPickerValue(dayjs().add(deltaStart, "days"));
    }
    recalculateCost({
      oldIssuanceDate: values?.project?.contract?.issuanceDate,
      issuanceDate: oldIssuanceDate,
      setFieldValue,
      setShowChangeCostModal,
      isCancel: true,
    });
  };

  const RIA_STATUS = {
    [true]: () => {
      setFieldValue("project.fields.selfOccupation", undefined);
      setOccupation(false);
      if (subscription?.locked) return;
      if (
        checkExpired({
          issuanceDate: values?.project?.contract?.issuanceDate,
        })
      ) {
        setValidStartDate(dayjs().add(deltaRia, "days"));
        recalculateCost({
          oldIssuanceDate: oldIssuanceDate,
          issuanceDate: dayjs().add(deltaRia, "days"),
          setFieldValue,
          setShowChangeCostModal,
          isRia: true,
        });
        setFieldValue(
          "project.contract.issuanceDate",
          dayjs().add(deltaRia, "days")
        );
        setPickerValue(dayjs().add(deltaRia, "days"));
      }
    },
    [false]: () => {
      if (subscription?.locked) return;
      setFieldValue("project.ria", false);
      setFieldValue("project.fields.selfOccupation", undefined);
      setFieldValue(
        "project.contract.issuanceDate",
        get(initialValues, "project.contract.issuanceDate")
      );
      setValidStartDate(dayjs().add(deltaStart, "days"));
      setPickerValue(dayjs().add(deltaStart, "days"));
    },
  };

  return (
    <>
      <UpdateCostModal
        showChangeCostModal={false && showChangeCostModal}
        insurancePlanName={get(subscription, "insurancePlan.name")}
        setShowChangeCostModal={setShowChangeCostModal}
        onChangeCost={onChangeCost}
        onCancelChangeCost={onCancelChangeCost}
      />
      <Header
        progressValue={progress}
        View={Cart({
          subscriptionId: get(subscription, "id"),
          tracer,
          project,
          contact: get(project, "contact"),
        })}
      />
      <div className="container __LMF">
        <Button className="back" type="default" onClick={onBack}>
          <img src={Back} />
        </Button>
        <h1 className="header-title">{TRANSLATION.TITLE}</h1>
        <span
          className="header-description"
          dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
        ></span>
        <div className="__LMF--content LMF-content medium">
          <Input
            label={TRANSLATION.FORM.EFFECTIVE_DATE.LABEL}
            placeholder={TRANSLATION.FORM.EFFECTIVE_DATE.PLACEHOLDER}
            onFocus={() =>
              setOldIssuanceDate(values?.project?.contract?.issuanceDate)
            }
            className={classNames({
              valid: get(values, "project.contract.issuanceDate"),
            })}
            type="date"
            variant="simple"
            format={DATE_FORMAT}
            defaultPickerValue={pickerValue}
            disabledDate={(current) => {
              return (
                current && (current < validStartDate || current > validEndDate)
              );
            }}
            {...bindDateInputProps({
              name: "project.contract.issuanceDate",
              values,
              setFieldValue,
              ...formProps,
            })}
            onChange={(date) => {
              setFieldValue("project.contract.issuanceDate", date);
              if (
                date.isValid() &&
                date >= validStartDate &&
                date <= validEndDate
              ) {
                recalculateCost({
                  oldIssuanceDate: oldIssuanceDate,
                  issuanceDate: date,
                  setFieldValue,
                  setShowChangeCostModal,
                });
              }
            }}
            onBlur={({ target: { value: issuanceDate } }) => {
              const date = dayjs(issuanceDate, DATE_FORMAT).add(2, "h");
              if (
                date.isValid() &&
                date >= validStartDate &&
                date <= validEndDate
              ) {
                setFieldValue("project.contract.issuanceDate", date);
              } else formProps.setFieldTouched("project.contract.issuanceDate");
            }}
            disabled={locked}
          />
          <div className="__LMF--content__body">
            <div className="__LMF--content__body--checkbox">
              <Input
                type="checkbox"
                label={TRANSLATION.FORM.RIA.LABEL}
                checked={get(values, "resiliation")}
                {...bindInputProps({
                  name: "resiliation",
                  values,
                  ...formProps,
                })}
                onChange={({ target: { checked } }) => {
                  setResiliation(checked);
                  setFieldValue("resiliation", checked);
                  RIA_STATUS[checked]();
                }}
                disabled={locked}
              />
              <img src={About} />
            </div>
            {get(values, "resiliation") && (
              <div className="__LMF--content__body--container">
                <div className="__LMF--content__body--container__title">
                  {TRANSLATION.FORM.RIA.ENABLED.TITLE}
                </div>
                <div className="__LMF--content__body--container__body rounded">
                  <Input
                    type="checkbox"
                    label={TRANSLATION.FORM.RIA.ENABLED.SELF_OCCUPATION}
                    checked={get(values, "project.fields.selfOccupation")}
                    {...bindInputProps({
                      name: "project.fields.selfOccupation",
                      values,
                      ...formProps,
                    })}
                    onChange={() => {
                      setFieldValue("project.fields.selfOccupation", true);
                      setFieldValue("project.ria", false);
                      setFieldValue("project.terminationRequest", {
                        contractNumber: null,
                        brokerageMandate: { currentCompany: null },
                      });
                      setOccupation(true);
                    }}
                    disabled={locked}
                  />
                  <Input
                    type="checkbox"
                    label={TRANSLATION.FORM.RIA.ENABLED.AUTO_OCCUPATION}
                    checked={
                      !get(values, "project.fields.selfOccupation", true)
                    }
                    {...bindInputProps({
                      name: "project.fields.selfOccupation",
                      values,
                      ...formProps,
                    })}
                    onChange={() => {
                      setFieldValue("project.fields.selfOccupation", false);
                      setFieldValue("project.ria", true);
                      setOccupation(false);
                    }}
                    disabled={locked}
                  />
                </div>
                {get(values, "resiliation") && get(values, "project.ria") && (
                  <div className="__LMF--content__body--container__body">
                    <Input
                      label={TRANSLATION.FORM.CURRENT_EFFECTIVE_DATE.LABEL}
                      placeholder={
                        TRANSLATION.FORM.CURRENT_EFFECTIVE_DATE.PLACEHOLDER
                      }
                      type="date"
                      variant="simple"
                      format={DATE_FORMAT}
                      defaultPickerValue={dayjs().add(-1, "day")}
                      disabledDate={(current) => {
                        return current && current > dayjs();
                      }}
                      {...bindDateInputProps({
                        name: `project.terminationRequest.dueDate`,
                        values,
                        setFieldValue,
                        ...formProps,
                      })}
                      disabled={locked}
                    />
                    <Input
                      label={TRANSLATION.FORM.CURRENT_CONTRACT.LABEL}
                      placeholder={
                        TRANSLATION.FORM.CURRENT_CONTRACT.PLACEHOLDER
                      }
                      {...bindInputProps({
                        name: `project.terminationRequest.contractNumber`,
                        values,
                        ...formProps,
                      })}
                      disabled={get(subscription, "locked")}
                    />
                    <Input
                      label={TRANSLATION.FORM.CURRENT_COMPANY.LABEL}
                      placeholder={TRANSLATION.FORM.CURRENT_COMPANY.PLACEHOLDER}
                      {...bindInputProps({
                        name: `project.terminationRequest.brokerageMandate.currentCompany`,
                        values,
                        ...formProps,
                      })}
                      disabled={locked}
                    />
                    <div className="__LMF--content__body--container__body--caption">
                      <img src={Lamp} />
                      <span>{TRANSLATION.FORM.CAPTION}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <Button
          disabled={!isEmpty(formProps.errors)}
          onClick={handleSubmit}
          type="white"
          size="middle"
        >
          <span>{TRANSLATION.NEXT}</span>
        </Button>
      </div>
    </>
  );
};

export default View;
