import { get } from "lodash";
import { useState } from "react";
import { ACTIVITY_TIME_OPTIONS, ESTABLISHMENTS } from "utils/constants";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import Select from "../Components/Select";

function View({ contact, onNext, onBack }) {
  const [selectedValue, setSelectedValue] = useState({
    income: undefined,
    incomeType: undefined,
    establishment: get(contact, "fields.establishment"),
    activityTime: get(contact, "fields.activityTime"),
    cappedIndex: get(contact, "fields.cappedIndex"),
    situation: undefined,
    optMadelin: undefined,
  });

  return (
    <div className="solyon">
      <div className="solyon__container">
        <Header
          title="Quel est votre indice majoré ? Travaillez-vous à temps plein ou à temps partiel ?"
          highlight="indice majoré, temps plein, temps partiel"
          subtitle="Informations de l’adhérent"
          onBack={onBack}
        />
        <div className="solyon__body">
          <div className="grid--1" style={{ width: 500 }}>
            <div className="grid--2">
              <Input
                placeholder={"ex : 123"}
                label={"Indice majoré"}
                defaultValue={selectedValue.cappedIndex}
                onChange={({ target: { value } }) =>
                  setSelectedValue((prev) => ({ ...prev, cappedIndex: value }))
                }
              />
              <Select
                options={ACTIVITY_TIME_OPTIONS.map((value) => ({
                  label: `${value}%`,
                  value,
                }))}
                placeholder={"Sélectionnez ..."}
                label={"Temps d'activité"}
                defaultValue={selectedValue.activityTime}
                onChange={(value) =>
                  setSelectedValue((prev) => ({ ...prev, activityTime: value }))
                }
              />
            </div>
            <Select
              options={ESTABLISHMENTS.map((value) => ({
                label: value,
                value,
              }))}
              placeholder={"Sélectionnez ..."}
              label={"Établissement employeur"}
              defaultValue={selectedValue.establishment}
              onChange={(value) =>
                setSelectedValue((prev) => ({ ...prev, establishment: value }))
              }
            />
          </div>
          <Button
            type={"primary"}
            alignment={"center"}
            disabled={
              !selectedValue.cappedIndex ||
              !selectedValue.activityTime ||
              !selectedValue.establishment
            }
            onClick={() =>
              onNext({
                payload: {
                  contact: {
                    ...contact,
                    fields: {
                      ...get(contact, "fields", {}),
                      ...selectedValue,
                    },
                  },
                },
              })
            }
          >
            <div className="d-flex justify--center">Continuer</div>
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default View;
