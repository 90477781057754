import * as yup from "yup";

const validation = (messages, { hasRia, occupation }) => {
  const string = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  return yup.object().shape({
    project: yup.object().shape({
      contract: yup.object().shape({
        issuanceDate: yup.date().typeError(messages.REQUIRED),
      }),
      fields: yup.object().shape({
        selfOccupation: yup.boolean().oneOf([true, false], " "),
      }),
      ...(hasRia &&
        !occupation && {
          terminationRequest: yup.object().shape({
            dueDate: yup.date().typeError(messages.REQUIRED),
            contractNumber: string,
            brokerageMandate: yup.object().shape({
              currentCompany: string,
            }),
          }),
        }),
    }),
  });
};

export default validation;
