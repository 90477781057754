import { get } from "lodash";
import { useEffect, useState } from "react";
import Loading from "shared/components/Spin";
import { useLazyQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import View from "./View";

const SocialSecurityNumber = ({
  tracer,
  project,
  onNext,
  currentStatus,
  onBack,
}) => {
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");
  const [loading, setLoading] = useState(true);
  const [getSubscription, { data }] = useLazyQuery(
    graphql.queries.SUBSCRIPTION
  );

  useEffect(() => {
    if (subscriptionId)
      getSubscription({
        variables: { where: { id: subscriptionId } },
        onCompleted: () => {
          setLoading(false);
        },
      });
  }, []);

  const onSubmit = ({ contact: { socialSecurityNumber } }) => {
    onNext({
      payload: {
        contact: {
          ...get(project, "contact", {}),
          socialSecurityNumber,
        },
      },
    });
  };

  if (loading) return <Loading />;

  const initialValues = {
    contact: {
      ...get(project, "contact"),
      socialSecurityNumber: get(project, "contact.socialSecurityNumber"),
    },
  };

  return (
    <View
      initialValues={initialValues}
      tracer={tracer}
      project={project}
      locked={get(project, "locked", false)}
      progress={currentStatus.progress}
      subscription={get(data, "subscription")}
      onBack={onBack}
      onNext={onSubmit}
    />
  );
};
export default SocialSecurityNumber;
