import React, { useMemo } from "react";

import PropTypes from "prop-types";

import Checkbox from "./Checkbox";
import Date from "./Date";
import Email from "./Email";
import File from "./File";
import Number from "./Number";
import Simple from "./Simple";
import Switch from "./Switch";
import TextArea from "./TextArea";

const DIRECTIONS = ["column", "row"];

const MODES = {
  simple: Simple,
  date: Date,
  number: Number,
  email: Email,
  file: File,
  textarea: TextArea,
  checkbox: Checkbox,
  switch: Switch,
};

const Input = ({ type, width, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View style={{ width }} {...props} />;
};

Input.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
  direction: PropTypes.oneOf(DIRECTIONS),
};

Input.defaultProps = {
  type: "simple",
  direction: "column",
};

export default React.memo(Input);
