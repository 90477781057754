// hooks/useInsurancePlans.js
import { useMutation } from "@apollo/client";
import { get } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useMemo, useState } from "react";
import { BUILD_GENERAL_COSTS } from "utils/api/graphql/mutations/costs";
import {
  CONTACT_POINT_SYSTEM,
  INSURANCE_PLAN_PARAMS_RULES,
} from "utils/constants";
import { generateSQLCondition } from "../../../../Manual/Solyon/SelectProducts";

const useInsurancePlans = ({
  setSelectedModules,
  insurancePlan,
  getOffers,
  project,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedInsurancePlans, setSelectedInsurancePlans] = useState([]);
  const [buildCosts] = useMutation(BUILD_GENERAL_COSTS);
  const insurancePlanFilter = useMemo(() => {
    const issuanceDate = moment(get(project, "contract.issuanceDate")).startOf(
      "day"
    );
    const issuanceDateDifference = issuanceDate.diff(
      moment().startOf("day"),
      "days"
    );

    const adherentAge = moment(get(project, "contact.user.birthDate")).startOf(
      "year"
    );

    const age = moment().diff(adherentAge, "years");

    const [startKey, endKey] = project.ria
      ? [
          INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
          INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
        ]
      : [
          INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
          INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
        ];
    const socialRegime = get(project, "contact.socialRegime");

    const profession = get(project, "contact.profession");

    const department = get(
      get(project, "contact.telecoms", []).find(
        ({ system }) => system === CONTACT_POINT_SYSTEM.ZIP_CODE
      ),
      "value",
      ""
    )
      .toString()
      .substring(0, 2);

    const sqlConditions = [
      generateSQLCondition(
        startKey,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
        issuanceDateDifference
      ),
      generateSQLCondition(
        endKey,
        `r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value ${
          moment(get(project, "contract.issuanceDate")).year() ===
          moment().year()
            ? "OR CAST(r.value AS NUMERIC) = 0"
            : ""
        }`,
        issuanceDateDifference
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.MIN_AGE,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
        age
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.MAX_AGE,
        "r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value",
        age
      ),

      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.SOCIAL_REGIMES,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${socialRegime}'`
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.PROFESSION,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${profession}'`
      ),
      generateSQLCondition(
        INSURANCE_PLAN_PARAMS_RULES.DEPARTMENTS,
        `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
        `'${department}'`
      ),
    ];

    return {
      raw: sqlConditions,
      custom: {
        AND: [
          {
            OR: [
              { startDate: null },
              {
                startDate: issuanceDate,
                operation: "<=",
              },
            ],
          },
          {
            OR: [
              { endDate: null },
              {
                endDate: issuanceDate,
                operation: ">=",
              },
            ],
          },
        ],
      },
    };
  }, []);

  const fetchMainOffers = ({ offers }) => {
    const mainOffers = get(offers, "data", []);
    mainOffers.forEach(({ id }) => {
      fetchAdditionalOffers(id);
    });
  };

  const fetchAdditionalOffers = (offerId) => {
    getOffers({
      variables: {
        where: {
          id: offerId,
          offerInsurancePlans: { main: false },
        },
      },
      onCompleted: ({ offers }) => {
        const offerInsurancePlans = get(offers, "data", []).flatMap(
          ({ offerInsurancePlans }) =>
            omitDeep(offerInsurancePlans, "__typename")
        );

        buildCosts({
          variables: {
            data: {
              project: { id: project.id },
              insurancePlanFilter: {
                ...insurancePlanFilter,
                isIn: {
                  id: offerInsurancePlans.map(
                    ({ insurancePlan }) => insurancePlan?.id
                  ),
                },
              },
            },
          },
          onCompleted: ({ buildGeneralCosts }) => {
            setLoading(false);
            if (!buildGeneralCosts?.length) return;
            setSelectedModules((prev) => {
              const modules = get(prev, insurancePlan?.id)?.filter(
                ({ insurancePlan }) =>
                  buildGeneralCosts
                    .map(({ insurancePlan }) => insurancePlan.id)
                    .includes(insurancePlan?.id)
              );
              return {
                ...prev,
                [insurancePlan?.id]: modules,
              };
            });
            setSelectedInsurancePlans((prev) => {
              const newPlans = buildGeneralCosts
                .filter(({ cost }) => cost)
                .map((costItem) => omitDeep(costItem, "__typename"));

              const uniquePlans = newPlans.filter(
                (newPlan) => !prev.find(({ id }) => id === newPlan.id)
              );

              return [...prev, ...uniquePlans];
            });
          },
        });
      },
    });
  };

  return {
    loading,
    selectedInsurancePlans,
    fetchMainOffers,
    fetchAdditionalOffers,
  };
};

export default useInsurancePlans;
