import { get } from "lodash";
import View from "./View";

const EffectiveDate = ({ project, onNext, onBack }) => {
  return (
    <View
      contact={get(project, "contact", {})}
      onNext={onNext}
      onBack={onBack}
    />
  );
};

export default EffectiveDate;
