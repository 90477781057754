import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import icon from "../Components/svg/lmf-icon.svg";

const ThankYou = ({}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.THANK_YOU", { returnObjects: true });
  return (
    <div className="thank-you">
      <img src={icon} width={65} />
      <p>{TRANSLATION.TITLE}</p>
    </div>
  );
};

export default ThankYou;
