import { Button as AntButton } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";

const BUTTON_TYPES = [
  "primary",
  "secondary",
  "white",
  "gray",
  "danger",
  "success",
  "bordered",
  "default",
];

const Button = ({
  type = "primary",
  alignment = "left",
  size = "medium",
  shape = false,
  children,
  className = "w-max",
  ...rest
}) => {
  return (
    <div className={classNames("button__lmf", alignment, className)}>
      <AntButton className={classNames(`lmf_${type}`, `lmf_${size}`)} {...rest}>
        {children}
      </AntButton>
    </div>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(BUTTON_TYPES),
  alignment: PropTypes.oneOf(["left", "center", "right"]),
  size: PropTypes.oneOf(["small", "middle", "medium", "large"]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  type: "primary",
  alignment: "left",
  size: "middle",
  className: "w-max",
};

export default Button;
