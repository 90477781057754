import classNames from "classnames";
import React from "react";
import { MdAdd, MdRemove } from "react-icons/md";
import Button from "../Components/Button";
import Cost from "../Components/Cost";

function UpsellsCard({ icon, name, cost, currency, onAdd, notSelected }) {
  return (
    <div className="solyon-upsells-card">
      <div
        className={classNames("solyon-upsells-card__container", {
          "solyon-upsells-card__not-selected": notSelected,
        })}
      >
        <img alt={name} src={icon} />
        <Cost cost={cost} currency={currency} />
        <Button type="primary" onClick={onAdd}>
          {notSelected ? <MdAdd size={14} /> : <MdRemove size={14} />}
        </Button>
      </div>
    </div>
  );
}

export default React.memo(UpsellsCard);
