export const COLORS = {
  C_PRIMARY: "#0e93e5",
  C_PRIMARY_BACKGROUND: "e6f4ff",
  C_SECOND: "#FCE9D2",
  C_THIRD: "#201F57",
  C_FORTH: "#8fa2f7",
  C_DARK: "#0D1539",
  C_TEXT: "#2f3838",
  C_BLACK: "#070C24",
  C_WHITE: "#ffffff",
  C_GRAY: "#8A92A5",
  C_BAC: "#8A92A5",
  C_GRAY_L2: "#FCFCFC",
  C_BLUE_LIGHT: "#28375A",
  C_BLUE_DARK: "#002E74",
  C_GREEN: "#3BD375",
  C_RED: "#FF5A93",
  C_GOLD: "D4A017",
  C_BACKGROUND: "#EFF3F8",
  C_WARNING: "#b89335",
  C_SUCCESS: "#41ad6a",
  C_DANGER: "#f26262",
  C_DANGER_BACKGROUND: "#ffe3e3",
  C_MALE: "#02B0F0",
  C_FEMALE: "#EF5890",

  C_RAND_DFF: "#968DFF",
  C_RAND_0D1: "#49A0D1",
  C_RAND_5CC: "#0BB5CC",
  C_RAND_BFF: "#4F4BFF",
  C_RAND_5FD: "#C975FD",
  C_RAND_DD7: "#87ADD7",
  C_RAND_BF8: "#FD7BF8",

  // LMF Colors
  C_LMF_PRIMARY: "#5A348C",
  C_LMF_SECONDARY: "#FF9D00",
  C_LMF_YELLOW: "#FEC212",
  C_LMF_BLUE: "#1B4DCE",
  C_LMF_DARK_BLUE: "#272C49",
  C_LMF_GREEN: "#00B48D",
  C_LMF_WHITE: "#FFFFFF",
  C_LMF_RED: "#FF6082",
  C_LMF_LIGHT_BLUE: "#80D4DC",
  C_LMF_PINK: "#FFB3B3",
  C_LMF_PALE_BLUE: "#8ACEFF",
  C_LMF_GRAY: "#979797",
};
