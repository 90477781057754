import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import validation from "./validation";

function View({ contact, onNext, onBack, locked }) {
  const { t } = useTranslation();
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: {
      ...contact,
      acceptContact: get(contact, "acceptContact") || false,
      showTelecoms: contact.acceptContact,
      showAccept: contact.acceptContact,
    },
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });
  return (
    <div className="solyon">
      <div className="solyon__container">
        <Header
          title="Presque fini ! Dernière étape pour découvri votre offre personnalisée"
          highlight="Presque fini !"
          subtitle="Informations de l’adhérent"
          onBack={onBack}
        />
        <div className="solyon__body">
          <div className="grid--1" style={{ width: 500 }}>
            <div className="grid--2">
              <Input
                placeholder={"ex : Doe"}
                label={"Nom"}
                {...bindInputProps({
                  name: "user.lastname",
                  values,
                  ...formProps,
                })}
                disabled={locked}
                onChange={({ target: { value } }) => {
                  setFieldValue("user.lastname", value);
                  setFieldValue(
                    "showTelecoms",
                    values.showTelecoms ||
                      !!(value && get(values, "user.firstname"))
                  );
                }}
              />
              <Input
                placeholder={"ex : John"}
                label={"Prénom"}
                {...bindInputProps({
                  name: "user.firstname",
                  values,
                  ...formProps,
                })}
                disabled={locked}
                onChange={({ target: { value } }) => {
                  setFieldValue("user.firstname", value);
                  setFieldValue(
                    "showTelecoms",
                    values.showTelecoms ||
                      !!(value && get(values, "user.lastname"))
                  );
                }}
              />
            </div>
            {values.showTelecoms && (
              <div className="grid--2">
                <Input
                  placeholder={"ex : 0610203040"}
                  label={"N° de téléphone"}
                  {...bindInputProps({
                    name: `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                    values,
                    ...formProps,
                  })}
                  disabled={locked}
                  onChange={({ target: { value } }) => {
                    setFieldValue(
                      `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                      value
                    );
                    setFieldValue(
                      "showAccept",
                      values.showAccept ||
                        !!(
                          value &&
                          get(
                            values,
                            `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                          )
                        )
                    );
                  }}
                />
                <Input
                  placeholder={"ex : John"}
                  label={"Email"}
                  {...bindInputProps({
                    name: `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                    values,
                    ...formProps,
                  })}
                  disabled={locked}
                  onChange={({ target: { value } }) => {
                    setFieldValue(
                      `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                      value
                    );
                    setFieldValue(
                      "showAccept",
                      values.showAccept ||
                        !!(
                          value &&
                          get(
                            values,
                            `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`
                          )
                        )
                    );
                  }}
                />
              </div>
            )}
            {values.showAccept && (
              <Input
                type="checkbox"
                label={
                  "J’autorise So’Lyon Mutuelle à collecter et utiliser les données personnelles nécessaires à l’exécution de mon devis et une proposition adaptée à mes besoins."
                }
                subLabel={
                  "Pour plus d’informations sur l’utilisation de vos données ainsi que sur la mise en œuvre par So’Lyon Mutuelle du dispositif sur la Réglementation Générale sur la Protection des Données (RGPD) qui est entré en vigueur le 25 Mai 2018, vous pouvez consulter notre politique de protection des données sur le site internet"
                }
                checked={get(values, "acceptContact") || false}
                {...bindInputProps({
                  name: "acceptContact",
                  values,
                  ...formProps,
                })}
              />
            )}
          </div>
          <Button
            type={"primary"}
            alignment={"center"}
            disabled={
              !get(values, "user.firstname") || !isEmpty(formProps.errors)
            }
            onClick={handleSubmit}
          >
            Continuer
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default View;
