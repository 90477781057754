import Header from "shared/components/Header";
import { PricingCard } from "shared/components/LmfWidgets";
import Button from "shared/components/LmfWidgets/Button";
import OfferHealth1 from "shared/assets/images/lmf/lmf-sante-1.svg";
import OfferHealth2 from "shared/assets/images/lmf/lmf-sante-2.svg";
import OfferHealth3 from "shared/assets/images/lmf/lmf-sante-3.svg";
import OfferHealth4 from "shared/assets/images/lmf/lmf-sante-4.svg";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const plans = [
  {
    planName: "LMF Santé 1",
    price: "186,40",
    logo: OfferHealth1,
    PlanColor:"red",
    benefits: [
      "Couronne en céramique jusqu’à 200 €",
      "Lunette verres simples jusqu’à 120 €",
      "Hospitalisation à partir de 40 € la nuit en chambre individuelle"
    ],
  },
  {
    planName: "LMF Santé 2",
    price: "255,86",
    logo: OfferHealth2,
    PlanColor:"white",
    benefits: [
      "Couronne en céramique jusqu’à 200 €",
      "Lunette verres simples jusqu’à 120 €",
      "Hospitalisation à partir de 40 € la nuit en chambre individuelle"
    ],
    isRecommended: true,
  },
  {
    planName: "LMF Santé 3",
    price: "307,84",
    logo: OfferHealth3,
PlanColor:"blue",

    benefits: [
      "Couronne en céramique jusqu’à 200 €",
      "Lunette verres simples jusqu’à 120 €",
      "Hospitalisation à partir de 40 € la nuit en chambre individuelle"
    ],
  },
  {
    planName: "LMF Santé 4",
    price: "307,84",
    logo: OfferHealth4,
    PlanColor:"yellow",
    benefits: [
      "Couronne en céramique jusqu’à 200 €",
      "Lunette verres simples jusqu’à 120 €",
      "Hospitalisation à partir de 40 € la nuit en chambre individuelle"
    ],
  }
];

const Lmf = () => {

  return (
    <div className="lmf__template">
      <Header
        key={"header"}
        title={"Components LMF"}
        actions={[]}
      />
      <div className="lmf__swiper">
        <Swiper
          modules={[Navigation]}
          slidesPerView={3}
          spaceBetween={16}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            100: {
              slidesPerView: 1,
              initialSlide: 1,
            },
            320: {
              slidesPerView: 1,
              centeredSlides: true,
              initialSlide: 1,
            },
            520: {
              slidesPerView: 1,
              centeredSlides: true,
              initialSlide: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            840: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            1099: {
              slidesPerView: 3,
            },
            1199: {
              slidesPerView: 3,
            },
          }}
        >
          {plans.map((plan, index) => (
            <SwiperSlide key={index}>
              <PricingCard key={index} {...plan} onSelect={() => console.log("Select")} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="lmf_swiper__arrows">
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
      </div>
    </div>
  );
};

export default Lmf;
