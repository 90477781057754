import cuid from "cuid";
import { defaults, get, keyBy, map } from "lodash";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import View from "./View";

const AdherentInfo = ({ project, onNext, onBack }) => {
  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.PHONE]: { value: null },
    [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
  });

  const onSubmit = ({ telecoms, showTelecoms, showAccept, ...contact }) => {
    const updatedTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    onNext({
      payload: {
        contact: {
          ...contact,
          id: get(project, "contact.id") || cuid(),
          telecoms: updatedTelecoms,
          user: {
            id: cuid(),
            ...contact.user,
          },
        },
        visible: true,
      },
    });
  };
  return (
    <View
      contact={{ ...get(project, "contact", {}), telecoms }}
      onNext={onSubmit}
      onBack={onBack}
      locked={project?.locked}
    />
  );
};

export default AdherentInfo;
