import { notification } from "antd";
import cuid from "cuid";
import { find, get, isArray } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import format from "string-template";
import { GENERATE_QUOTE_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { BUILD_GENERAL_COSTS } from "utils/api/graphql/mutations/costs";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import { CREATE_LINK } from "utils/api/graphql/mutations/link";
import {
  DUPLICATE_PROJECT,
  UPDATE_PROJECT,
} from "utils/api/graphql/mutations/projects";
import { SEND_B2C_QUOTE } from "utils/api/graphql/mutations/quotes";
import { CREATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { GENERAL_COSTS } from "utils/api/graphql/queries/general-cost";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { PROJECT } from "utils/api/graphql/queries/projects";
import {
  ACTION_TYPES,
  DATE_FORMAT,
  INSURANCE_PLAN_PARAMS_RULES,
  PROCESS_TYPES,
  SCREENS,
  TEMPLATES,
  TEMPLATE_TYPES,
} from "utils/constants";
import { getMinDeltaStart } from "utils/helpers/array";
import { filterByConditions } from "../../../..";
import Loader from "../Components/Loader";
import View from "./View";

const findStatus = (data, template) => {
  return find(data, ({ template: _ }) => _ === template);
};
const generateSQLCondition = (ruleName, condition, value) => {
  return format(
    `EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}' AND ({condition})) OR NOT EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}')`,
    { ruleName, condition: condition.replace(":value", value) }
  );
};

const Products = ({ processId, tracer, project, onNext, onBack }) => {
  const navigate = useNavigate();
  const [getProject] = useLazyQuery(PROJECT, {
    fetchPolicy: "no-cache",
    variables: { where: { id: project.id } },
  });
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [buildGeneralCosts] = useMutation(BUILD_GENERAL_COSTS);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [generalCosts, { data }] = useLazyQuery(GENERAL_COSTS);
  const [addSubscription] = useMutation(CREATE_SUBSCRIPTION);
  const [getDeltaStart] = useLazyQuery(INSURANCE_PLANS, {
    variables: {
      where: {
        isStandalone: true,
      },
    },
  });
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const [generateQuoteDocuments] = useMutation(GENERATE_QUOTE_DOCUMENTS);
  const [sendQuoteMutation] = useMutation(SEND_B2C_QUOTE);
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const [duplicateProject] = useMutation(DUPLICATE_PROJECT);
  const [addLink] = useMutation(CREATE_LINK);

  const duplicateProjectOnLocked = () => {
    const { insurancePlans, currentSubscription, nextQuoteStatus, ...fields } =
      project?.fields;

    const Template =
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE_COSA]
        .PRODUCTS;
    const status = findStatus(
      get(tracer, "flow.process.processStatuses", []),
      Template
    );
    const actionsToExecute = filterByConditions(
      get(status, "actions", []),
      project
    );
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({
        id: cuid(),
        status: { id: status },
      }));
    duplicateProject({
      variables: {
        where: { id: project.id },
        data: {
          statuses: [{ id: cuid(), status: { id: status.id } }, ...syncActions],
          fields,
        },
      },
      onCompleted: ({ duplicateProject }) => {
        localStorage.setItem("projectId", duplicateProject?.id);
        navigate(
          `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${duplicateProject?.id}`,
          {
            state: {
              process: get(omitDeep(tracer, "__typename"), "flow.process"),
              tracer: omitDeep(tracer, "__typename"),
            },
          }
        );
      },
    });
  };

  const onSubmit = async (insurancePlanId) => {
    const insurancePlans = [
      ...get(project, "fields.insurancePlans", []).filter(
        (id) => id !== insurancePlanId
      ),
      insurancePlanId,
    ];
    onNext({
      payload: {
        fields: {
          ...project.fields,
          insurancePlans,
          selectedToCompare: [insurancePlanId],
        },
      },
    });
  };

  useEffect(() => {
    getDeltaStart({
      onCompleted: ({ insurancePlans: { data: insurancePlans } }) => {
        const deltaRia = getMinDeltaStart({ insurancePlans, minValue: 30 });
        const deltaStart = getMinDeltaStart({
          insurancePlans,
          minValue: 3,
          key: INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
        });
        const deltaS = project.ria ? deltaRia : deltaStart;
        if (project?.expired) {
          notification.open({
            message: format(TRANSLATION.EXPIRATION.TITLE, {
              issuanceDate: moment().add(deltaS, "days").format(DATE_FORMAT),
            }),
            description: TRANSLATION.EXPIRATION.DESCRIPTION,
            duration: 5,
            icon: <HiOutlineExclamationCircle size={32} />,
            type: "error",
          });
        }
        const issuanceDate = moment().add(deltaS, "days");

        updateProject({
          variables: {
            where: { id: project.id },
            data: {
              expired: false,
              contract: {
                ...project.contract,
                issuanceDate,
              },
            },
          },
          onCompleted: ({ updateProject: project }) => {
            const adherentAge = moment(
              get(project, "contact.user.birthDate")
            ).startOf("year");
            const profession = get(project, "contact.profession");

            const age = moment().diff(adherentAge, "years");

            const sqlConditions = [
              generateSQLCondition(
                INSURANCE_PLAN_PARAMS_RULES.MIN_AGE,
                "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
                age
              ),
              generateSQLCondition(
                INSURANCE_PLAN_PARAMS_RULES.MAX_AGE,
                "r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value",
                age
              ),
              generateSQLCondition(
                INSURANCE_PLAN_PARAMS_RULES.PROFESSION,
                `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
                `'${profession}'`
              ),
              generateSQLCondition(
                INSURANCE_PLAN_PARAMS_RULES.PROCESSES,
                `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
                `'${processId}'`
              ),
            ];

            const insurancePlanFilter = {
              where: {
                isStandalone: true,
              },
              raw: sqlConditions,
              custom: {
                AND: [
                  {
                    OR: [
                      { startDate: null },
                      {
                        startDate: issuanceDate,
                        operation: "<=",
                      },
                    ],
                  },
                  {
                    OR: [
                      { endDate: null },
                      {
                        endDate: issuanceDate,
                        operation: ">=",
                      },
                    ],
                  },
                ],
              },
            };
            buildGeneralCosts({
              variables: {
                data: {
                  project: { id: project.id },
                  insurancePlanFilter,
                },
              },
              onCompleted: ({ buildGeneralCosts }) => {
                const ids = buildGeneralCosts
                  .filter(({ cost }) => cost)
                  .map(({ id }) => id);
                generalCosts({
                  variables: {
                    isIn: { id: ids },
                  },
                  onCompleted: () => setLoading(false),
                });
              },
            });
          },
        });
      },
    });
  }, [refetch]);

  const refetchCosts = ({ contact }) => {
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          contact,
        },
      },
      onCompleted: () => setRefetch((prev) => !prev),
    });
  };
  if (
    loading ||
    !isArray(omitDeep(get(data, "generalCosts.data"), "__typename"))
  )
    return <Loader />;
  return (
    <View
      products={omitDeep(get(data, "generalCosts.data"), "__typename")}
      count={get(data, "generalCosts.count")}
      onBack={onBack}
      onSendQuote={onSubmit}
      disabledProducts={get(project, "fields.insurancePlans", [])}
      contact={get(project, "contact", {})}
      refetchCosts={refetchCosts}
      downloadSingleDocument={downloadSingleDocument}
      locked={project.locked}
      duplicateProject={duplicateProjectOnLocked}
    />
  );
};

export default Products;
