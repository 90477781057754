import { Col, Row, Table } from "antd";
import Subscriptions from "entities/Subscriptions";
import { find, get } from "lodash";
import { useTranslation } from "react-i18next";
import { TbFileExport } from "react-icons/tb";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import { CURRENCY_SYMBOL, GENDER } from "utils/constants";
import { displayDate } from "utils/helpers/date";

const List = ({
  filter,
  pagination,
  setPagination,
  onSearch,
  exportSubscriptions,
  exportLoading,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.SUBSCRIPTION);
  const LIST = t("LIST", { returnObjects: true });

  const getCost = (record, row) => {
    const { cost, currency } = find(
      get(record, "generalCosts"),
      (generalCost) =>
        get(generalCost, "insurancePlan.id") === get(row, "insurancePlan.id")
    );
    return `${cost} ${CURRENCY_SYMBOL[currency]}`;
  };

  const columns = [
    {
      title: LIST.SIGNATURE_DATE,
      dataIndex: "signedDate",
      render: (date) => displayDate({ date }),
    },
    {
      title: LIST.START_DATE,
      dataIndex: "project",
      render: (item) =>
        displayDate({ date: get(item, "contract.issuanceDate") }),
      key: "issuanceDate",
    },
    {
      title: LIST.CLIENT,
      dataIndex: "project",
      render: (record) => (
        <>
          {GENDER[get(record, "contact.user.gender")]}{" "}
          {get(record, "contact.user.firstname")}{" "}
          {get(record, "contact.user.lastname")}
        </>
      ),
      key: "client",
    },
    {
      title: LIST.ADVISOR,
      dataIndex: "project",
      render: (record) =>
        record?.author
          ? `${record?.author.firstname} ${record?.author.lastname}`
          : `-----------`,
      key: "advisor",
    },
    {
      title: LIST.STATUS,
      dataIndex: "status",
      render: (record) => <span>{LIST.STATUSES[record]}</span>,
      key: "status",
    },
    {
      title: LIST.OFFER,
      dataIndex: "insurancePlan",
      render: (record) => get(record, "name", "-----"),
      key: "offer",
    },
    {
      title: LIST.MONTHLY_PRICE,
      dataIndex: "project",
      render: getCost,
      key: "price",
    },
  ];

  return (
    <>
      <Header
        key={"header"}
        title={LIST.HEADER.TITLE}
        actions={[
          {
            icon: <TbFileExport size={20} />,
            text: LIST.HEADER.EXPORT_CONTRACTS,
            onClick: exportSubscriptions,
            loading: exportLoading,
          },
        ]}
      />
      <Card type="simple">
        <Row>
          <Col lg={20} xs={24}>
            <Row gutter={[8, 8]}>
              <Col lg={12} xs={24}>
                <Input
                  type="search"
                  placeholder={LIST.FILTERS.SEARCH}
                  onChange={({ target }) => onSearch(target)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <div className="m-bottom-24" />
      <Subscriptions
        View={({ count, data, setValues, skip }) => (
          <Card type={"simple"}>
            <Table
              dataSource={data}
              columns={columns}
              className="table__wrapper"
              scroll={{ x: true }}
              rowKey={"id"}
              pagination={false}
            />
            <Pagination count={count} setValues={setValues} skip={skip || 0} />
          </Card>
        )}
        setValues={setPagination}
        skip={pagination.skip}
        filter={{ ...filter, ...pagination }}
      />
    </>
  );
};

export default List;
