import React from "react";
import PropTypes from "prop-types";
import { MdCheck, MdHelp, MdOpenInNew } from "react-icons/md";
import { Link } from "react-router-dom";
import { COLORS } from "shared/style/colors";
import Button from "shared/components/LmfWidgets/Button";
import helpIcon from "shared/assets/images/lmf/lmf_help.svg";
import helpIconWhite from "shared/assets/images/lmf/lmf_help_white.svg";



const PricingCard = ({
  planName,
  logo,
  price,
  currency,
  perMonth,
  benefits,
  isRecommended,
  onSelect,
  PlanColor
}) => {
  return (
    <div className={`pricing-lmf__card ${isRecommended ? "recommended" : ""}`}>
      {isRecommended && <div className="recommendation-badge">Notre recommandation</div>}
      <div className="pricing-lmf__card__header">
        <img src={logo} alt={planName} />
        <h3 className={`name__color--${PlanColor}`}>{planName}</h3>
      </div>

      <div className="content">
        <div className="pricing-lmf__card__price">
          <div className={`price ${isRecommended && "recommended"} `}>
            <span className="amount">{price}</span>
            <span className="currency">
              {currency}
              {perMonth && <small> /mois</small>}
            </span>
          </div>
          <img src={isRecommended ? helpIconWhite : helpIcon} alt="help" />
        </div>

        <Button type={isRecommended ? "white" : "primary"} className="w-100 h-auto" size="large">Je choisis cette offre</Button>

        <div className={`pricing-lmf__card__benefits ${isRecommended && "recommended"} `}>
          <span className="title">Les garanties phares</span>
          <ul className="list">
            {benefits.map((benefit, index) => (
              <li key={index}>
                <div className="icon">
                  <MdCheck color={COLORS.C_LMF_SECONDARY} size={20} />
                </div>
                <span>{benefit}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>


      <div className={`pricing-lmf__card__footer ${isRecommended && "recommended"} `}>
        <Link>Détails des garanties</Link>
        <Link>
          <span>Simulateur de remboursement</span>
          <MdOpenInNew size={16} color={COLORS.C_LMF_SECONDARY} />
        </Link>
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  planName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  currency: PropTypes.string,
  perMonth: PropTypes.bool,
  benefits: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRecommended: PropTypes.bool,
  onSelect: PropTypes.func,
  themeColor: PropTypes.string
};

PricingCard.defaultProps = {
  currency: "€",
  perMonth: true,
  isRecommended: false,
  onSelect: () => { },
  themeColor: "#5A348C"
};

export default PricingCard;
