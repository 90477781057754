import { get } from "lodash";
import moment from "moment";
import { GENDER } from "utils/constants";
import * as yup from "yup";

const validation = (messages) => {
  const validateSocialSecurityNumber = function (value) {
    if (!value) return true;
    const prefix = `${
      (get(this.parent, "user.gender") === GENDER.MALE && "[17]") ||
      (get(this.parent, "user.gender") === GENDER.MALE && "[28]") ||
      "[1278]"
    }${moment(get(this.parent, "user.birthDate")).format("YY")}${moment(
      moment(get(this.parent, "user.birthDate"))
    ).format("MM")}`;
    const regexPattern = new RegExp(`^${prefix}(?:[0-9]{8}|[0-9]{10})$`);
    return new RegExp(regexPattern).test(value);
  };

  return yup.object().shape({
    contact: yup.object().shape({
      socialSecurityNumber: yup
        .string()
        .typeError(messages.REQUIRED)
        .nullable()
        .required(messages.REQUIRED)
        .test(
          "securityNumberFormat",
          messages.INVALID_VALUE,
          validateSocialSecurityNumber
        ),
    }),
  });
};

export default validation;
