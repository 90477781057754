import { Modal } from "antd";
import classNames from "classnames";
import { useFormik } from "formik";
import { find, get, isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import ContactUs from "../Components/ContactUs";
import Header from "../Components/Header";
import Radio from "../Components/Radio";
import Back from "../Components/svg/back.svg";
import checkMarkSmall from "../Components/svg/check_small-secondary.svg";
import euroIcon from "../Components/svg/euro-icon.svg";
import euroSign from "../Components/svg/euro.svg";
import validation from "./validation";

const formulas = [
  {
    value: "ECONOMIC",
    data: {},
  },
  {
    value: "ESSENTIAL",
    data: {},
  },
  {
    value: "COMFORT",
    data: {},
  },
  {
    value: "OPTIMAL",
    data: {},
  },
  {
    value: "CUSTOM",
    data: {},
  },
];

const View = ({ initialValues, progress, onBack, onNext }) => {
  const [formula, setFormula] = useState(null);
  const [help, setHelp] = useState(true);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.COLLECTION_OF_NEEDS", { returnObjects: true });

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
      { formula }
    ),
  });

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        width={400}
        footer={null}
        className="LMF-formula-modal"
      >
        {formula && (
          <>
            <span className="LMF-formula-modal__title">
              {TRANSLATION.MODAL.FORMULA} "
              {TRANSLATION.FORMULAS[formula.value].LABEL}"
            </span>
            <div className="LMF-formula-modal__content">
              <ul>
                <li>
                  <img src={checkMarkSmall} />
                  <span>Couronne en céramique jusqu’à 200 €</span>
                </li>
                <li>
                  <img src={checkMarkSmall} />
                  <span>Lunette verres simples jusqu’à 120 €</span>
                </li>
                <li>
                  <img src={checkMarkSmall} />
                  <span>
                    Hospitalisation à partir de 40 € la nuit en chambre
                    individuelle
                  </span>
                </li>
              </ul>
            </div>
            <Button
              onClick={() => setOpen(false)}
              className="w-full"
              type="white"
              size="middle"
            >
              <span>{TRANSLATION.CLOSE}</span>
            </Button>
          </>
        )}
      </Modal>
      <Header progressValue={progress} />
      <div className="container __LMF">
        <Button className="back" type="default" onClick={onBack}>
          <img src={Back} />
        </Button>
        <h1 className="header-title">{TRANSLATION.TITLE}</h1>
        <span
          className="header-description"
          dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
        ></span>
        <div className="__LMF--content LMF-content">
          <Radio
            type="list"
            disabled={false}
            values={formulas.map(({ value }) => ({
              label: TRANSLATION.FORMULAS[value].LABEL,
              value,
              subLabel: TRANSLATION.FORMULAS[value].SUB_LABEL,
              onHelpClick: (v) => setOpen(true),
            }))}
            {...bindInputProps({
              name: "formula",
              values,
              setFieldValue,
              ...formProps,
            })}
            onChange={async (value) => {
              setFormula(find(formulas, { value }));
              setFieldValue("formula", value);
              setFieldValue("budget", null);
              await formProps.validateForm();
              formProps.setFieldTouched(`formula`);
            }}
          />
          {get(values, "formula") === "CUSTOM" && (
            <div className="custom-budget">
              <img src={euroIcon} />
              <Input
                label={TRANSLATION.FORM.BUDGET.LABEL}
                placeholder={TRANSLATION.FORM.BUDGET.PLACEHOLDER}
                className={classNames({
                  valid: get(values, "budget"),
                })}
                inputMode="numeric"
                {...bindInputProps({
                  name: `budget`,
                  values,
                  setFieldValue,
                  ...formProps,
                })}
                onChange={({ target: { value } }) =>
                  setFieldValue(`budget`, value)
                }
                suffix={<img src={euroSign} />}
                disabled={false}
              />
            </div>
          )}
        </div>
        <Button
          disabled={!isEmpty(formProps.errors)}
          onClick={handleSubmit}
          type="primary"
          size="large"
        >
          <span>{TRANSLATION.NEXT}</span>
        </Button>
        <ContactUs
          phone={"+212666952697"}
          text={TRANSLATION.NEED_HELP}
          help={help}
          setHelp={setHelp}
        />
      </div>
    </>
  );
};

export default View;
