import { Col, Row } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { useLazyQuery, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { FRANCE_ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  SOCIAL_REGIMES,
} from "utils/constants";
import { getMaxDeltaEnd, getMinDeltaStart } from "utils/helpers/array";
import { hundredYearsAgoStart, isMajor } from "utils/helpers/date";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import ContactUs from "../Components/ContactUs";
import Header from "../Components/Header";
import Back from "../Components/svg/back.svg";
import InputChecked from "../Components/svg/check_small.svg";
import InputError from "../Components/svg/error.svg";
import validation from "./validation";

const View = ({ onNext, onBack, initialValues, locked, progress }) => {
  const [deltaRia, setDeltaRia] = useState(30);
  const [deltaStart, setDeltaStart] = useState(1);
  const [deltaEndRia, setDeltaEndRia] = useState();
  const [deltaEnd, setDeltaEnd] = useState();
  const [help, setHelp] = useState(true);
  const [franceAddressZipCodeCity] = useLazyQuery(FRANCE_ADDRESS_ZIP_CODE_CITY);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.CONTACT", { returnObjects: true });
  useQuery(INSURANCE_PLANS, {
    variables: { where: {} },
    onCompleted: ({ insurancePlans: { data: insurancePlans } }) => {
      const deltaRia = getMinDeltaStart({ insurancePlans, minValue: 30 });
      const deltaStart = getMinDeltaStart({
        insurancePlans,
        minValue: 1,
        key: "DELTA_START",
      });
      const deltaEndRia = getMaxDeltaEnd({ insurancePlans }) || 0;
      const deltaEnd =
        getMaxDeltaEnd({
          insurancePlans,
          key: "DELTA_END",
        }) || 0;
      setDeltaRia(deltaRia);
      setDeltaStart(deltaStart);
      setDeltaEndRia(deltaEndRia);
      setDeltaEnd(deltaEnd);
    },
  });
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
      { franceAddressZipCodeCity, deltaRia, deltaStart, deltaEnd, deltaEndRia }
    ),
  });
  const pickerValue = !get(values, "project.contract.issuanceDate") && dayjs();

  return (
    <>
      <Header progressValue={progress} />
      <div className="container __LMF">
        <Button className="back" type="default" onClick={onBack}>
          <img src={Back} />
        </Button>
        <h1 className="header-title">{TRANSLATION.TITLE}</h1>
        <span
          className="header-description"
          dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
        ></span>
        <div className="__LMF--content LMF-content">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Input
                label={TRANSLATION.FORM.ZIP_CODE.LABEL}
                placeholder={TRANSLATION.FORM.ZIP_CODE.PLACEHOLDER}
                className={classNames({
                  valid: get(
                    values,
                    `contact.telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                  ),
                })}
                inputMode="numeric"
                {...bindInputProps({
                  name: `contact.telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                  values,
                  setFieldValue,
                  ...formProps,
                })}
                onChange={async ({ target: { value } }) => {
                  setFieldValue(
                    `contact.telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                    value.toString()
                  );
                  if (value?.length >= 2)
                    setFieldValue(
                      "contact.fields.department",
                      value.toString().substring(0, 2)
                    );

                  await formProps.validateForm();
                  formProps.setFieldTouched(
                    `contact.telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                  );
                }}
                disabled={locked}
                suffix={
                  get(
                    formProps.errors,
                    `contact.telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                  ) ? (
                    <img src={InputError} />
                  ) : (
                    <img src={InputChecked} />
                  )
                }
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Input
                label={TRANSLATION.FORM.BIRTH_DATE.LABEL}
                placeholder={TRANSLATION.FORM.BIRTH_DATE.PLACEHOLDER}
                className={classNames({
                  valid: get(values, "contact.user.birthDate"),
                })}
                type="date"
                variant="simple"
                format={DATE_FORMAT}
                disabledDate={(current) =>
                  current > isMajor || current < hundredYearsAgoStart
                }
                defaultPickerValue={
                  !get(values, "contact.user.birthDate") &&
                  dayjs().subtract(30, "years")
                }
                {...bindDateInputProps({
                  name: "contact.user.birthDate",
                  values,
                  setFieldValue,
                  ...formProps,
                })}
                disabled={locked}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Select
                options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
                  value,
                  label: TRANSLATION.FORM.SOCIAL_REGIMES[key],
                }))}
                className={classNames({
                  valid: get(values, "contact.socialRegime"),
                })}
                placeholder={TRANSLATION.FORM.SOCIAL_REGIME.PLACEHOLDER}
                label={TRANSLATION.FORM.SOCIAL_REGIME.LABEL}
                {...bindInputProps({
                  name: "contact.socialRegime",
                  values,
                  setFieldValue,
                  ...formProps,
                })}
                onChange={(value) => {
                  setFieldValue("contact.socialRegime", value);
                }}
                disabled={locked}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Input
                type="date"
                variant="simple"
                className={classNames({
                  valid: get(values, "contract.issuanceDate"),
                })}
                label={TRANSLATION.FORM.EFFECTIVE_DATE.LABEL}
                defaultPickerValue={pickerValue}
                placeholder={TRANSLATION.FORM.EFFECTIVE_DATE.PLACEHOLDER}
                disabledDate={(current) => {
                  const validEndDate = values.ria
                    ? deltaEndRia === 0
                      ? dayjs("31/12/2024").endOf("year")
                      : dayjs("31/12/2024").add(deltaEndRia, "days")
                    : deltaEnd === 0
                    ? dayjs("31/12/2024").endOf("year")
                    : dayjs("31/12/2024").add(deltaEnd, "days");
                  const validStartDate = values.ria
                    ? dayjs(
                        moment
                          .max(
                            moment().add(deltaRia, "days"),
                            moment().startOf("y")
                          )
                          .toDate()
                      )
                    : dayjs(
                        moment
                          .max(
                            moment().add(deltaStart, "days"),
                            moment().startOf("y")
                          )
                          .toDate()
                      );
                  return (
                    current &&
                    (current < validStartDate || current > validEndDate)
                  );
                }}
                {...bindDateInputProps({
                  name: "contract.issuanceDate",
                  values,
                  setFieldValue,
                  ...formProps,
                })}
                format={DATE_FORMAT}
                disabled={locked}
              />
            </Col>
          </Row>
        </div>
        <Button
          disabled={!isEmpty(formProps.errors)}
          onClick={handleSubmit}
          type="primary"
          size="middle"
        >
          <span>{TRANSLATION.NEXT}</span>
        </Button>
        <p className="__LMF--hint">{TRANSLATION.HINT}</p>
        <ContactUs
          phone={"+212666952697"}
          text={TRANSLATION.NEED_HELP}
          help={help}
          setHelp={setHelp}
        />
      </div>
    </>
  );
};

export default View;
