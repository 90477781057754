import { get } from "lodash";
import { useState } from "react";
import {
  ACTIVITY_STATUS,
  CURRENCY_SYMBOL,
  INCOME_TYPES,
} from "utils/constants";
import Button from "../Components/Button";
import Card from "../Components/Card";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import Select from "../Components/Select";
import ToggleButton from "../Components/ToggleButton";

function View({ contact, onNext, onBack }) {
  const [selectedValue, setSelectedValue] = useState({
    income: get(contact, "fields.income"),
    incomeType: INCOME_TYPES.ANNUAL,
    establishment: undefined,
    activityTime: undefined,
    cappedIndex: undefined,
    situation: get(contact, "fields.situation"),
    optMadelin: get(contact, "optMadelin"),
  });

  return (
    <div className="solyon">
      <div className="solyon__container">
        <Header
          title="Quel est votre revenus annuel et votre situation ?"
          highlight="revenus annuel, situation"
          subtitle="Informations de l’adhérent"
          onBack={onBack}
        />
        <div className="solyon__body">
          <div className="grid--1" style={{ width: 500 }}>
            <div className="grid--2">
              <Input
                suffix={CURRENCY_SYMBOL.EUR}
                type="number"
                placeholder={"0,00"}
                label={"Revenus annuel"}
                defaultValue={selectedValue.income}
                onChange={({ target: { value } }) =>
                  setSelectedValue((prev) => ({ ...prev, income: value }))
                }
              />
              <Select
                options={Object.values(ACTIVITY_STATUS).map((value) => ({
                  label: value,
                  value,
                }))}
                placeholder={"Sélectionnez ..."}
                label={"Votre situation"}
                defaultValue={selectedValue.situation}
                onChange={(value) =>
                  setSelectedValue((prev) => ({
                    ...prev,
                    situation: value,
                    optMadelin: undefined,
                  }))
                }
              />
            </div>
            {selectedValue.situation === ACTIVITY_STATUS.NON_SALARIED && (
              <Card>
                <ToggleButton
                  label="Loi Madelin"
                  value={selectedValue.optMadelin}
                  onYesClick={() =>
                    setSelectedValue((prev) => ({
                      ...prev,
                      optMadelin: true,
                    }))
                  }
                  onNoClick={() =>
                    setSelectedValue((prev) => ({
                      ...prev,
                      optMadelin: false,
                    }))
                  }
                />
              </Card>
            )}
          </div>
          <Button
            type={"primary"}
            alignment={"center"}
            disabled={!selectedValue.income || !selectedValue.situation}
            onClick={() =>
              onNext({
                payload: {
                  contact: {
                    ...contact,
                    fields: {
                      ...get(contact, "fields", {}),
                      ...selectedValue,
                    },
                  },
                },
              })
            }
          >
            Continuer
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default View;
