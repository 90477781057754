import { Select } from "antd";
import classNames from "classnames";
import { get, isEmpty, isObject } from "lodash";
import { useQuery } from "shared/hooks/useApi";
import Label from "../Label";

const Async = ({
  label: selectLabel,
  node,
  errors,
  icon,
  labels,
  onChange = () => {},
  onSearch = () => {},
  touched,
  required,
  filter,
  optionFilterProp = "label",
  ...rest
}) => {
  const {
    query,
    variables,
    accessors: { root, label = "name", value = "id" },
  } = node;
  const getLabel = (item) => {
    if (!isEmpty(labels)) return labels[get(item, label)];
    if (isObject(label))
      return get(label, "keys", [])
        .map((key) => {
          const [principal, alt] = key.split(get(label, "separator", "-"));
          return get(item, principal) || get(item, alt);
        })
        .filter((value) => value)
        .join(get(label, "separator", "-"));
    return get(item, label);
  };
  const { data, loading } = useQuery(query, { variables });

  const items = get(data, root, []).map((item) => ({
    label: getLabel(item),
    value: get(item, value),
  }));

  return (
    <div
      className={classNames("select__wrapper", { __error: touched && errors })}
    >
      <Label label={selectLabel} icon={icon} required={required} />
      <div className={`select__wrapper--field`}>
        <Select
          size="large"
          className={classNames({ "disable--input": rest.disabled })}
          loading={loading}
          onChange={(value) => {
            return onChange(
              value,
              get(data, root, []).find((item) => item.id === value)
            );
          }}
          showSearch
          optionFilterProp={optionFilterProp}
          onSearch={onSearch}
          options={filter ? items.filter(filter) : items}
          {...rest}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Async;
