import { Input } from "antd";
import PropTypes from "prop-types";

import classnames from "classnames";
import React from "react";
import Label from "../Label";

const Number = ({
  label,
  placeholder,
  errors,
  touched,
  icon,
  innerRef,
  required,
  direction,
  optional,
  maxLength,
  ...rest
}) => {
  const checkMaxLength = ({ target }, maxLength) => {
    if (!maxLength) return;
    let value = target.value.replace(/\D/g, "");
    if (value.length > maxLength - 1) value = value.slice(0, maxLength - 1);
    target.value = value;
  };
  return (
    <div
      className={classnames(
        "solyon-input",
        { "solyon-input__error": touched && errors },
        direction
      )}
    >
      <Label
        label={label}
        icon={icon}
        required={required}
        optional={optional}
      />
      <div className={`solyon-input--field`}>
        <Input
          type="number"
          placeholder={placeholder}
          ref={innerRef}
          {...rest}
          onKeyDown={(_) => checkMaxLength(_, maxLength)}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

Number.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

Number.defaultProps = {
  label: "",
  placeholder: "",
};
export default React.memo(Number);
