import { useLazyQuery } from "@apollo/client";
import { Col, Row, Spin } from "antd";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiAward, FiFile, FiSearch } from "react-icons/fi";
import {
  MdAccountBalance,
  MdOutlineDescription,
  MdPerson,
  MdTrendingUp,
} from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Date from "shared/components/Input/Date";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import {
  DATE_FORMAT,
  SUBSCRIPTION_STATUSES,
  USER_TYPES,
} from "utils/constants";
import ContactsStats from "./Widgets/ContactsStats";
import DepartmentsStats from "./Widgets/DepartmentsStats";
import ProcessStats from "./Widgets/ProcessStats";
import ProductsStats from "./Widgets/ProductsStats";

const Dashboard = () => {
  const { t } = useTranslation(NAME_SPACES.HOME);
  const FILTER = t("FILTER", { returnObjects: true });

  const savedFilters = JSON.parse(localStorage.getItem("dashboardFilters")) || {
    process: undefined,
    insurancePlan: undefined,
    author: undefined,
    dateRange: [],
  };

  const [filters, setFilters] = useState(savedFilters);

  const [fetchQuotes, { data: quoteCountData, loading: quoteCountLoading }] =
    useLazyQuery(graphql.queries.COUNT_QUOTES, {});

  const [
    fetchContactWithProject,
    { data: contactWithProjectCount, loading: contactWithProjectCountLoading },
  ] = useLazyQuery(graphql.queries.COUNT_DISTINCT_CONTACT_WITH_PROJECT, {});

  const [
    fetchContactWithQuote,
    { data: contactWithQuoteCount, loading: contactWithQuoteCountLoading },
  ] = useLazyQuery(graphql.queries.COUNT_DISTINCT_CONTACT_WITH_QUOTE, {});
  const [
    countSubscriptionsMetrics,
    { data: subscriptionMetricsCount, loading: subscriptionsCountLoading },
  ] = useLazyQuery(graphql.queries.COUNT_SUBSCRIPTIONS_METRICS, {});
  const [
    countWebSubscriptions,
    { data: webSubscriptionCount, loading: webSubscriptionsCountLoading },
  ] = useLazyQuery(graphql.queries.COUNT_SUBSCRIPTIONS, {});

  useEffect(() => {
    fetchData(savedFilters);
  }, []);

  const fetchData = (filters) => {
    const quoteWhere = {
      AND: [
        ...(filters.process
          ? [
              {
                project: {
                  statuses: { status: { process: { id: filters.process } } },
                },
              },
            ]
          : []),
        ...(filters.insurancePlan
          ? [
              {
                project: {
                  subscriptions: {
                    insurancePlan: { id: filters.insurancePlan },
                  },
                },
              },
            ]
          : []),
        ...(filters.author
          ? [{ project: { author: { id: filters.author } } }]
          : []),
      ],
    };

    const subscriptionWhere = {
      AND: [
        { status: SUBSCRIPTION_STATUSES.SIGNED },
        ...(filters.process
          ? [
              {
                project: {
                  statuses: { status: { process: { id: filters.process } } },
                },
              },
            ]
          : []),
        ...(filters.insurancePlan
          ? [
              {
                project: {
                  subscriptions: {
                    insurancePlan: { id: filters.insurancePlan },
                  },
                },
              },
            ]
          : []),
        ...(filters.author
          ? [{ project: { author: { id: filters.author } } }]
          : []),
      ],
    };

    const contactWhere = {
      AND: [
        ...(filters.process
          ? [
              {
                projects: {
                  statuses: { status: { process: { id: filters.process } } },
                },
              },
            ]
          : []),
        ...(filters.insurancePlan
          ? [
              {
                projects: {
                  subscriptions: {
                    insurancePlan: { id: filters.insurancePlan },
                  },
                },
              },
            ]
          : []),
        ...(filters.author
          ? [{ projects: { author: { id: filters.author } } }]
          : []),
      ],
    };

    const [startDate, endDate] = get(filters, "dateRange", []) || {};

    fetchQuotes({
      variables: {
        where: quoteWhere,
        gt: { createdDate: startDate },
        lt: { createdDate: endDate },
      },
    });
    countSubscriptionsMetrics({
      variables: {
        where: subscriptionWhere,
        gt: { signedDate: startDate },
        lt: { signedDate: endDate },
      },
    });
    countWebSubscriptions({
      variables: {
        where: { ...subscriptionWhere, project: { author: null } },
        gt: { signedDate: startDate },
        lt: { signedDate: endDate },
      },
    });
    fetchContactWithProject({
      variables: {
        where: contactWhere,
        gt: { createdDate: startDate },
        lt: { createdDate: endDate },
      },
    });
    fetchContactWithQuote({
      variables: {
        where: contactWhere,
        gt: { createdDate: startDate },
        lt: { createdDate: endDate },
      },
    });
  };

  const handleFilterClick = (values) => {
    setFilters(values);
    localStorage.setItem("dashboardFilters", JSON.stringify(values));
    fetchData(values);
  };

  return (
    <>
      <Header title={t("DASHBOARD")} />
      <Card type="simple">
        <Formik initialValues={filters} onSubmit={handleFilterClick}>
          {({ setFieldValue }) => (
            <Form>
              <Row gutter={[16, 16]} align={"bottom"}>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <Date
                    label={FILTER.PERIOD}
                    variant="range"
                    format={DATE_FORMAT}
                    onChange={(dates) => setFieldValue("dateRange", dates)}
                    defaultValue={
                      get(filters, "dateRange", []).length === 2
                        ? [
                            dayjs(get(filters, "dateRange.0")),
                            dayjs(get(filters, "dateRange.1")),
                          ]
                        : undefined
                    }
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={5}>
                  <Select
                    type="async"
                    onChange={(value) => setFieldValue("author", value)}
                    label={FILTER.ADVISOR}
                    node={{
                      query: graphql.queries.USERS,
                      variables: {
                        where: {
                          OR: [
                            { meta: { type: USER_TYPES.BROKER_ADMIN.type } },
                            { meta: { type: USER_TYPES.BROKER_EMPLOYEE.type } },
                            {
                              meta: {
                                type: USER_TYPES.BROKER_REPRESENTATIVE.type,
                              },
                            },
                            {
                              meta: {
                                type: USER_TYPES.INSURANCE_REPRESENTATIVE.type,
                              },
                            },
                            {
                              meta: { type: USER_TYPES.INTERNAL_ADVISOR.type },
                            },
                            {
                              meta: {
                                type: USER_TYPES.INTERNAL_SUPERVISOR.type,
                              },
                            },
                          ],
                        },
                      },
                      accessors: {
                        root: "users.data",
                        label: {
                          separator: " ",
                          keys: ["firstname", "lastname"],
                        },
                      },
                    }}
                    placeholder={FILTER.ADVISOR}
                    allowClear
                    defaultValue={filters.author}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <Select
                    type="async"
                    onChange={(value) => setFieldValue("insurancePlan", value)}
                    label={FILTER.SELECT_PRODUCT}
                    node={{
                      query: graphql.queries.INSURANCE_PLANS,
                      variables: {
                        where: {},
                      },
                      accessors: {
                        root: "insurancePlans.data",
                        label: "name",
                      },
                    }}
                    placeholder={FILTER.SELECT_PRODUCT}
                    allowClear
                    defaultValue={filters.insurancePlan}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={4}>
                  <Select
                    type="async"
                    onChange={(value) => setFieldValue("process", value)}
                    label={FILTER.SALES_JOURNEY}
                    node={{
                      query: graphql.queries.PROCESSES,
                      variables: {
                        where: {},
                      },
                      accessors: {
                        root: "processes.data",
                        label: "name",
                      },
                    }}
                    placeholder={FILTER.SELECT_SALES_JOURNEY}
                    allowClear
                    defaultValue={filters.process}
                  />
                </Col>
                <Col xs={24} sm={24} md={4} lg={3}>
                  <Button
                    key="done"
                    icon={<FiSearch size={20} />}
                    type="primary"
                    size="full--width"
                    htmlType="submit"
                  >
                    {FILTER.FILTER_LABEL}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
      <div className="p-top-20" />
      <Row gutter={[16, 16]} align={"bottom"}>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Card
            type={"count"}
            icon={<FiFile />}
            label={FILTER.NUMBER_OF_QUOTES}
            value={
              quoteCountLoading ? (
                <Spin size="small" />
              ) : (
                quoteCountData?.countQuotes ?? "-"
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Card
            type="count"
            icon={<FiAward />}
            label={FILTER.NUMBER_OF_CONTRACTS}
            value={
              subscriptionsCountLoading ? (
                <Spin size="small" />
              ) : (
                get(
                  subscriptionMetricsCount,
                  "countSubscriptionsMetrics.countSubscriptions"
                ) ?? "-"
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Card
            type="count"
            icon={<MdOutlineDescription />}
            label={FILTER.NUMBER_OF_WEB_CONTRACTS}
            value={
              webSubscriptionsCountLoading ? (
                <Spin size="small" />
              ) : (
                webSubscriptionCount?.countSubscriptions ?? "-"
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Card
            type={"count"}
            icon={<MdPerson />}
            label={FILTER.AVERAGE_AGE}
            value={
              subscriptionsCountLoading ? (
                <Spin size="small" />
              ) : (
                get(
                  subscriptionMetricsCount,
                  "countSubscriptionsMetrics.countSubscriptionsAverageAge"
                ) || "-"
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Card
            type="count"
            icon={<MdAccountBalance />}
            label={FILTER.TURNOVER}
            value={
              subscriptionsCountLoading ? (
                <Spin size="small" />
              ) : (
                get(
                  subscriptionMetricsCount,
                  "countSubscriptionsMetrics.countSubscriptionsTurnover"
                )
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Card
            type="count"
            icon={<MdTrendingUp />}
            label={FILTER.AVERAGE_TURNOVER}
            value={
              subscriptionsCountLoading ? (
                <Spin size="small" />
              ) : (
                get(
                  subscriptionMetricsCount,
                  "countSubscriptionsMetrics.countSubscriptionsAvgTurnover"
                )
              )
            }
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="p-top-20">
        <Col xs={24} sm={24} md={12} lg={12}>
          {contactWithProjectCountLoading || contactWithQuoteCountLoading ? (
            <Spin size="small" />
          ) : (
            <ContactsStats
              title={FILTER.CONTACT_STATS}
              loading={
                contactWithProjectCountLoading || contactWithQuoteCountLoading
              }
              data={[
                {
                  name: FILTER.CONTACT_BY_PROJECT,
                  count:
                    contactWithProjectCount?.countDistinctContactWithProject ||
                    0,
                },
                {
                  name: FILTER.CONTACT_BY_QUOTE,
                  count:
                    contactWithQuoteCount?.countDistinctContactWithQuote || 0,
                },
                {
                  name: FILTER.CONTACT_SIGNED,
                  count:
                    get(
                      subscriptionMetricsCount,
                      "countSubscriptionsMetrics.countSubscriptions"
                    ) || 0,
                },
              ]}
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          {subscriptionsCountLoading ? (
            <Spin size="small" />
          ) : (
            <DepartmentsStats
              title={FILTER.CONTRACT_BY_DEPARTMENTS}
              loading={subscriptionsCountLoading}
              data={get(
                subscriptionMetricsCount,
                "countSubscriptionsMetrics.countSubscriptionsByDepartment",
                []
              )}
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          {subscriptionsCountLoading ? (
            <Spin size="small" />
          ) : (
            <ProductsStats
              title={FILTER.CONTRACT_BY_FORMULAS}
              loading={subscriptionsCountLoading}
              data={get(
                subscriptionMetricsCount,
                "countSubscriptionsMetrics.countSubscriptionsByFormula",
                []
              )}
            />
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="p-top-20">
        <Col span={24}>
          <ProcessStats />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
