import { SCREENS } from "utils/constants";
import List from "./List";
import Lmf from "./Lmf";

const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.UI.LIST.path,
    exact: true,
    element: <List />,
  },
  {
    path: SCREENS.PRIVATE.ADMIN.UI.LMF.path,
    exact: true,
    element: <Lmf />,
  },
];

export default routes;
