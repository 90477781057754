import classNames from "classnames";
import { isString } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Popover from "shared/components/Popover";
import { NAME_SPACES } from "shared/locales/constants";

const Label = ({ label, subLabel, optional, icon, required }) => {
  const { t } = useTranslation(NAME_SPACES.ERRORS);
  const { t: components } = useTranslation(NAME_SPACES.COMPONENTS);
  const LABEL = components("LABEL", { returnObjects: true });
  const VALIDATIONS = t("VALIDATIONS", { returnObjects: true });

  return (
    <div
      className={classNames({ "solyon-label": label, "hide--label": !label })}
    >
      {icon && (
        <span
          className={classNames("solyon-label__icon", { "hide--icon": !icon })}
        >
          {icon}
        </span>
      )}
      <label htmlFor={label}>
        {label}
        {subLabel && <span className="solyon-label__sub">{subLabel}</span>}
      </label>
      {optional && (
        <span className="solyon-label__optional">
          ({isString(optional) ? optional : LABEL.OPTIONAL})
        </span>
      )}
      {required && (
        <div className="popover-wrapper">
          <Popover text={VALIDATIONS.REQUIRED} />
          <span className="solyon-label__required">*</span>
        </div>
      )}
    </div>
  );
};

Label.propTypes = {
  label: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.node,
};

Label.defaultProps = {
  setType: () => {},
};

export default React.memo(Label);
