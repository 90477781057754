import dayjs from "dayjs";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import Loading from "shared/components/Spin";
import { useLazyQuery, useQuery } from "shared/hooks/useApi";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import View from "./View";

const ProcessStats = () => {
  const savedFilters = JSON.parse(
    localStorage.getItem("dashboardProcessFilters")
  ) || {
    process: undefined,
    dateRange: [],
  };
  const [selectedProcess, setSelectedProcess] = useState(savedFilters?.process);
  const [dateRange, setDateRange] = useState(savedFilters?.dateRange || []);
  const [processData, setProcessData] = useState(null);
  const [getProcessData] = useLazyQuery(graphql.queries.PROCESS_STATICS);
  const { data: processes, loading: loadingProcesses } = useQuery(
    graphql.queries.PROCESSES,
    {
      variables: {
        where: {},
      },
    }
  );

  const { loading } = useQuery(graphql.queries.TRACER, {
    variables: { where: {} },
    onCompleted: ({ tracer }) => {
      getProcessStats({
        id: selectedProcess || get(tracer, "flow.process.id"),
        dateRange: isEmpty(dateRange)
          ? [dayjs().startOf("M"), dayjs().endOf("M")]
          : [dayjs(dateRange[0]), dayjs(dateRange[1])],
      });
    },
  });
  useEffect(() => {
    localStorage.setItem(
      "dashboardProcessFilters",
      JSON.stringify({ dateRange, process: selectedProcess })
    );
  }, [selectedProcess, dateRange[0], dateRange[1]]);

  const constructChart = (chartData) => {
    const categories = chartData?.data?.map(({ name }) => name);
    const dataPoints = chartData?.data?.map(({ count }) => count);

    setProcessData({
      options: {
        chart: {
          id: `basic-bar`,
        },
        xaxis: {
          categories,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: [COLORS.C_WHITE],
          },
          formatter: function (val, { dataPointIndex, w }) {
            if (dataPointIndex === 0) return `${val}`;

            const previousValue = w.config.series[0].data[dataPointIndex - 1];
            const percentageChange =
              previousValue === 0
                ? 0
                : (((val - previousValue) / previousValue) * 100).toFixed(2);

            return `${val} (${
              percentageChange > 0 ? "+" : ""
            }${percentageChange}%)`;
          },
          offsetY: -20,
          dropShadow: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: chartData?.process?.name || "-",
          data: dataPoints,
        },
      ],
    });
  };

  const getProcessStats = ({ id, dateRange }) => {
    setSelectedProcess(id);
    dateRange && setDateRange(dateRange);
    getProcessData({
      fetchPolicy: "no-cache",
      variables: {
        where: {
          id,
        },
        gt: {
          createdDate: dateRange
            ? dateRange[0].isValid()
              ? dateRange[0].format("YYYY-MM-DD")
              : dayjs().startOf("y").format("YYYY-MM-DD")
            : null,
        },
        lt: {
          createdDate: dateRange
            ? dateRange[1].isValid()
              ? dateRange[1].format("YYYY-MM-DD")
              : dayjs().endOf("y").format("YYYY-MM-DD")
            : null,
        },
      },
      onCompleted: ({ projectProcessStatusesStats }) => {
        constructChart(projectProcessStatusesStats);
      },
    });
  };
  if (loadingProcesses || loading || !processData) return <Loading />;

  return (
    <View
      dateRange={dateRange}
      getProcessStats={getProcessStats}
      selectedProcess={selectedProcess}
      processes={get(processes, "processes.data")}
      data={processData}
    />
  );
};

export default ProcessStats;
