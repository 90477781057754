import cuid from "cuid";
import { defaults, get, keyBy, map } from "lodash";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import View from "./View";

const AdherentAddress = ({
  project,
  tracer,
  currentStatus,
  onNext,
  onBack,
}) => {
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");

  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
  });

  const next = ({ telecoms }) => {
    const mappedTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    onNext({
      payload: {
        contact: {
          ...get(project, "contact"),
          telecoms: mappedTelecoms,
        },
      },
    });
  };

  const initialValues = {
    telecoms,
  };

  return (
    <View
      onNext={next}
      initialValues={initialValues}
      fields={get(project, "fields", {})}
      onBack={onBack}
      tracer={tracer}
      locked={project.locked}
      progress={currentStatus.progress}
      subscriptionId={subscriptionId}
      project={project}
    />
  );
};

export default AdherentAddress;
