import PropTypes from "prop-types";
import { useMemo } from "react";

import Range from "./Range";
import Simple from "./Simple";

const DIRECTIONS = ["column", "row"];
const MODES = {
  simple: Simple,
  range: Range,
};

const Date = ({ variant, defaultValue, ...props }) => {
  const View = useMemo(() => MODES[variant], [variant]);
  return <View defaultValue={defaultValue || undefined} {...props} />;
};

Date.propTypes = {
  variant: PropTypes.oneOf(Object.keys(MODES)),
  direction: PropTypes.oneOf(DIRECTIONS),
};

Date.defaultProps = {
  variant: "simple",
  direction: "column",
};

export default Date;
