import React, { useMemo } from "react";

import PropTypes from "prop-types";

import Async from "./Async";
import Simple from "./Simple";

const DIRECTIONS = ["column", "row"];
const MODES = {
  simple: Simple,
  async: Async,
};

const Select = ({ type, width, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View style={{ width }} {...props} />;
};

Select.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
  direction: PropTypes.oneOf(DIRECTIONS),
};

Select.defaultProps = {
  type: "simple",
  direction: "column",
};

export default React.memo(Select);
