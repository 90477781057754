// import cuid from "cuid";
// import dayjs from "dayjs";
import { get } from "lodash";
import { useState } from "react";
import { isMajor } from "utils/helpers/date";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
// import { DATE_FORMAT } from "utils/constants";
// import Button from "../Components/Button";
// import Input from "../Components/Input";

function View({ contact, onNext, onBack }) {
  const [selectedValue, setSelectedValue] = useState(
    get(contact, "user.birthDate")
  );

  const handleDateChange = (date) => {
    if (!date) return setSelectedValue(date);
    if (date.isValid() && date.isBefore(isMajor)) {
      const dateWithTime = date
        .set("hour", 2)
        .set("minute", 0)
        .set("second", 0);
      setSelectedValue(dateWithTime);
    }
  };

  return (
    <div className="solyon">
      <div className="solyon__container">
        <Header
          title="À partir de quelle date souhaitez-vous être couvert ?"
          highlight="souhaitez-vous être,couvert ?"
          subtitle="Souscription : Date d’effet"
          onBack={onBack}
        />
        {/* <div className="solyon__body">
          <Input
            width={316}
            type="date"
            format={DATE_FORMAT}
            placeholder={"JJ / MM / AAAA"}
            label={"Date de naissance"}
            defaultValue={selectedValue && dayjs(selectedValue)}
            disabledDate={(current) => current > isMajor}
            defaultPickerValue={!selectedValue && dayjs().subtract(30, "years")}
            onChange={handleDateChange}
          />
          <Button
            type={"primary"}
            alignment={"center"}
            disabled={!selectedValue}
            onClick={() =>
              onNext({
                payload: {
                  contact: {
                    ...contact,
                    user: {
                      id: cuid(),
                      ...get(contact, "user", {}),
                      birthDate: selectedValue,
                    },
                  },
                },
              })
            }
          >
            <div className="d-flex justify--center">Continuer</div>
          </Button>
        </div> */}
        <Footer />
      </div>
    </div>
  );
}

export default View;
