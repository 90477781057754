import cuid from "cuid";
import { isUndefined } from "lodash";
import { useState } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import { JOB_LIST } from "utils/constants";
import FPH from "../Assets/images/FPH.svg";
import FPHChecked from "../Assets/images/FPH_checked.svg";
import active from "../Assets/images/active.svg";
import activeChecked from "../Assets/images/active_checked.svg";
import retired from "../Assets/images/retired.svg";
import retiredChecked from "../Assets/images/retired_checked.svg";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Radio from "../Components/Radio";

function View({ contact, onNext }) {
  const [selectedValue, setSelectedValue] = useState(contact?.profession);

  return (
    <div className="solyon">
      <div className="solyon__container">
        <Header
          title="Quel profil correspond à votre situation?"
          highlight="profil"
          subtitle="Profil"
        />
        <div className="solyon__body">
          <Radio
            size={3}
            defaultValue={selectedValue}
            values={[
              {
                image: FPH,
                checkedImage: FPHChecked,
                label: "Fonction Publique Hospitalière",
                value: JOB_LIST.HOSPITAL_CIVIL_SERVICE,
                subLabel:
                  "Vous travaillez dans la fonction publique hospitalière ? Ce profil est fait pour vous.",
              },
              {
                image: active,
                checkedImage: activeChecked,
                label: "Actif",
                value: JOB_LIST.ACTIVE,
                subLabel:
                  "Vous êtes actuellement en activité dans le secteur privé ou public, étudiant ou en recherche  d’emploi.",
              },
              {
                image: retired,
                checkedImage: retiredChecked,
                value: JOB_LIST.RETIRED,
                label: "Retraité",
                subLabel:
                  "Vous êtes à la retraite ? Ce profil est spécialement  conçu pour vous.",
              },
            ]}
            onChange={setSelectedValue}
          />
          <Button
            type={"primary"}
            width={366}
            alignment={"center"}
            disabled={isUndefined(selectedValue)}
            onClick={() =>
              onNext({
                payload: {
                  contact: {
                    id: cuid(),
                    ...contact,
                    profession: selectedValue,
                  },
                },
              })
            }
          >
            <div className="d-flex justify--center">
              Je valide mon profil
              <MdArrowRightAlt size={20} className="float-right" />
            </div>
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default View;
