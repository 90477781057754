import cuid from "cuid";
import dayjs from "dayjs";
import { get, isString, set } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useEffect, useMemo, useState } from "react";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { GENERATE_QUOTE_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { BUILD_GENERAL_COST } from "utils/api/graphql/mutations/costs";
import {
    CASE_TYPES,
    DATE_FORMAT,
    SUBSCRIPTION_STATUSES,
} from "utils/constants";
import View from "./View";

const EffectiveDate = ({
  tracer,
  project,
  onNext,
  currentStatus,
  onBack,
  updateUrl,
}) => {
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");
  const [loading, setLoading] = useState(true);
  const insurancePlans = useMemo(
    () =>
      get(project, "fields.insurancePlans", [])?.map((id) => ({
        AND: [
          {
            insurancePlans: { id },
          },
          { project: { id: get(project, "id") } },
        ],
      })),
    [project]
  );
  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT);
  const [getSubscription, { data }] = useLazyQuery(
    graphql.queries.SUBSCRIPTION
  );

  const attachmentsFilter = {
    where: {
      OR: [
        {
          project: {
            id: get(project, "id"),
          },
        },
        ...insurancePlans,
      ],
    },
  };

  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id: subscriptionId } },
        },
      ],
    }
  );

  const [generateQuoteDocuments] = useMutation(GENERATE_QUOTE_DOCUMENTS, {
    refetchQueries: [
      {
        query: graphql.queries.ATTACHMENTS,
        awaitRefetchQueries: true,
        variables: attachmentsFilter,
      },
    ],
  });

  const [buildGeneralCost] = useMutation(BUILD_GENERAL_COST, {
    variables: {
      data: {
        project: { id: project.id },
        insurancePlan: {
          id: get(data, "subscription.insurancePlan.id"),
        },
      },
    },
  });

  const [getGeneralCost, { data: generalCost, refetch }] = useLazyQuery(
    graphql.queries.GENERAL_COST,
    {
      variables: {
        where: {
          project: { id: get(project, "id") },
          insurancePlan: {
            id: get(data, "subscription.insurancePlan.id"),
          },
        },
      },
      onCompleted: () => {
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    if (subscriptionId)
      getSubscription({
        variables: { where: { id: subscriptionId } },
        onCompleted: () => {
          getGeneralCost();
        },
      });
  }, []);

  const recalculateCost = ({
    issuanceDate,
    oldIssuanceDate,
    setFieldValue,
    setShowChangeCostModal,
    isCancel,
    isRia,
  }) => {
    const cost = get(generalCost, "generalCost.cost");
    const formattedIssuanceDate = isString(issuanceDate)
      ? moment(issuanceDate)
      : issuanceDate;

    const formattedOldIssuanceDate = isString(oldIssuanceDate)
      ? moment(oldIssuanceDate)
      : oldIssuanceDate;

    if (formattedIssuanceDate.isSame(formattedOldIssuanceDate.format(), "day"))
      return;

    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          contract: {
            id: get(project, "contract.id"),
            issuanceDate,
          },
        },
      },
      onCompleted: () => {
        buildGeneralCost({
          onCompleted: ({ buildGeneralCost: generalCost }) => {
            if (!generalCost.cost) {
              setFieldValue("project.contract.issuanceDate", oldIssuanceDate);
              setShowChangeCostModal({
                noCost: true,
              });
              return updateProject({
                variables: {
                  where: { id: project.id },
                  data: {
                    contract: {
                      id: get(project, "contract.id"),
                      issuanceDate: oldIssuanceDate,
                    },
                  },
                },
                onCompleted: () => {
                  buildGeneralCost();
                },
              });
            }
            if (cost === generalCost.cost) return;
            if (isCancel) {
              setFieldValue("project.contract.issuanceDate", issuanceDate);
              refetch();
              return setShowChangeCostModal({});
            }
            setShowChangeCostModal({
              isRia,
              issuanceDate: {
                old: formattedOldIssuanceDate.format(DATE_FORMAT),
                new: formattedIssuanceDate.format(DATE_FORMAT),
              },
              cost: {
                old: cost,
                new: generalCost.cost,
              },
            });
            refetch();
          },
        });
      },
    });
  };

  const onSubmit = ({ project: projectValues }) => {
    const isEffectiveDateChanged = !dayjs(
      get(projectValues, "contract.issuanceDate")
    ).isSame(dayjs(get(project, "contract.issuanceDate")));
    const subscription = omitDeep(get(data, "subscription"), "__typename");
    set(subscription, "project", {
      ...projectValues,
      terminationRequest: {
        id: cuid(),
        ...projectValues.terminationRequest,
        brokerageMandate: {
          id: cuid(),
          ...projectValues.terminationRequest?.brokerageMandate,
        },
      },
    });
    set(
      subscription,
      "project.contract.caseType",
      get(subscription, "project.ria")
        ? CASE_TYPES.RETURN_TO_COMPETITION
        : CASE_TYPES.NEW_CONTRACT
    );
    set(subscription, "status", SUBSCRIPTION_STATUSES.IN_PROGRESS);
    updateSubscription({
      variables: {
        where: {
          id: subscriptionId,
        },
        data: subscription,
      },
      onCompleted: (_) => {
        const source = localStorage.getItem("source");
        if (isEffectiveDateChanged)
          generateQuoteDocuments({
            variables: {
              data: {
                project: { id: get(project, "id") },
                insurancePlans: [{ id: get(subscription, "insurancePlan.id") }],
              },
            },
          });
        updateUrl({
          subscriptionId,
          url: get(
            data,
            "subscription.insurancePlan.config.generatedUrl",
            `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
          ),
        });
        onNext({
          payload: {},
        });
      },
    });
  };

  if (loading) return <Loading />;

  const initialValues = {
    resiliation: get(project, "ria") || get(project, "fields.selfOccupation"),
    project: {
      ...omitDeep(project, "__typename"),
      contract: {
        ...get(omitDeep(project, "__typename"), "contract"),
      },
    },
  };

  return (
    <View
      initialValues={initialValues}
      tracer={tracer}
      project={project}
      locked={get(project, "locked", get(data, "subscription.locked", false))}
      progress={currentStatus.progress}
      subscription={get(data, "subscription")}
      recalculateCost={recalculateCost}
      onBack={onBack}
      onNext={onSubmit}
    />
  );
};
export default EffectiveDate;
