import { find, get } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Subscription from "shared/components/Subscription";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { UPDATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { TRACER } from "utils/api/graphql/queries/tracers";
import {
  COMMISSION_TYPES,
  INSURANCE_PLAN_PARAMS_RULES,
  PROCESS_TAGS,
  SCREENS,
  SUBSCRIPTION_STATUSES,
  THEMES_PALETTE,
} from "utils/constants";
import GenerateLinkModal from "./GenerateLinkModal";

const Subscriptions = ({ translation, data, project, onChangeStatus }) => {
  const navigate = useNavigate();
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION);
  const [open, setOpen] = useState({});
  const [getTracer] = useLazyQuery(TRACER);
  const onClick = (id) => {
    if (find(data, { id })?.status === SUBSCRIPTION_STATUSES.DRAFT) {
      const currentStatuses = get(
        project,
        "statuses.0.status.process.processStatuses",
        []
      );
      const subscriptionInProgress = find(currentStatuses, {
        tag: PROCESS_TAGS.CURRENT_SUBSCRIPTION,
      });
      onChangeStatus({ project, status: subscriptionInProgress });
      updateSubscription({
        variables: {
          where: {
            id,
          },
          data: { status: SUBSCRIPTION_STATUSES.IN_PROGRESS },
        },
      });
    }
    navigate(`${SCREENS.PRIVATE.BROKER.SUBSCRIPTIONS.DETAIL.path}/${id}`);
  };

  const onOpenGenerateLink = (data) => setOpen(data);

  return (
    <>
      <GenerateLinkModal
        open={open}
        setOpen={setOpen}
        template={get(project, "fields.processTemplate")}
      />
      {data.map(({ id, status, insurancePlan, project, additionalInfo }) => {
        const { cost, currency, commission } = find(
          get(project, "generalCosts"),
          (cost) => get(cost, "insurancePlan.id") === get(insurancePlan, "id")
        );
        const totalCost = get(
          get(project, "fields.upsells", {}),
          insurancePlan.id,
          []
        )
          ?.reduce((acc, curr) => acc + curr.cost, cost)
          ?.toFixed(2);
        const expired =
          project.expired ||
          get(insurancePlan, "params", []).some((param) => {
            return (
              moment(get(project, "contract.issuanceDate"))
                .startOf("day")
                .diff(moment().startOf("day"), "days") <
              get(param, "rules", []).find(
                ({ name }) =>
                  name ===
                  (get(project, "ria")
                    ? INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA
                    : INSURANCE_PLAN_PARAMS_RULES.DELTA_START)
              )?.value
            );
          });
        return (
          <Subscription
            key={id}
            translation={translation.SUBSCRIPTION}
            date={get(project, "contract.issuanceDate")}
            user={`${get(project, "contact.user.firstname")} ${get(
              project,
              "contact.user.lastname"
            )}`}
            status={translation.SUBSCRIPTION.STATUSES[status]}
            product={get(insurancePlan, "name")}
            category={get(insurancePlan, "description")}
            icon={get(insurancePlan, "icon")}
            cost={totalCost}
            period={translation.MONTH}
            currency={currency}
            onClick={() => onClick(id)}
            disabled={{
              status: expired,
              text: translation.SUBSCRIPTION.POPOVER,
            }}
            tag={
              get(commission, "type") === COMMISSION_TYPES.LINEAR
                ? `${get(commission, "value", "-")}/${get(
                    commission,
                    "value",
                    "-"
                  )}`
                : `${get(commission, "value", "-")}/${get(
                    commission,
                    "withholding",
                    "-"
                  )}`
            }
            onOpenGenerateLink={async () => {
              const {
                data: {
                  tracer: { source },
                },
              } = await getTracer({
                variables: {
                  where: {
                    flow: {
                      process: {
                        theme:
                          THEMES_PALETTE[get(project, "fields.processTemplate")]
                            .PROSPECT_PROCESS,
                      },
                    },
                  },
                },
              });
              onOpenGenerateLink({
                projectId: project?.id,
                subscriptionId: id,
                insurancePlanIds: [insurancePlan?.id],
                isInfoCompleted: additionalInfo?.isInfoCompleted,
                baseUrl: get(
                  insurancePlan,
                  "config.generatedUrl",
                  `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source.id}`
                ),
              });
            }}
          />
        );
      })}
    </>
  );
};

export default Subscriptions;
