import { notification } from "antd";
import cuid from "cuid";
import { filter, find, get, head, isNil, last, sortBy } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import format from "string-template";
import { GENERATE_QUOTE_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { BUILD_GENERAL_COSTS } from "utils/api/graphql/mutations/costs";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import { CREATE_LINK } from "utils/api/graphql/mutations/link";
import {
  DUPLICATE_PROJECT,
  UPDATE_PROJECT,
} from "utils/api/graphql/mutations/projects";
import { SEND_B2C_QUOTE } from "utils/api/graphql/mutations/quotes";
import { CREATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { GENERAL_COSTS } from "utils/api/graphql/queries/general-cost";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { PROJECT } from "utils/api/graphql/queries/projects";
import {
  ACTION_TYPES,
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  INSURANCE_PLAN_PARAMS_RULES,
  PROCESS_TYPES,
  SCREENS,
  TEMPLATES,
  TEMPLATE_TYPES,
} from "utils/constants";
import { getMinDeltaStart } from "utils/helpers/array";
import { filterByConditions } from "../../../..";
import View from "./View";

const findStatus = (data, template) => {
  return find(data, ({ template: _ }) => _ === template);
};
const generateSQLCondition = (ruleName, condition, value) => {
  return format(
    `EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}' AND ({condition})) OR NOT EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}')`,
    { ruleName, condition: condition.replace(":value", value) }
  );
};

const Products = ({
  processId,
  tracer,
  currentStatus,
  project,
  onNext,
  onBack,
}) => {
  const navigate = useNavigate();
  const [getProject] = useLazyQuery(PROJECT, {
    fetchPolicy: "no-cache",
    variables: { where: { id: project.id } },
  });
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [buildGeneralCosts] = useMutation(BUILD_GENERAL_COSTS);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [generalCosts, { data }] = useLazyQuery(GENERAL_COSTS);
  const [addSubscription] = useMutation(CREATE_SUBSCRIPTION);
  const [getDeltaStart] = useLazyQuery(INSURANCE_PLANS, {
    variables: {
      where: {
        isStandalone: true,
      },
    },
  });
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const [generateQuoteDocuments] = useMutation(GENERATE_QUOTE_DOCUMENTS);
  const [sendQuoteMutation] = useMutation(SEND_B2C_QUOTE);
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const [duplicateProject] = useMutation(DUPLICATE_PROJECT);
  const [addLink] = useMutation(CREATE_LINK);

  const duplicateProjectOnLocked = () => {
    const { insurancePlans, currentSubscription, nextQuoteStatus, ...fields } =
      project?.fields;

    const Template =
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE_COSA]
        .PRODUCTS;
    const status = findStatus(
      get(tracer, "flow.process.processStatuses", []),
      Template
    );
    const actionsToExecute = filterByConditions(
      get(status, "actions", []),
      project
    );
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({
        id: cuid(),
        status: { id: status },
      }));
    duplicateProject({
      variables: {
        where: { id: project.id },
        data: {
          statuses: [{ id: cuid(), status: { id: status.id } }, ...syncActions],
          fields,
        },
      },
      onCompleted: ({ duplicateProject }) => {
        localStorage.setItem("projectId", duplicateProject?.id);
        navigate(
          `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${duplicateProject?.id}`,
          {
            state: {
              process: get(omitDeep(tracer, "__typename"), "flow.process"),
              tracer: omitDeep(tracer, "__typename"),
            },
          }
        );
      },
    });
  };

  const getInTouch = () => {
    return onNext({
      payload: {
        contact: {
          ...get(project, "contact"),
          fields: {
            ...get(project, "contact.fields"),
            requestHelp: true,
          },
        },
      },
    });
  };

  const onSendQuote = async (insurancePlanId) => {
    const {
      data: { project },
    } = await getProject();
    const initialInsurancePlans = get(project, "fields.insurancePlans", []);
    if (initialInsurancePlans.includes(insurancePlanId)) {
      return onNext({
        payload: {
          fields: {
            ...project.fields,
            currentSubscription: get(project, "subscriptions", []).find(
              ({ insurancePlan }) => insurancePlan.id === insurancePlanId
            )?.id,
          },
        },
      });
    }

    const status = get(
      last(
        sortBy(
          filter(
            project.statuses,
            ({ status }) => status.process.id === processId
          ),
          "createdDate"
        )
      ),
      "status"
    );
    const { possibleStatuses } = status;
    const [possibleStatus] = filterByConditions(
      sortBy(possibleStatuses, "order"),
      {
        ...project,
        status,
      }
    );
    const needs = get(
      head(
        omitDeep(get(data, "generalCosts.data"), "__typename").filter(
          ({ insurancePlan: { id } }) => id === insurancePlanId
        )
      ),
      "insurancePlan.insurancePlanCoverages",
      []
    ).reduce((acc, current) => {
      acc[get(current, "coverageCategory.value")] =
        get(current, "needs.level", 1) || 1;
      return acc;
    }, {});

    addSubscription({
      variables: {
        data: {
          id: cuid(),
          project: { id: project.id },
          insurancePlan: {
            id: insurancePlanId,
          },
        },
      },
      onCompleted: ({
        addSubscription: { id: subscriptionId, insurancePlan },
      }) => {
        onNext({
          payload: {
            locked: false,
            visible: true,
            fields: {
              ...project.fields,
              insurancePlans: [
                ...get(project, "fields.insurancePlans", []),
                insurancePlanId,
              ],
              selectedToCompare: [
                ...get(project, "fields.selectedToCompare", []),
                insurancePlanId,
              ],
              ...(!isNil(possibleStatus?.nextStatus) && {
                nextQuoteStatus: possibleStatus.nextStatus,
              }),
              needs,
              currentSubscription: subscriptionId,
            },
          },
          onCompleted: () => {
            notification.open({
              message: "Devis envoyé",
              duration: 5,
              icon: <IoCheckmarkCircleOutline color={COLORS.C_SUCCESS} />,
            });
            // generateQuoteDocuments({
            //   variables: {
            //     data: {
            //       project: { id: project.id },
            //       insurancePlans: [{ id: insurancePlanId }],
            //     },
            //   },
            //   onCompleted: () => {
            //     const source = localStorage.getItem("source");
            //     addLink({
            //       variables: {
            //         data: {
            //           url: get(
            //             insurancePlan,
            //             "config.generatedUrl",
            //             `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
            //           ),
            //           project: { id: project.id },
            //           subscription: { id: subscriptionId },
            //           fields: {
            //             process: { id: processId },
            //             status: { id: get(possibleStatus, "nextStatus") },
            //           },
            //         },
            //       },
            //       onCompleted: ({ addLink }) => {
            //         sendQuoteMutation({
            //           variables: {
            //             data: {
            //               link: addLink?.id,
            //               project: { id: project.id },
            //               insurancePlans: [{ id: insurancePlanId }],
            //             },
            //           },
            //           onCompleted: () =>
            //             notification.open({
            //               message: "Devis envoyé",
            //               duration: 5,
            //               icon: (
            //                 <IoCheckmarkCircleOutline
            //                   color={COLORS.C_SUCCESS}
            //                 />
            //               ),
            //             }),
            //         });
            //       },
            //     });
            //   },
            // });
          },
        });
      },
    });
  };

  useEffect(() => {
    getDeltaStart({
      onCompleted: ({ insurancePlans: { data: insurancePlans } }) => {
        const deltaRia = getMinDeltaStart({ insurancePlans, minValue: 30 });
        const deltaStart = getMinDeltaStart({
          insurancePlans,
          minValue: 3,
          key: "DELTA_START",
        });
        const deltaS = project.ria ? deltaRia : deltaStart;
        if (project?.expired) {
          notification.open({
            message: format(TRANSLATION.EXPIRATION.TITLE, {
              issuanceDate: moment().add(deltaS, "days").format(DATE_FORMAT),
            }),
            description: TRANSLATION.EXPIRATION.DESCRIPTION,
            duration: 5,
            icon: <HiOutlineExclamationCircle size={32} />,
            type: "error",
          });
        }
        updateProject({
          variables: {
            where: { id: project.id },
            data: {
              expired: false,
              contract: {
                ...project.contract,
                issuanceDate: moment()
                  .add(deltaS, "days")
                  .isAfter(project?.contract?.issuanceDate)
                  ? moment().add(deltaS, "days")
                  : project?.contract?.issuanceDate,
              },
            },
          },
          onCompleted: ({ updateProject: project }) => {
            const issuanceDate = moment(
              get(project, "contract.issuanceDate")
            ).startOf("day");
            const issuanceDateDifference = issuanceDate.diff(
              moment().startOf("day"),
              "days"
            );
            const adherentAge = moment(
              get(project, "contact.user.birthDate")
            ).startOf("year");

            const age = moment().diff(adherentAge, "years");

            const [startKey, endKey] = project.ria
              ? [
                  INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
                  INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
                ]
              : [
                  INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
                  INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
                ];

            const socialRegime = get(project, "contact.socialRegime");

            const exerciseFrame = get(project, "contact.exerciseFrame");

            const department = get(
              get(project, "contact.telecoms", []).find(
                ({ system }) => system === CONTACT_POINT_SYSTEM.ZIP_CODE
              ),
              "value",
              ""
            )
              .toString()
              .substring(0, 2);

            const sqlConditions = [
              generateSQLCondition(
                startKey,
                "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
                issuanceDateDifference
              ),
              generateSQLCondition(
                endKey,
                `r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value ${
                  moment(get(project, "contract.issuanceDate")).year() ===
                  moment().year()
                    ? "OR CAST(r.value AS NUMERIC) = 0"
                    : ""
                }`,
                issuanceDateDifference
              ),
              generateSQLCondition(
                INSURANCE_PLAN_PARAMS_RULES.MIN_AGE,
                "r.value IS NULL OR CAST(r.value AS NUMERIC) <= :value",
                age
              ),
              generateSQLCondition(
                INSURANCE_PLAN_PARAMS_RULES.MAX_AGE,
                "r.value IS NULL OR CAST(r.value AS NUMERIC) >= :value",
                age
              ),
              // generateSQLCondition(
              //   INSURANCE_PLAN_PARAMS_RULES.EXERCISE_FRAME,
              //   "r.value IS NULL OR CAST(r.value AS TEXT) = '' OR CAST(r.value AS TEXT) = ':value' ",
              //   exerciseFrame
              // ),
              generateSQLCondition(
                INSURANCE_PLAN_PARAMS_RULES.SOCIAL_REGIMES,
                `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
                `'${socialRegime}'`
              ),
              generateSQLCondition(
                INSURANCE_PLAN_PARAMS_RULES.DEPARTMENTS,
                `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
                `'${department}'`
              ),
              generateSQLCondition(
                INSURANCE_PLAN_PARAMS_RULES.PROCESSES,
                `r.value IS NULL OR r.value @> to_jsonb(ARRAY[:value])::jsonb OR jsonb_array_length(r.value) = 0`,
                `'${processId}'`
              ),
            ];

            const insurancePlanFilter = {
              where: {
                isStandalone: true,
              },
              raw: sqlConditions,
              custom: {
                AND: [
                  {
                    OR: [
                      { startDate: null },
                      {
                        startDate: issuanceDate,
                        operation: "<=",
                      },
                    ],
                  },
                  {
                    OR: [
                      { endDate: null },
                      {
                        endDate: issuanceDate,
                        operation: ">=",
                      },
                    ],
                  },
                ],
              },
            };
            buildGeneralCosts({
              variables: {
                data: {
                  project: { id: project.id },
                  insurancePlanFilter,
                },
              },
              onCompleted: ({ buildGeneralCosts }) => {
                const ids = buildGeneralCosts
                  .filter(({ cost }) => cost)
                  .map(({ id }) => id);
                generalCosts({
                  variables: {
                    isIn: { id: ids },
                  },
                  onCompleted: () => setLoading(false),
                });
              },
            });
          },
        });
      },
    });
  }, [refetch]);

  const refetchCosts = ({ contact }) => {
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          contact,
        },
      },
      onCompleted: () => setRefetch((prev) => !prev),
    });
  };
  if (loading) return <Loading />;
  return (
    <View
      products={omitDeep(get(data, "generalCosts.data"), "__typename")}
      onBack={onBack}
      onSendQuote={onSendQuote}
      contact={get(project, "contact", {})}
      refetchCosts={refetchCosts}
      downloadSingleDocument={downloadSingleDocument}
      locked={project.locked}
      duplicateProject={duplicateProjectOnLocked}
      progress={currentStatus.progress}
      getInTouch={getInTouch}
    />
  );
};

export default Products;
