import Button from "./Button";
import Label from "./Label";

const ToggleButton = ({
  label,
  onYesClick = () => {},
  onNoClick = () => {},
  value,
  yesLabel = "Oui",
  noLabel = "Non",
  disabled = false,
}) => {
  return (
    <div className="d-flex justify--between">
      <Label label={label} />
      <div className="d-flex">
        <Button
          size={"small"}
          type={value ? "primary" : "default"}
          alignment="center"
          disabled={disabled}
          onClick={onYesClick}
        >
          {yesLabel}
        </Button>
        <Button
          size={"small"}
          type={!value ? "primary" : "default"}
          alignment="center"
          disabled={disabled}
          onClick={onNoClick}
        >
          {noLabel}
        </Button>
      </div>
    </div>
  );
};

export default ToggleButton;
