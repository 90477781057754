import classNames from "classnames";
import cuid from "cuid";
import lodash, { filter, find, get, isEmpty, last, sortBy } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useMemo, useState } from "react";
import {
  useBeforeUnload,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import format from "string-template";
import graphql from "utils/api/graphql";
import { CREATE_LINK } from "utils/api/graphql/mutations/link";
import { CREATE_WORKFLOW_WEBHOOK } from "utils/api/graphql/mutations/webhook";
import { ACTION_TYPES, THEMES_PALETTE, TRACER_TYPES } from "utils/constants";
import HighContactCard from "./HighContactCard";
import Templates from "./Templates";

const lodashOperations = {
  identity: ({ entity, condition }) =>
    lodash[condition.operation](get(entity, condition.leftOperand.accessor)) ===
    condition.rightOperand.value,
  default: ({ entity, condition }) =>
    lodash[condition.operation](
      condition.rightOperand.value,
      get(entity, condition.leftOperand.accessor)
    ),
};

export const filterByConditions = (array, entity) =>
  array.filter((element = {}) => {
    return element.conditions
      .map((condition) => {
        return (
          lodashOperations[condition.operation] || lodashOperations.default
        )({
          entity,
          condition,
        });
      })
      .every((x) => x === true);
  });

const Detail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [displayProject, setDisplayProject] = useState(null);
  const [addWorkflowWebhook] = useMutation(CREATE_WORKFLOW_WEBHOOK);
  const [addLink] = useMutation(CREATE_LINK);
  const [currentStatus, setCurrentStatus] = useState(get(state, "status"));
  const [templateHistory, setTemplateHistory] = useState(
    JSON.parse(localStorage.getItem("templateHistory"))
  );

  const handleBeforeUnload = () => {
    localStorage.setItem("templateHistory", JSON.stringify(templateHistory));
  };

  useBeforeUnload(handleBeforeUnload, { capture: true });

  useEffect(() => {
    setDisplayProject(null);
  }, [id]);

  const ACTIONS = {
    [ACTION_TYPES.NAVIGATE]: ({ args = {}, actionsTemplate = {} }) => {
      if (isEmpty(actionsTemplate)) return;
      navigate(format(args.url, actionsTemplate));
    },
    [ACTION_TYPES.WEBHOOK]: ({ args = {} }) => {
      if (args.workflow)
        addWorkflowWebhook({
          variables: {
            data: {
              id: cuid(),
              meta: {
                workflow: args.workflow,
                project: id,
              },
            },
          },
        });
    },
    [ACTION_TYPES.SYNC_PROCESSES]: () => {},
    [ACTION_TYPES.NO_SYNC_PROCESSES]: () => {},
    DEFAULT: () => {},
  };

  useQuery(graphql.queries.PROJECT, {
    variables: { where: { id } },
    fetchPolicy: "no-cache",
    onCompleted: ({ project }) => {
      localStorage.setItem("projectId", project?.id);
      if (!isEmpty(displayProject)) return;
      const projectData = omitDeep(project, "__typename");

      setDisplayProject({
        ...projectData,
        fields: {
          ...get(projectData, "fields", {}),
          currentSubscription:
            get(state, "subscriptionId") ||
            get(projectData, "fields.currentSubscription"),
        },
      });
      const processId = get(state, "process.id");
      const statuses = sortBy(
        filter(
          project.statuses,
          ({ status }) => get(status, "process.id") === processId
        ),
        "createdDate"
      );
      if (!isEmpty(state.status)) {
        return setCurrentStatus(state.status);
      }
      setCurrentStatus(get(last(statuses), "status"));
    },
  });

  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT, {
    refetchQueries: [
      {
        query: graphql.queries.PROJECT,
        awaitRefetchQueries: true,
        variables: { where: { id: displayProject?.id || id } },
      },
    ],
  });
  const onNext = async ({
    payload = {},
    actionsTemplate = {},
    onCompleted = () => {},
  }) => {
    const project = omitDeep({ ...displayProject, ...payload }, "__typename");
    const { possibleStatuses, actions } = currentStatus;
    const [possibleStatus] = filterByConditions(
      sortBy(possibleStatuses, "order"),
      {
        ...project,
        status: currentStatus,
      }
    );
    const actionsToExecute = filterByConditions(actions || [], project);
    if (!isEmpty(actionsTemplate)) {
      const actionsToExecute = filterByConditions(actions || [], project);
      for (const { args, type } of actionsToExecute) {
        await (ACTIONS[type] || ACTIONS.DEFAULT)({ args, actionsTemplate });
      }
    }
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({ id: cuid(), status: { id: status } }));
    const noSyncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.NO_SYNC_PROCESSES)
      .map(({ args: { process } }) =>
        get(displayProject, "statuses", []).find(
          ({ status }) => get(status, "process.id") === process
        )
      );
    const nextStatus = find(
      get(state, "process.processStatuses"),
      ({ id }) => id === possibleStatus?.nextStatus
    );

    let _statuses;
    setTemplateHistory((prev) => ({
      ...prev,
      [possibleStatus?.nextStatus]: currentStatus,
    }));

    if (
      parseInt(get(nextStatus, "order")) >=
      parseInt(
        get(
          get(project, "statuses").find(
            ({ status }) =>
              get(status, "process.id") === get(state, "process.id")
          ),
          "status.order"
        )
      )
    ) {
      _statuses = [
        possibleStatus && {
          id: cuid(),
          status: { id: possibleStatus?.nextStatus },
        },
        ...syncActions,
        ...noSyncActions,
      ];
    }
    const dataPayload = {
      ...payload,
      ...((_statuses || payload.statuses) && {
        statuses: _statuses || payload.statuses,
      }),
    };

    if (isEmpty(dataPayload) && !isEmpty(nextStatus))
      return setCurrentStatus(nextStatus);

    updateProject({
      variables: {
        where: {
          id: project.id,
        },
        data: dataPayload,
      },
      onCompleted: ({ updateProject }) => {
        onCompleted();
        setDisplayProject(omitDeep(updateProject, "__typename"));
        if (!isEmpty(nextStatus)) setCurrentStatus(nextStatus);
      },
    });
  };

  const updateUrl = async ({ subscriptionId, url }) => {
    const { possibleStatuses } = currentStatus;
    const [possibleStatus] = filterByConditions(
      sortBy(possibleStatuses, "order"),
      {
        ...displayProject,
        status: currentStatus,
      }
    );
    const nextStatus = find(
      get(state, "process.processStatuses"),
      ({ id }) => id === possibleStatus?.nextStatus
    );
    if (isEmpty(nextStatus)) return;
    addLink({
      variables: {
        data: {
          url,
          project: { id },
          subscription: { id: subscriptionId },
          fields: {
            process: { id: get(state, "process.id") },
            status: { id: nextStatus?.id },
          },
        },
      },
    });
  };

  const onBack = (props) => {
    const steps = props?.steps || 1;
    if (steps === 1 && get(templateHistory, currentStatus?.id)) {
      return setCurrentStatus(get(templateHistory, currentStatus?.id));
    }
    const process = get(state, "process");
    const sortedStatuses = sortBy(
      process?.processStatuses,
      ({ order }) => -parseInt(order, 10)
    );
    const currentStatusOrder = get(currentStatus, "order");

    let targetStatus;
    let stepsRemaining = steps;
    for (let status of sortedStatuses) {
      if (parseInt(status.order, 10) < currentStatusOrder) {
        stepsRemaining--;
        if (stepsRemaining === 0) {
          targetStatus = status;
          break;
        }
      }
    }
    if (targetStatus) {
      setCurrentStatus(targetStatus);
    }
  };

  const WithContactCard = useMemo(() => {
    const Template = get(
      get(
        Templates[get(state, "tracer.type")],
        get(state, "process.theme", "DEFAULT"),
        Templates[get(state, "tracer.type")].DEFAULT
      ),
      get(currentStatus, "template")
    );
    document.title =
      THEMES_PALETTE[get(state, "process.theme", "DEFAULT")]?.TITLE ||
      THEMES_PALETTE.DEFAULT.TITLE;
    if (!Template) return;
    return HighContactCard(Template);
  }, [currentStatus?.template]);

  if (!displayProject)
    return get(
      get(
        Templates[get(state, "tracer.type")],
        get(state, "process.theme", "DEFAULT")
      ),
      "Loading",
      <Loading />
    );

  return (
    <div className={get(state, "process.theme", "DEFAULT")}>
      <div
        className={classNames({
          locked:
            get(state, "tracer.type") === TRACER_TYPES.MANUAL &&
            displayProject?.locked,
        })}
      >
        <WithContactCard
          contactId={get(displayProject, "contact.id")}
          visible={
            get(displayProject, "contact.user.firstname") &&
            get(state, "tracer.type") === TRACER_TYPES.MANUAL
          }
          project={displayProject}
          processId={get(state, "process.id")}
          tracer={get(state, "tracer")}
          onNext={onNext}
          onBack={onBack}
          setCurrentStatus={setCurrentStatus}
          setDisplayProject={setDisplayProject}
          updateUrl={updateUrl}
          currentStatus={currentStatus}
        />
      </div>
    </div>
  );
};

export default Detail;
