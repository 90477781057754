import moment from "moment";
import format from "string-template";
import { CONTACT_POINT_SYSTEM, DATE_FORMAT, REGEX_EXPS } from "utils/constants";
import { isMajor } from "utils/helpers/date";
import * as yup from "yup";

const validation = (
  messages,
  {
    franceAddressZipCodeCity: validateZipCode,
    deltaRia,
    deltaStart,
    deltaEnd,
    deltaEndRia,
  }
) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  return yup.object().shape({
    contract: yup.mixed().when("locked", {
      is: (locked) => locked,
      then: yup.object().shape({
        issuanceDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED),
      }),

      otherwise: yup.object().shape({
        issuanceDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .min(
            moment().endOf("day").add(deltaStart, "days"),
            format(messages.MIN_DATE, {
              date: moment()
                .endOf("day")
                .add(deltaStart, "days")
                .format(DATE_FORMAT),
            })
          )
          .max(
            deltaEnd === 0
              ? moment().endOf("year")
              : moment().endOf("day").add(deltaEnd, "days"),
            format(messages.MAX_DATE, {
              date:
                deltaEnd === 0
                  ? moment().endOf("year").format(DATE_FORMAT)
                  : moment()
                      .endOf("day")
                      .add(deltaEnd, "days")
                      .format(DATE_FORMAT),
            })
          )
          .required(messages.REQUIRED),
      }),
    }),
    contact: yup.object().shape({
      user: yup.object().shape({
        birthDate: yup
          .date() 
          .typeError(messages.REQUIRED)
          .max(isMajor, messages.AGE_18)
          .min(moment().subtract(100, "year").startOf("year"), messages.AGE_18)
          .required(messages.REQUIRED),
      }),
      socialRegime: requiredString,
      telecoms: yup.object().shape({
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
          value: yup
            .string()
            .typeError(messages.REQUIRED)
            .test("isValid", messages.INVALID_VALUE, async function (value) {
              const isValidRegex = new RegExp(REGEX_EXPS.zipCode).test(value);
              if (!isValidRegex) return false;
              const {
                data: {
                  franceAddressZipCodeCity: { response },
                },
              } = await validateZipCode({
                variables: {
                  where: { zipCode: value },
                },
              });
              return response.length;
            }),
        }),
      }),
    }),
  });
};

export default validation;
