import { isFunction } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function Header({ title, highlight, subtitle, onBack }) {
  let highlightWords = [];
  if (highlight) {
    if (Array.isArray(highlight)) {
      highlightWords = highlight;
    } else if (typeof highlight === "string") {
      highlightWords = highlight
        .split(",")
        .map((word) => word.trim())
        .filter(Boolean);
    }
  }

  let regexPattern = null;
  if (highlightWords.length > 0) {
    const escapedWords = highlightWords.map((word) => escapeRegExp(word));
    regexPattern = new RegExp(`(${escapedWords.join("|")})`, "gi");
  }

  let processedTitle = title.replace(/\\n|\r?\n/g, "<br />");

  if (regexPattern) {
    processedTitle = processedTitle.replace(regexPattern, `<span>$1</span>`);
  }

  return (
    <div className="solyon-header">
      {subtitle && <div className="solyon-header__subtitle">{subtitle}</div>}
      <div className="solyon-header__row">
        {isFunction(onBack) && (
          <MdKeyboardBackspace
            className="solyon-header__row--icon"
            onClick={onBack}
          />
        )}
        <div
          className="solyon-header__title"
          dangerouslySetInnerHTML={{ __html: processedTitle }}
        />
      </div>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  highlight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  subtitle: PropTypes.string,
  onBack: PropTypes.func,
};

export default React.memo(Header);
