import { Radio } from "antd";
import { range } from "lodash";

const options = { 1: "Faible", 2: "Modéré", 3: "Élevé", 4: "Très élevé" };

const Needs = ({ categories = [], selectedValues, setSelectedValues }) => {
  const levels = range(1, 5);

  const handleChange = (name, value) => {
    setSelectedValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="solyon-scroll-container">
      <div className="solyon-needs">
        <div className="selection-grid">
          {categories.map((category) => (
            <div className="grid--1">
              <div className="selection-card">
                <div className="selection-header">
                  <img src={category.icon} alt="" className="selection-icon" />
                  <h3 className="selection-title">{category.label}</h3>
                </div>

                <Radio.Group
                  className="selection-radio-group"
                  onChange={(e) => handleChange(category.value, e.target.value)}
                  value={selectedValues[category.value]}
                >
                  {levels.map((option) => (
                    <Radio
                      key={option}
                      value={option}
                      className={`selection-radio ${
                        selectedValues[category.value] === option
                          ? "selected"
                          : ""
                      }`}
                    >
                      {options[option]}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Needs;
