import cuid from "cuid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, PROCESS_CONFIG_TYPES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.PROCESSES.LIST.path);
  const [addProcess] = useMutation(graphql.mutations.CREATE_PROCESS, {
    refetchQueries: [
      {
        query: graphql.queries.PROCESSES,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.PROCESSES.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = (_) => {
    const {
      name,
      type,
      processStatuses,
      insurancePlans,
      skills,
      attributionType,
    } = _;
    return addProcess({
      variables: {
        data: {
          id,
          name,
          type,
          processStatuses,
          skills,
          config: attributionType
            ? [
                {
                  id: cuid(),
                  type: PROCESS_CONFIG_TYPES.ATTRIBUTION_TYPE,
                  value: attributionType,
                },
              ]
            : [],
          // insurancePlans: insurancePlans?.map((id) => ({ id })),
        },
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default Create;
