import classNames from "classnames";
import { get } from "lodash";
import { MdCheck } from "react-icons/md";
import Button from "../../Components/Button";
import Cost from "../../Components/Cost";

const ProductCard = ({
  icon,
  name,
  description,
  price,
  action,
  info,
  link,
  recommendation,
}) => {
  return (
    <div className="product__item">
      <div
        className={classNames({
          "product__item--recommended": recommendation?.value,
        })}
      >
        <div className="product__item--wrapper">
          <div className="product__item--header">
            <div className="product__item--header--top">
              <img className="product__item--icon" alt={name} src={icon} />
              {recommendation?.value && (
                <h3 className="product__item--recommended--header">
                  {recommendation?.label}
                </h3>
              )}
            </div>
            <Cost
              cost={get(price, "cost")}
              currency={get(price, "currency")}
              showYearly
            />
            <p className="product__item--description">{description}</p>
          </div>
          <div className="product__item--action">
            <Button
              type={recommendation?.value ? "primary" : "primary-light"}
              onClick={action?.onClick}
              loading={action?.loading}
              disabled={action?.disabled}
            >
              {action?.label}
            </Button>
          </div>
          <div className="product__item--info">
            <span className="product__item--info--title">{info?.title}</span>

            <div className="product__item--info--list">
              {get(info, "items", []).map(
                ({ coverage, limit, complement }, i) => (
                  <div key={i} className="item">
                    <MdCheck />
                    <span>{`${coverage} ${limit}`}</span>
                    {complement && <sup>{complement}</sup>}
                  </div>
                )
              )}
            </div>
          </div>
          <div className="product__item--link" onClick={link?.onClick}>
            {link?.label}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
