import { Radio } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";
import checkMarkChecked from "../svg/check-mark-checked.svg";
import checkMarkHelpChecked from "../svg/check-mark-help-checked.svg";
import checkMarkHelpUnchecked from "../svg/check-mark-help-unchecked.svg";
import checkMarkUnchecked from "../svg/check-mark-unchecked.svg";

const List = ({
  errors,
  touched,
  label,
  icon,
  onChange,
  values,
  value,
  required,
  subLabel,
  style,
  ...rest
}) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const onSelect = ({ target: { value } }) => {
    setCheckedValue(value);
    onChange(value);
  };

  return (
    <div className="b2c-radio b2c-lmf-radio--list">
      <Radio.Group
        onChange={onSelect}
        value={checkedValue}
        defaultValue={value}
        optionType="button"
        {...rest}
      >
        {values.map(
          ({ label, value: _value, subLabel, onHelpClick }) =>
            !(_value === checkedValue && _value === "CUSTOM") && (
              <Radio
                value={_value}
                className={classNames(
                  "custom--item",
                  { checked: checkedValue },
                  { text: !subLabel }
                )}
                key={`radio-${_value}`}
              >
                <div className="checkbox-container">
                  {subLabel && (
                    <div className="check-mark">
                      <img
                        src={
                          _value === checkedValue
                            ? checkMarkChecked
                            : checkMarkUnchecked
                        }
                      />
                    </div>
                  )}
                  <div>
                    <p className="radio-title">{label}</p>
                    <p className="sub-label">{subLabel}</p>
                  </div>
                  {subLabel && (
                    <div
                      className="check-mark-help"
                      onClick={() => onHelpClick(_value)}
                    >
                      <img
                        src={
                          _value === checkedValue
                            ? checkMarkHelpChecked
                            : checkMarkHelpUnchecked
                        }
                      />
                    </div>
                  )}
                </div>
              </Radio>
            )
        )}
      </Radio.Group>
      {touched && errors && <p className={"input--errors"}>{errors}</p>}
    </div>
  );
};

List.propTypes = {
  loading: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  placeholder: PropTypes.node,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.any,
    })
  ),
  value: PropTypes.any,
};

List.defaultProps = {
  onChange: () => {},
  values: [],
};
export default List;
