import cuid from "cuid";
import { defaults, get, keyBy, map } from "lodash";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import View from "./View";

const AdherentDetails = ({
  project,
  tracer,
  currentStatus,
  onNext,
  onBack,
}) => {
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");

  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
  });

  const next = ({ contact, contract }) => {
    const mappedTelecoms = map(get(contact, "telecoms"), (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    onNext({
      payload: {
        contact: {
          ...contact,
          telecoms: mappedTelecoms,
        },
        contract,
      },
    });
  };

  const initialValues = {
    contact: {
      ...get(project, "contact", {}),
      telecoms,
      fields: {
        ...get(project, "contact.fields", {}),
        acceptDigital: get(project, "contact.fields.acceptDigital", false),
        acceptContact: get(project, "contact.fields.acceptContact", false),
      },
    },
    contract: get(project, "contract"),
  };

  return (
    <View
      onNext={next}
      initialValues={initialValues}
      fields={get(project, "fields", {})}
      onBack={onBack}
      tracer={tracer}
      locked={project.locked}
      progress={currentStatus.progress}
      subscriptionId={subscriptionId}
      project={project}
      disabled={project?.locked}
    />
  );
};

export default AdherentDetails;
