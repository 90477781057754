import { Modal } from "antd";
import { find, get } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM, DATE_FORMAT } from "utils/constants";
import Button from "../Button";

const Profile = ({ contact, open, setOpen, onEdit }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.PROFILE_CART", { returnObjects: true });

  const getTelecom = (system) =>
    get(find(get(contact, "telecoms", []), { system }), "value", "");

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      width={400}
      footer={null}
      className="LMF-formula-modal"
    >
      <span className="LMF-formula-modal__title">{TRANSLATION.TITLE}</span>
      <div className="LMF-formula-modal__body">
        <div className="LMF-formula-modal__body--adherent">
          <span className="LMF-formula-modal__body--adherent__title">
            <span>{TRANSLATION.ADHERENT.TITLE}</span>
            <span onClick={() => onEdit()}>{TRANSLATION.ADHERENT.EDIT}</span>
          </span>
          <ul>
            <li>
              <span>{TRANSLATION.ADHERENT.NAME}</span>
              <span className="capitalize">
                {get(contact, "user.firstname")} {get(contact, "user.lastname")}
              </span>
            </li>
            <li>
              <span>{TRANSLATION.ADHERENT.BIRTH_DATE}</span>
              <span>
                {moment(get(contact, "user.birthDate")).format(DATE_FORMAT)}
              </span>
            </li>
            <li>
              <span>{TRANSLATION.ADHERENT.EMAIL}</span>
              <span>{getTelecom(CONTACT_POINT_SYSTEM.EMAIL)}</span>
            </li>
            <li>
              <span>{TRANSLATION.ADHERENT.PHONE}</span>
              <span>{getTelecom(CONTACT_POINT_SYSTEM.PHONE)}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="LMF-formula-modal__actions">
        <Button
          onClick={() => setOpen(false)}
          className="w-full"
          type="white"
          size="middle"
        >
          <span>{TRANSLATION.CLOSE}</span>
        </Button>
      </div>
    </Modal>
  );
};
export default Profile;
