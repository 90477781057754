import Lottie from "lottie-react";
import { useWindowSize } from "shared/hooks/resize";
import animationData from "../Assets/Lotties/spin.json";

const Loader = () => {
  const [, height] = useWindowSize();
  return (
    <div className="solyon-loader" style={{ height }}>
      <Lottie animationData={animationData} loop={true} />
    </div>
  );
};

Loader.propTypes = {};

Loader.defaultProps = {};

export default Loader;
