import Button from "../Button";
import Mail from "../svg/courrier.svg";

const EmailSent = ({ setOpen, TRANSLATION }) => {
  return (
    <div className="modal-done">
      <img src={Mail} />
      <span
        className="LMF-help-modal__title"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.SENT }}
      ></span>
      <Button
        onClick={() => setOpen(false)}
        type="white"
        size="middle"
        className="w-full"
      >
        <span>{TRANSLATION.CLOSE}</span>
      </Button> 
    </div>
  );
};

export default EmailSent;
