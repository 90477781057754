import { notification } from "antd";
import cuid from "cuid";
import GeneralCosts from "entities/GeneralCosts";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { GENERATE_QUOTE_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { CREATE_SUBSCRIPTIONS } from "utils/api/graphql/mutations/subscription";
import {
  CONTACT_POINT_SYSTEM,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
} from "utils/constants";
import { removeDuplicates } from "utils/helpers/array";
import View from "./View";

const Quote = ({ project, onNext, onBack }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [addSubscriptions] = useMutation(CREATE_SUBSCRIPTIONS);

  const [generateQuoteDocuments] = useMutation(GENERATE_QUOTE_DOCUMENTS);
  const [attachments, setAttachments] = useState([
    {
      name: DETAIL.TEMPLATES.QUOTE.ANSET_DOCUMENTS.QUOTE_FILE,
      contentType: "application/pdf",
    },
    {
      name: DETAIL.TEMPLATES.QUOTE.ANSET_DOCUMENTS.DUTY_FILE,
      contentType: "application/pdf",
    },
  ]);

  const { loading } = useQuery(graphql.queries.ATTACHMENTS, {
    variables: {
      isIn: { type: Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES) },
      where: {
        OR: get(project, "fields.insurancePlans", [])?.map((id) => ({
          AND: [{ insurancePlans: { id } }, { project: null }],
        })),
      },
    },
    onCompleted: (data) => {
      const uniqAttachments = removeDuplicates(
        get(data, "attachments.data", [])
      );
      setAttachments((prev) => [...prev, ...uniqAttachments]);
    },
  });

  const [sendQuoteMutation] = useMutation(
    graphql.mutations.SEND_TERMINAL_QUOTE,
    {
      onCompleted: (_) => {
        notification.open({
          message: DETAIL.TEMPLATES.QUOTE.NOTIFICATION.SUCCESS,
          duration: 5,
          type: "success",
        });
        onNext({
          payload: {
            locked: true,
          },
        });
      },
    }
  );
  const [updateContact] = useMutation(graphql.mutations.UPDATE_CONTACT, {
    onCompleted: async () => {
      const selectedData = get(project, "fields.insurancePlans", []);
      const insurancePlans = selectedData.map((id) => ({ id }));
      try {
        const { errors } = await generateQuoteDocuments({
          variables: {
            data: {
              project: { id: project.id },
              insurancePlans,
            },
          },
        });

        if (errors) {
          throw new Error(`GraphQL Error: ${JSON.stringify(errors)}`);
        }

        let links = {};

        await addSubscriptions({
          variables: {
            data: selectedData.map((insurancePlanId) => ({
              id: cuid(),
              project: { id: project.id },
              insurancePlan: {
                id: insurancePlanId,
              },
            })),
          },
        });

        await sendQuoteMutation({
          variables: {
            data: {
              links,
              project: { id: project.id },
              insurancePlans,
            },
          },
        });
      } catch (error) {
        console.error("Error in updateContact mutation:", error);
      }
    },
  });

  const sendQuote = (telecoms) => {
    updateContact({
      variables: {
        where: {
          id: project.contact.id,
        },
        data: {
          telecoms: [
            ...project.contact.telecoms.filter(
              (telecom) => telecom.system !== CONTACT_POINT_SYSTEM.EMAIL
            ),
            ...telecoms.map(({ label, value }) => ({
              id: value,
              system: CONTACT_POINT_SYSTEM.EMAIL,
              value: label,
            })),
          ],
        },
      },
    });
  };

  const generalCostsFilter = {
    where: {
      project: {
        id: project.id,
      },
    },
    isIn: {
      insurancePlan: {
        id: get(project, "fields.insurancePlans", []),
      },
    },
  };

  return (
    <GeneralCosts
      filter={generalCostsFilter}
      loading={loading}
      updateContact={updateContact}
      attachments={attachments}
      setAttachments={setAttachments}
      project={project}
      onNext={sendQuote}
      onBack={onBack}
      View={View}
      NoData={View}
    />
  );
};

export default Quote;
