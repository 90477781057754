import { TEMPLATE_TYPES, TEMPLATES } from "utils/constants";
import AdherentBirthDate from "./AdherenBirthdate";
import AdherentInfo from "./AdherentInfo";
import Loading from "./Components/Loader";
import Default from "./Default";
import EffectiveDate from "./EffectiveDate";
import Income from "./Income";
import Needs from "./Needs";
import Products from "./Products";
import Profile from "./Profile";
import ProtectIncome from "./ProtectIncome";
import Upsells from "./Upsells";

const Templates = {
  Loading: <Loading />,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].PROFILE]: Profile,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_BIRTHDATE]:
    AdherentBirthDate,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].INCOME]: Income,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_INFO]: AdherentInfo,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].NEEDS]: Needs,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].PRODUCTS]: Products,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].UPSELLS]: Upsells,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].PROTECT_INCOME]:
    ProtectIncome,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].EFFECTIVE_DATE]:
    EffectiveDate,
  default: Default,
};
export default Templates;
