import { Col, Row } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import Input from "shared/components/Input";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Button";
import validation from "./validation";

const View = ({
  setOpen,
  onSubmit,
  initialValues,
  errorMessages,
  TRANSLATION,
}) => {
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validationSchema: validation(errorMessages),
  });

  return (
    <>
      <span
        className="LMF-help-modal__title"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.TITLE }}
      ></span>
      <div className="LMF-content">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.LAST_NAME.LABEL}
              placeholder={TRANSLATION.FORM.LAST_NAME.PLACEHOLDER}
              {...bindInputProps({
                name: `contact.user.lastname`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={async ({ target: { value } }) => {
                setFieldValue(`contact.user.lastname`, value);
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.FIRST_NAME.LABEL}
              placeholder={TRANSLATION.FORM.FIRST_NAME.PLACEHOLDER}
              {...bindInputProps({
                name: `contact.user.firstname`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={async ({ target: { value } }) => {
                setFieldValue(`contact.user.firstname`, value);
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.PHONE.LABEL}
              placeholder={TRANSLATION.FORM.PHONE.PLACEHOLDER}
              inputMode="numeric"
              {...bindInputProps({
                name: `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={async ({ target: { value } }) => {
                setFieldValue(
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                  value
                );
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.EMAIL.LABEL}
              placeholder={TRANSLATION.FORM.EMAIL.PLACEHOLDER}
              inputMode="numeric"
              {...bindInputProps({
                name: `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={async ({ target: { value } }) => {
                setFieldValue(
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                  value
                );
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              type="checkbox"
              label={TRANSLATION.FORM.OPT_INS.CONTACT}
              {...bindInputProps({
                name: "contact.fields.acceptContact",
                values,
                ...formProps,
              })}
              onChange={({ target: { checked } }) => {
                setFieldValue("contact.fields.acceptContact", checked);
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              type="checkbox"
              label={TRANSLATION.FORM.OPT_INS.MAIL_ME}
              {...bindInputProps({
                name: "contact.fields.acceptDigital",
                values,
                ...formProps,
              })}
              onChange={({ target: { checked } }) => {
                setFieldValue("contact.fields.acceptDigital", checked);
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-actions">
        <Button
          onClick={() => setOpen(false)}
          className="w-40"
          type="white"
          size="middle"
        >
          <span>{TRANSLATION.CLOSE}</span>
        </Button>
        <Button
          disabled={!isEmpty(formProps.errors)}
          onClick={handleSubmit}
          type={!isEmpty(formProps.errors) ? "white" : "primary"}
          size="middle"
          className="w-full"
        >
          <span>{TRANSLATION.CONFIRM}</span>
        </Button>
      </div>
    </>
  );
};

export default View;
