import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import Button from "../Components/Button";
import Cost from "../Components/Cost";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import DetailsModal from "./DetailsModal";
import UpsellsCard from "./UpsellsCard";

function View({
  onNext,
  onBack,
  upsells,
  selectedModules,
  handleModule,
  projectId,
  insurancePlanId,
}) {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const [openDetails, setOpenDetails] = useState(null);

  const { totalCost, currency } = useMemo(
    () => ({
      totalCost:
        get(selectedModules, insurancePlanId)
          ?.reduce((acc, curr) => acc + curr.cost, 0)
          ?.toFixed(2) || 0,
      currency: get(upsells, `0.currency`),
    }),
    [get(selectedModules, insurancePlanId, [])?.length]
  );

  const DETAIL = t("DETAIL", { returnObjects: true });
  return (
    <div className="solyon">
      <div className="solyon__container">
        <Header
          title="Renforcez votre couverture avec nos modules complémentaires"
          highlight="modules complémentaires"
          subtitle="Ajoutez jusqu'à 2 modules pour renforcer votre couverture"
          onBack={onBack}
        />
        <div className="solyon__body">
          <div className="solyon-scroll-container">
            <div className="solyon-upsells">
              {upsells.map((module) => (
                <div className="solyon-upsells__item" key={module?.id}>
                  <UpsellsCard
                    notSelected={
                      !get(selectedModules, insurancePlanId)?.find(
                        ({ insurancePlan }) =>
                          insurancePlan.id === get(module, "insurancePlan.id")
                      )
                    }
                    icon={get(module, "insurancePlan.icon") || ""}
                    name={get(module, "insurancePlan.name") || ""}
                    cost={get(module, "cost")}
                    currency={get(module, "currency")}
                    onAdd={() => handleModule({ module })}
                  />
                  <div
                    onClick={() =>
                      setOpenDetails(get(module, "insurancePlan.id"))
                    }
                    className={classNames("solyon-upsells__item--link", {
                      "solyon-upsells__item--link--gray": !get(
                        selectedModules,
                        insurancePlanId
                      )?.find(
                        ({ insurancePlan }) =>
                          insurancePlan.id === get(module, "insurancePlan.id")
                      ),
                    })}
                  >
                    Découvrir les forfaits
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={"solyon-upsells-recap"}>
            <div className={"solyon-upsells-recap__card"}>
              <div
                className={classNames("solyon-upsells-recap__container", {
                  "solyon-upsells-recap--gray": isEmpty(
                    get(selectedModules, insurancePlanId)
                  ),
                })}
              >
                <div className="solyon-upsells-recap__text">
                  Supplément à payer par mois
                </div>
                <Cost cost={totalCost} currency={currency} />
                <Button type={"primary"} alignment={"center"} onClick={onNext}>
                  Continuer
                </Button>
              </div>
            </div>
            <div
              onClick={() => onNext({ ignore: true })}
              className={classNames("solyon-upsells__item--link", {
                "solyon-upsells__item--link--hidden": !isEmpty(
                  get(selectedModules, insurancePlanId)
                ),
              })}
            >
              Ignorer cette étape
            </div>
          </div>
          {openDetails && (
            <DetailsModal
              open={openDetails}
              onClose={() => setOpenDetails(null)}
              filter={{
                where: {
                  insurancePlan: { id: openDetails },
                  project: { id: projectId },
                },
              }}
              translation={DETAIL.TEMPLATES.COMPARISON}
            />
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default View;
