import classNames from "classnames";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "shared/components/Spin";
import { NAME_SPACES } from "shared/locales/constants";
import { CURRENCY_SYMBOL } from "utils/constants";
import Button from "../Components/Button";
import Cart from "../Components/Cart";
import ContactUs from "../Components/ContactUs";
import Header from "../Components/Header";
import Back from "../Components/svg/back.svg";
import checkMarkChecked from "../Components/svg/check-mark-checked.svg";
import checkMarkUnchecked from "../Components/svg/check-mark-unchecked.svg";
import facialGreenWater from "../Components/svg/facile-vert_eau.svg";

const View = ({
  mainProduct,
  products,
  selectedModules,
  disabled,
  onCheck,
  generalCost,
  progress,
  subscriptionId,
  tracer,
  project,
  onNext,
  onBack,
  loading,
}) => {
  const [help, setHelp] = useState(true);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.REINFORCEMENT", { returnObjects: true });
  const totalCost = get(
    selectedModules,
    get(generalCost, "insurancePlan.id"),
    []
  )
    ?.reduce((acc, curr) => acc + curr.cost, 0)
    ?.toFixed(2);
  const [integer, decimal = ""] = totalCost?.toString()?.split(".") || ["-"];

  return (
    <>
      <Header
        progressValue={progress}
        View={Cart({
          subscriptionId,
          tracer,
          project,
          modules: get(selectedModules, mainProduct),
          contact: get(project, "contact"),
        })}
      />
      <div className="container __LMF">
        <Button className="back" type="default" onClick={onBack}>
          <img src={Back} />
        </Button>
        <h1 className="header-title">{TRANSLATION.TITLE}</h1>
        <span
          className="header-description"
          dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
        ></span>
        <div className="LMF-content modules-container">
          <div className="modules-container__header">
            <img src={facialGreenWater} />
            <span>{TRANSLATION.HEADER.TEXT}</span>
          </div>
          <div className="modules-container__body">
            {loading ? (
              <Loading />
            ) : (
              products?.map((product, i) => (
                <div
                  onClick={() => onCheck(product)}
                  className={classNames("modules-container__body--checkbox", {
                    checked:
                      get(selectedModules, mainProduct, []).find(
                        ({ insurancePlan }) =>
                          insurancePlan?.id ===
                          get(product, "insurancePlan.id", "")
                      ) || false,
                    disabled: disabled,
                  })}
                  key={`product-${i}`}
                >
                  <img className="checked-icon" src={checkMarkChecked} />
                  <img className="unchecked-icon" src={checkMarkUnchecked} />
                  <div className="product-description">
                    <label>{get(product, "insurancePlan.name", "")}</label>
                    <span>{get(product, "insurancePlan.description", "")}</span>
                  </div>
                </div>
              ))
            )}
          </div>

          <div className="modules-container__footer">
            {TRANSLATION.FOOTER.TEXT}
          </div>
        </div>
        <div
          className={classNames("add-upsell", {
            blur: !get(selectedModules, mainProduct, []).length,
          })}
        >
          <span className="add-upsell__price">
            <span className="add-upsell__price--integer">{integer}</span>
            <span className="add-upsell__price--decimal">
              <span>
                ,{decimal} {CURRENCY_SYMBOL[generalCost?.currency]}
              </span>
              <span>{TRANSLATION.PER_MONTH}</span>
            </span>
          </span>
          <Button
            type={
              get(selectedModules, mainProduct, []).length ? "primary" : "white"
            }
            size="large"
            onClick={onNext}
            disabled={!get(selectedModules, mainProduct, []).length}
          >
            {TRANSLATION.NEXT}
          </Button>
        </div>
        <p className="__LMF--link" onClick={() => onNext({ ignore: true })}>
          {TRANSLATION.NO_THANKS}
        </p>
        <ContactUs
          phone={"+212666952697"}
          text={TRANSLATION.NEED_HELP}
          help={help}
          setHelp={setHelp}
        />
      </div>
    </>
  );
};

export default View;
