import { Input } from "antd";
import PropTypes from "prop-types";

import classnames from "classnames";
import Label from "../Label";

const Email = ({
  label,
  placeholder,
  errors,
  touched,
  icon,
  innerRef,
  required,
  direction,
  ...rest
}) => {
  return (
    <div
      className={classnames(
        "input__wrapper",
        { __error: touched && errors },
        direction
      )}
    >
      <Label label={label} icon={icon} required={required} />
      <div className={`input__wrapper--field`}>
        <Input
          type="email"
          className={classnames({
            "input--borderd--red": touched && errors,
            "disable--input": rest.disabled,
          })}
          placeholder={placeholder}
          ref={innerRef}
          {...rest}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

Email.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

Email.defaultProps = {
  label: "",
  placeholder: "",
};
export default Email;
