import { useLazyQuery, useQuery } from "@apollo/client";
import { get } from "lodash";
import { useState } from "react";
import { OFFERS } from "utils/api/graphql/queries/offers";
import useInsurancePlans from "../../../Manual/Solyon/Comparison/Widgets/Upsells/hooks/useInsurancePlans";
import Loader from "../Components/Loader";
import View from "./View";

const Upsells = ({ project, onNext, onBack }) => {
  const [selectedModules, setSelectedModules] = useState(
    get(project, "fields.upsells", [])
  );

  const [getOffers, { loading: offersLoading }] = useLazyQuery(OFFERS);
  const { selectedInsurancePlans, fetchMainOffers, loading } =
    useInsurancePlans({
      insurancePlan: { id: get(project, "fields.selectedToCompare.0") },
      getOffers,
      project,
      setSelectedModules,
      offerWhere: [{ offerInsurancePlans: { weight: 1 } }],
    });
  useQuery(OFFERS, {
    variables: {
      where: {
        AND: [
          {
            offerInsurancePlans: {
              insurancePlan: { id: get(project, "fields.selectedToCompare.0") },
            },
          },
          { offerInsurancePlans: { main: true } },
        ],
      },
    },
    onCompleted: (offers) => {
      fetchMainOffers(offers);
    },
  });

  const handleModule = ({ module }) => {
    const id = get(module, "insurancePlan.id");
    setSelectedModules((prev) => {
      const exist = get(prev, get(project, "fields.selectedToCompare.0"))?.find(
        ({ insurancePlan }) => insurancePlan.id === id
      );
      if (
        get(prev, get(project, "fields.selectedToCompare.0"))?.length === 2 &&
        !exist
      )
        return prev;
      return exist
        ? {
            [get(project, "fields.selectedToCompare.0")]: get(
              prev,
              get(project, "fields.selectedToCompare.0"),
              []
            ).filter(({ insurancePlan }) => insurancePlan.id !== id),
          }
        : {
            [get(project, "fields.selectedToCompare.0")]: [
              ...get(prev, get(project, "fields.selectedToCompare.0"), []),
              module,
            ],
          };
    });
  };

  const onSubmit = ({ ignore = false }) => {
    onNext({
      payload: {
        fields: {
          ...project.fields,
          upsells: ignore ? undefined : selectedModules,
        },
      },
    });
  };

  if (offersLoading || loading) return <Loader />;

  return (
    <View
      contact={get(project, "contact", {})}
      onNext={onSubmit}
      onBack={onBack}
      upsells={selectedInsurancePlans}
      selectedModules={selectedModules}
      projectId={project?.id}
      handleModule={handleModule}
      insurancePlanId={get(project, "fields.selectedToCompare.0")}
    />
  );
};

export default Upsells;
