import {
  CONTACT_POINT_SYSTEM,
  PAYMENT_TYPES,
  REGEX_EXPS,
  SUBSCRIBER_TYPES,
} from "utils/constants";
import * as yup from "yup";

const validation = (messages, { paymentType, subscriberType }) => {
  const string = yup.string().typeError(messages.NOT_STRING).nullable();
  const requiredString = yup
    .string()
    .typeError(messages.NOT_STRING)
    .required(messages.REQUIRED);
  return yup.object().shape({
    project: yup.object().shape({
      contact: yup.object().shape({
        user: yup.object().shape({
          gender: requiredString,
          firstname: string,
          lastname: string,
          birthDate: yup
            .string()
            .typeError(messages.REQUIRED)
            .required(messages.REQUIRED),
        }),
      }),
      fields: yup.object().shape({
        serialNumber: requiredString,
        imei: yup
          .string()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED)
          .nullable()
          .test("isValid", messages.INVALID_IMEI_VALUE, function (value) {
            return value
              ? new RegExp(REGEX_EXPS.imei).test(value.replace(/\s+/g, ""))
              : true;
          }),
        purchaseDate: yup
          .string()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED),
      }),
    }),
    telecoms: yup.object().shape({
      contact: yup.object().shape({
        [CONTACT_POINT_SYSTEM.MAILBOX]: yup.object().shape({
          value: yup
            .string()
            .nullable()
            .matches(/^(bp)/i, messages.INVALID_MAILBOX_VALUE),
        }),
        [CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.BIRTH_ZIP_CODE]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.BIRTH_COUNTRY]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.ADDRESS]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.COMPLEMENT]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.EMAIL]: yup.object().shape({
          value: yup
            .string()
            .required(messages.REQUIRED)
            .nullable()
            .email(messages.EMAIL),
        }),
        [CONTACT_POINT_SYSTEM.PHONE]: yup.object().shape({
          value: yup
            .string()
            .required(messages.REQUIRED)
            .typeError(messages.INVALID_POLYNISIE)
            .nullable()
            .test(
              "isValid",
              messages.INVALID_POLYNISIE,
              async function (value) {
                return !value
                  ? true
                  : new RegExp(REGEX_EXPS.phonePolynisie).test(
                      value?.replace(/\s+/g, "")
                    );
              }
            ),
        }),
      }),
    }),
    payment: yup.object().shape({
      type: string,
      ...(paymentType === PAYMENT_TYPES.DEBIT && {
        frequency: string,
        debitDay: requiredString,
        payer: yup.object().shape({
          BIC: requiredString,
          IBAN: yup
            .string()
            .typeError(messages.NOT_STRING)
            .required(messages.REQUIRED)
            .max(27, messages.MAX_LENGTH)
            .matches(
              /^(FR76)(12149|12239|14168|17469)\d{5}[A-Z0-9]{11}\d{2}$/i,
              messages.NOT_SUPPORTED_IBAN
            )
            .nullable(),
          owner: yup.object().shape({
            isAdherent: yup.boolean().typeError(messages.NOT_BOOLEAN),
            firstName: string,
            lastName: string,
          }),
        }),
        receiver: yup.object().shape({
          BIC: string,
          IBAN: yup
            .string()
            .typeError(messages.NOT_STRING)
            .test("isValid", messages.INVALID_IBAN, async function (value) {
              return value
                ? new RegExp(REGEX_EXPS.franceIban).test(
                    value.replace(/\s+/g, "")
                  )
                : true;
            })
            .nullable(),
          owner: yup.object().shape({
            firstName: string,
            lastName: string,
          }),
        }),
      }),
    }),
    additionalInfo: yup.object().shape({
      terminalUser: yup.object().shape({
        subscriberType: requiredString,
        ...(subscriberType === SUBSCRIBER_TYPES.OTHER && {
          firstName: requiredString,
          lastName: requiredString,
        }),
      }),
    }),
  });
};

export default validation;
