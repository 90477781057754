import cuid from "cuid";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAddRoad,
  MdOutlineBadge,
  MdOutlineEditCalendar,
  MdOutlinePayment,
  MdOutlinePerson,
  MdOutlinePinDrop,
  MdOutlineQrCode,
  MdOutlineReplay,
  MdOutlineSouthAmerica,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  ANSET_SUBSCRIPTION_FORM_KEYS,
  BANK_ACCOUNT_OWNER_TYPE,
  CONTACT_POINT_SYSTEM,
  DEBIT_POSSIBLE_DATES,
  PAYMENT_FREQUENCIES,
  PAYMENT_TYPES,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";
import { setDefaultIfEmpty } from "utils/helpers/input";

const Payment = ({
  formProps,
  values,
  setDisabled,
  setPercent,
  setFieldValue,
  isFieldRequired,
  errors,
  initialValues,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("ANSET_SUBSCRIPTION", { returnObjects: true });

  const PAYMENT_TYPE = {
    [PAYMENT_TYPES.DEBIT]: () => {
      setDisabled((prev) => ({
        ...prev,
        payment: {
          ...prev.payment,
          frequency: false,
          debitDay: false,
          payer: {
            ...prev.payment?.payer,
            IBAN: false,
            BIC: false,
            owner: {
              ...prev.payment?.payer?.owner,
              firstName: false,
              lastName: false,
            },
          },
        },
        [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.BANK_ACCOUNT]: false,
      }));
    },
    [PAYMENT_TYPES.DELEGATE]: () => {
      setDisabled((prev) => ({
        ...prev,
        payment: {
          ...prev.payment,
          frequency: true,
          debitDay: true,
          payer: {
            ...prev.payment?.payer,
            IBAN: true,
            BIC: true,
            owner: {
              ...prev.payment?.payer?.owner,
              firstName: true,
              lastName: true,
            },
          },
        },
        [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.BANK_ACCOUNT]: true,
      }));
    },
    [PAYMENT_TYPES.TIP]: () => {},
  };
  const PAYMENT_PAYER = {
    [true]: () => {
      set(initialValues, "payment.payer.owner", {
        id: get(initialValues, "payment.payer.owner.id", cuid()),
        firstName: get(initialValues, "project.contact.user.firstname"),
        lastName: get(initialValues, "project.contact.user.lastname"),
        isAdherent: true,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
      set(
        initialValues,
        "telecoms.payer",
        get(initialValues, "telecoms.contact", [])
      );
      setDisabled((prev) => ({
        ...prev,
        telecoms: {
          ...prev.telecoms,
          payer: {
            firstName: true,
            lastName: true,
            [CONTACT_POINT_SYSTEM.STREET]: {
              value: true,
            },
            [CONTACT_POINT_SYSTEM.ZIP_CODE]: {
              value: true,
            },
            [CONTACT_POINT_SYSTEM.CITY]: {
              value: true,
            },
          },
        },
      }));
    },
    [false]: () => {
      set(initialValues, "payment.payer.owner", {
        id: get(initialValues, "payment.payer.owner.id", cuid()),
        isAdherent: false,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
      set(initialValues, "telecoms.payer", {});
      setDisabled((prev) => ({
        ...prev,
        telecoms: {
          ...prev.telecoms,
          payer: undefined,
        },
      }));
    },
  };

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PAYMENT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.TITLE}
        subtitle={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.SUBTITLE}
      >
        <div className="grid--1">
          <Input
            type="radio"
            variant="group"
            icon={<MdOutlinePayment size={16} />}
            label={SUBSCRIPTION.FORM.PAYMENT_TYPE.LABEL}
            values={setDefaultIfEmpty(
              get(initialValues, "insurancePlan.fields.payment.type"),
              Object.values(PAYMENT_TYPES)
            ).map((value) => ({
              value,
              label: SUBSCRIPTION.FORM.PAYMENT_TYPE.OPTIONS[value],
            }))}
            value={get(initialValues, "payment.type")}
            errors={errors["payment.type"]}
            touched={!isEmpty(errors["payment.type"])}
            onBlur={() => setPercent(initialValues)}
            onChange={(value) => {
              PAYMENT_TYPE[value]();
              set(initialValues, "payment.type", value);
            }}
            required={isFieldRequired("payment.type")}
          />
          {get(initialValues, "payment.type") === PAYMENT_TYPES.DEBIT ? (
            <>
              <Input
                type="radio"
                variant="group"
                icon={<MdOutlineReplay size={16} />}
                label={SUBSCRIPTION.FORM.PAYMENT_FREQUENCY.LABEL}
                values={setDefaultIfEmpty(
                  get(initialValues, "insurancePlan.fields.payment.frequency"),
                  Object.values(PAYMENT_FREQUENCIES)
                ).map((value) => ({
                  value,
                  label: SUBSCRIPTION.FORM.PAYMENT_FREQUENCY.OPTIONS[value],
                }))}
                value={get(initialValues, "payment.frequency")}
                errors={errors["payment.frequency"]}
                touched={!isEmpty(errors["payment.frequency"])}
                onBlur={() => setPercent(initialValues)}
                onChange={(value) =>
                  set(initialValues, "payment.frequency", value)
                }
                required={isFieldRequired("payment.frequency")}
              />
              <Select
                options={setDefaultIfEmpty(
                  get(initialValues, "insurancePlan.fields.payment.debitDay"),
                  DEBIT_POSSIBLE_DATES
                ).map((value) => ({
                  value,
                  label: value,
                }))}
                placeholder={SUBSCRIPTION.FORM.DEBIT_DATE}
                label={SUBSCRIPTION.FORM.DEBIT_DATE}
                icon={<MdOutlineEditCalendar size={16} />}
                defaultValue={get(initialValues, "payment.debitDay")}
                errors={errors["payment.debitDay"]}
                touched={!isEmpty(errors["payment.debitDay"])}
                onChange={(value) => {
                  set(initialValues, "payment.debitDay", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("payment.debitDay")}
              />
              <Input
                label={SUBSCRIPTION.FORM.IBAN.LABEL}
                icon={<MdOutlineQrCode size={16} />}
                placeholder={SUBSCRIPTION.FORM.IBAN.PLACEHOLDER}
                defaultValue={get(initialValues, "payment.payer.IBAN")}
                errors={errors["payment.payer.IBAN"]}
                touched={!isEmpty(errors["payment.payer.IBAN"])}
                onChange={({ target: { value } }) => {
                  set(initialValues, "payment.payer.IBAN", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("payment.payer.IBAN")}
              />
              <Input
                label={SUBSCRIPTION.FORM.BIC.LABEL}
                icon={<MdOutlineQrCode size={16} />}
                placeholder={SUBSCRIPTION.FORM.BIC.PLACEHOLDER}
                defaultValue={get(initialValues, "payment.payer.BIC")}
                errors={errors["payment.payer.BIC"]}
                touched={!isEmpty(errors["payment.payer.BIC"])}
                onChange={({ target: { value } }) => {
                  set(initialValues, "payment.payer.BIC", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("payment.payer.BIC")}
              />
              {get(initialValues, "payment.payer.owner.type") ===
                BANK_ACCOUNT_OWNER_TYPE.PARTICULAR && (
                <Input
                  type="radio"
                  variant="group"
                  icon={<MdOutlinePerson size={16} />}
                  value={get(initialValues, "payment.payer.owner.isAdherent")}
                  label={
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER.LABEL
                  }
                  values={[
                    {
                      value: true,
                      label:
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .OPTIONS.ADHERENT,
                    },
                    {
                      value: false,
                      label:
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .OPTIONS.ANOTHER_PERSON,
                      popover:
                        get(
                          initialValues,
                          "insurancePlan.fields.payment.shouldBeAdherent"
                        ) &&
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT
                          .SHOULD_BE_ADHERENT,
                    },
                  ]}
                  disabled={get(
                    initialValues,
                    "insurancePlan.fields.payment.shouldBeAdherent"
                  )}
                  errors={errors["payment.frequency"]}
                  touched={!isEmpty(errors["payment.frequency"])}
                  onBlur={() => setPercent(initialValues)}
                  onChange={(value) => {
                    set(initialValues, "payment.payer.owner.isAdherent", value);
                    setDisabled((prev) => ({
                      ...prev,
                      proof: {
                        ...prev.proof,
                        payerCin: value,
                      },
                    }));
                    PAYMENT_PAYER[value]();
                  }}
                  required={isFieldRequired("payment.payer.owner.isAdherent")}
                />
              )}
              {(get(initialValues, "insurancePlan.fields.tns") ||
                !get(initialValues, "payment.payer.owner.isAdherent")) && (
                <>
                  {get(initialValues, "insurancePlan.fields.tns") ? (
                    <Form
                      type="highlight"
                      title={
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .ORGANIZATION.TITLE
                      }
                    >
                      <div className="grid--1">
                        <Input
                          label={
                            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT
                              .PAYER.ORGANIZATION.SOCIAL_REASON
                          }
                          icon={<MdOutlineBadge size={16} />}
                          optional={SUBSCRIPTION.FORM.OPTIONAL}
                          placeholder={
                            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT
                              .PAYER.ORGANIZATION.SOCIAL_REASON
                          }
                          defaultValue={get(
                            initialValues,
                            "payment.payer.owner.socialReason"
                          )}
                          errors={errors["payment.payer.owner.socialReason"]}
                          touched={
                            !isEmpty(errors["payment.payer.owner.socialReason"])
                          }
                          onChange={({ target: { value } }) => {
                            set(
                              initialValues,
                              "payment.payer.owner.socialReason",
                              value
                            );
                          }}
                          onBlur={() => setPercent(initialValues)}
                          required={isFieldRequired(
                            "payment.payer.owner.socialReason"
                          )}
                        />
                      </div>
                    </Form>
                  ) : (
                    <Form
                      type="highlight"
                      title={
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .IDENTITY.TITLE
                      }
                    >
                      <div className="grid--1">
                        <Input
                          label={
                            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT
                              .PAYER.IDENTITY.FIRST_NAME.LABEL
                          }
                          icon={<MdOutlineBadge size={16} />}
                          placeholder={
                            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT
                              .PAYER.IDENTITY.FIRST_NAME.PLACEHOLDER
                          }
                          defaultValue={get(
                            initialValues,
                            "payment.payer.owner.firstName"
                          )}
                          errors={errors["payment.payer.owner.firstName"]}
                          touched={
                            !isEmpty(errors["payment.payer.owner.firstName"])
                          }
                          onChange={({ target: { value } }) => {
                            set(
                              initialValues,
                              "payment.payer.owner.firstName",
                              value
                            );
                          }}
                          onBlur={() => setPercent(initialValues)}
                          required={isFieldRequired(
                            "payment.payer.owner.firstName"
                          )}
                        />
                        <Input
                          label={
                            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT
                              .PAYER.IDENTITY.LAST_NAME.LABEL
                          }
                          icon={<MdOutlineBadge size={16} />}
                          placeholder={
                            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT
                              .PAYER.IDENTITY.LAST_NAME.PLACEHOLDER
                          }
                          defaultValue={get(
                            initialValues,
                            "payment.payer.owner.lastName"
                          )}
                          errors={errors["payment.payer.owner.lastName"]}
                          touched={
                            !isEmpty(errors["payment.payer.owner.lastName"])
                          }
                          onChange={({ target: { value } }) => {
                            set(
                              initialValues,
                              "payment.payer.owner.lastName",
                              value
                            );
                          }}
                          onBlur={() => setPercent(initialValues)}
                          required={isFieldRequired(
                            "payment.payer.owner.lastName"
                          )}
                        />
                      </div>
                    </Form>
                  )}
                  <Form
                    type="highlight"
                    title={`${SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER.ADDRESS.TITLE} (${SUBSCRIPTION.FORM.OPTIONAL})`}
                  >
                    <div className="grid--1">
                      <Input
                        label={SUBSCRIPTION.FORM.STREET.LABEL}
                        optional={SUBSCRIPTION.FORM.OPTIONAL}
                        icon={<MdOutlineAddRoad size={16} />}
                        placeholder={SUBSCRIPTION.FORM.STREET.PLACEHOLDER}
                        defaultValue={get(
                          initialValues,
                          `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET}.value`
                        )}
                        errors={
                          errors[
                            `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET}.value`
                          ]
                        }
                        touched={
                          !isEmpty(
                            errors[
                              `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET}.value`
                            ]
                          )
                        }
                        onChange={({ target: { value } }) => {
                          set(
                            initialValues,
                            `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET}.value`,
                            value
                          );
                        }}
                        onBlur={() => setPercent(initialValues)}
                        required={isFieldRequired(
                          `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET}.value`
                        )}
                      />
                      <Input
                        label={SUBSCRIPTION.FORM.POSTAL_CODE.LABEL}
                        icon={<MdOutlinePinDrop size={16} />}
                        optional={SUBSCRIPTION.FORM.OPTIONAL}
                        placeholder={SUBSCRIPTION.FORM.POSTAL_CODE.PLACEHOLDER}
                        defaultValue={get(
                          initialValues,
                          `telecoms.payer.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                        )}
                        errors={
                          errors[
                            `telecoms.payer.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                          ]
                        }
                        touched={
                          !isEmpty(
                            errors[
                              `telecoms.payer.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                            ]
                          )
                        }
                        onChange={({ target: { value } }) => {
                          set(
                            initialValues,
                            `telecoms.payer.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                            value
                          );
                        }}
                        onBlur={() => setPercent(initialValues)}
                        required={isFieldRequired(
                          `telecoms.payer.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                        )}
                      />
                      <Input
                        icon={<MdOutlineSouthAmerica size={16} />}
                        label={SUBSCRIPTION.FORM.CITY.LABEL}
                        placeholder={SUBSCRIPTION.FORM.CITY.PLACEHOLDER}
                        optional={SUBSCRIPTION.FORM.OPTIONAL}
                        defaultValue={get(
                          initialValues,
                          `telecoms.payer.${CONTACT_POINT_SYSTEM.CITY}.value`
                        )}
                        errors={
                          errors[
                            `telecoms.payer.${CONTACT_POINT_SYSTEM.CITY}.value`
                          ]
                        }
                        touched={
                          !isEmpty(
                            errors[
                              `telecoms.payer.${CONTACT_POINT_SYSTEM.CITY}.value`
                            ]
                          )
                        }
                        onChange={({ target: { value } }) => {
                          set(
                            initialValues,
                            `telecoms.payer.${CONTACT_POINT_SYSTEM.CITY}.value`,
                            value
                          );
                        }}
                        onBlur={() => setPercent(initialValues)}
                        required={isFieldRequired(
                          `telecoms.payer.${CONTACT_POINT_SYSTEM.CITY}.value`
                        )}
                      />
                    </div>
                  </Form>
                </>
              )}
            </>
          ) : (
            <div>
              <Alert
                type="info"
                showIcon
                message={SUBSCRIPTION.ADDITIONAL_INFORMATION.DELEGATE_PAYMENT}
              />
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default Payment;
