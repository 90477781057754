import Projects from "entities/Projects";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPlus } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { MdOutlineLock, MdOutlineLockOpen } from "react-icons/md";
import { TbFileExport } from "react-icons/tb";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Filter from "shared/components/Filter";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";

import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import {
  ACTIONS,
  INITIAL_VALUES,
  PROCESS_TAGS as PROCESS_TAGS_STATUSES,
  USER_TYPES,
} from "utils/constants";
import { setPaginationHistory } from "utils/helpers/pagination";
import ContactCard from "../../Clients/Card";
import OfferDrawer from "./Offers";
import SignProjectDrawer from "./SignProjectDrawer";
import Subscriptions from "./Subscriptions";
import ProjectsTable from "./Table";

const LOCK_ICON = {
  [true]: <MdOutlineLock size={24} color={COLORS.C_GRAY} />,
  [false]: <MdOutlineLockOpen size={24} color={COLORS.C_GRAY} />,
};

const List = ({
  filter,
  onSearch,
  applyFilter,
  pagination,
  setPagination,
  createProject,
  actions,
  exportSubscriptions,
  exportLoading,
  duplicate,
  onDelete,
  onDetail,
  onAssign,
  isAssigning,
  setTracer,
  onChangeStatus,
  tracers,
  tracer,
  user = {},
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const FILTER = t("FILTER", { returnObjects: true });
  const LIST = t("LIST", { returnObjects: true });
  const PROCESS_TAGS = t("PROCESS_TAGS", { returnObjects: true });
  const [openSignDrawer, setOpenSignDrawer] = useState(null);
  const [createOfferDrawer, setCreateOfferDrawer] = useState(null);
  const [openSubscriptionDrawer, setOpenSubscriptionDrawer] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [project, setProject] = useState(null);
  const [search, setSearch] = useState({});
  const [open, setOpen] = useState(false);
  const [offers, setOffers] = useState([]);
  const [status, setStatus] = useState({});
  const [qualificationsFilter, setQualificationsFilter] = useState({});
  const [state, setState] = useState(false);

  const onCloseSubscription = () => {
    setOpenSubscriptionDrawer(null);
  };

  const onCloseSign = () => {
    setOpenSignDrawer(null);
  };

  const showDrawerSubscription = (id) => {
    setOpenSubscriptionDrawer(id);
  };

  const showDrawerSign = ({ id, statuses }) => {
    setOpenSignDrawer({ id, statuses });
  };

  const PAGINATION_OPTIONS = [
    { label: `10 ${LIST.FILTERS.PROJECTS}`, value: 10 },
    { label: `20 ${LIST.FILTERS.PROJECTS}`, value: 20 },
    { label: `50 ${LIST.FILTERS.PROJECTS}`, value: 50 },
    { label: `100 ${LIST.FILTERS.PROJECTS}`, value: 100 },
  ];

  return (
    <>
      <Header
        key={"header"}
        title={LIST.HEADER.TITLE}
        actions={[
          ...(actions[ACTIONS.ADD_PROJECT]
            ? [
                {
                  icon: <BiPlus size={20} />,
                  text: LIST.HEADER.CREATE.NORMAL,
                  onClick: () => setCreateOfferDrawer(true),
                },
              ]
            : []),
          ...(actions[ACTIONS.EXPORT_SUBSCRIPTIONS]
            ? [
                {
                  icon: <TbFileExport size={20} />,
                  text: LIST.HEADER.EXPORT_SUBSCRIPTIONS,
                  onClick: exportSubscriptions,
                  loading: exportLoading,
                },
              ]
            : []),
        ]}
      />
      <Card type="simple">
        <div className="filter__area">
          <div className="search--bar">
            <Input
              placeholder={LIST.FILTERS.CLIENT_LAST_NAME}
              onChange={({ target: { value } }) =>
                setSearch((prev) => ({ ...prev, clientLastName: value }))
              }
            />
            <Input
              placeholder={LIST.FILTERS.CLIENT_FIST_NAME}
              onChange={({ target: { value } }) =>
                setSearch((prev) => ({ ...prev, clientFirstName: value }))
              }
            />
            {user?.meta?.type === USER_TYPES.INTERNAL_SUPERVISOR.type && (
              <>
                <Input
                  placeholder={LIST.FILTERS.ADVISOR_LAST_NAME}
                  onChange={({ target: { value } }) =>
                    setSearch((prev) => ({ ...prev, advisorLastName: value }))
                  }
                />
                <Input
                  placeholder={LIST.FILTERS.ADVISOR_FIST_NAME}
                  onChange={({ target: { value } }) =>
                    setSearch((prev) => ({ ...prev, advisorFirstName: value }))
                  }
                />
              </>
            )}
            <Button
              type="secondary"
              onClick={() => onSearch(search)}
              icon={<FaSearch size={14} />}
              size={"full--w"}
            >
              {LIST.FILTERS.FILTER}
            </Button>
            <Filter
              translate={FILTER.OFFER}
              items={tracers.map(({ name }) => name)}
              setFilter={(offers) => {
                setOffers(offers);
                applyFilter({ offers, ...status });
              }}
            />
          </div>
          <div className="filter__search">
            <Filter
              translate={FILTER.PROCESS_TAG}
              items={Object.keys(PROCESS_TAGS)}
              setFilter={(items) => {
                setStatus({
                  expired: !!items.find(
                    (item) => item === PROCESS_TAGS_STATUSES.EXPIRED
                  ),
                  tags: items.filter(
                    (item) => item !== PROCESS_TAGS_STATUSES.EXPIRED
                  ),
                });
                applyFilter({
                  offers,
                  expired: !!items.find(
                    (item) => item === PROCESS_TAGS_STATUSES.EXPIRED
                  ),
                  tags: items.filter(
                    (item) => item !== PROCESS_TAGS_STATUSES.EXPIRED
                  ),
                });
              }}
            />
            <Select
              options={PAGINATION_OPTIONS}
              value={pagination.take || INITIAL_VALUES.take}
              onChange={(value) => {
                setPaginationHistory({
                  footprint: "projects",
                  value: Math.floor(pagination.skip / value) + 1,
                  take: value,
                  count: pagination.count,
                });
                setPagination((prev) => ({ ...prev, take: value }));
              }}
            />
          </div>
        </div>
      </Card>
      {contactId && (
        <ContactCard
          contactId={contactId}
          project={project}
          open={open}
          tracer={tracer}
          tracers={tracers}
          setOpen={setOpen}
        />
      )}
      <OfferDrawer
        setCreateOfferDrawer={setCreateOfferDrawer}
        createProject={createProject}
        open={createOfferDrawer}
        tracers={tracers}
      />
      <div className="m-bottom-24" />
      <Projects
        View={ProjectsTable}
        setValues={setPagination}
        skip={pagination.skip}
        filter={{ ...filter, ...pagination }}
        fetchPolicy="network-only"
        duplicate={duplicate}
        onDelete={onDelete}
        onDetail={onDetail}
        onAssign={onAssign}
        isAssigning={isAssigning}
        setTracer={setTracer}
        showDrawerSubscription={showDrawerSubscription}
        showDrawerSign={showDrawerSign}
        tracers={tracers}
        setContactId={setContactId}
        setProject={setProject}
        setOpen={setOpen}
        onChangeStatus={onChangeStatus}
        actions={actions}
        pagination={pagination}
      />
      {openSubscriptionDrawer && (
        <Subscriptions
          onChangeStatus={onChangeStatus}
          onClose={onCloseSubscription}
          project={openSubscriptionDrawer}
        />
      )}
      {openSignDrawer?.id && (
        <SignProjectDrawer onClose={onCloseSign} project={openSignDrawer} />
      )}
    </>
  );
};

export default List;
