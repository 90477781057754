import { useLazyQuery, useQuery } from "@apollo/client";
import { get } from "lodash";
import { useState } from "react";
import { OFFERS } from "utils/api/graphql/queries/offers";
import useInsurancePlans from "../../../Manual/Solyon/Comparison/Widgets/Upsells/hooks/useInsurancePlans";
import Loader from "../Components/Loader";
import View from "./View";

const ProtectIncome = ({ project, onNext, onBack }) => {
  const [selectedValue, setSelectedValue] = useState(
    get(project, "contact.fields", {})
  );

  const [getOffers, { loading: offersLoading }] = useLazyQuery(OFFERS);
  const { selectedInsurancePlans, fetchMainOffers, loading } =
    useInsurancePlans({
      insurancePlan: { id: get(project, "fields.selectedToCompare.0") },
      getOffers,
      project,
      offerWhere: [{ offerInsurancePlans: { weight: 2 } }],
    });
  useQuery(OFFERS, {
    variables: {
      where: {
        AND: [
          {
            offerInsurancePlans: {
              insurancePlan: { id: get(project, "fields.selectedToCompare.0") },
            },
          },
          { offerInsurancePlans: { main: true } },
        ],
      },
    },
    onCompleted: (offers) => {
      fetchMainOffers(offers);
    },
  });

  const onSubmit = ({ ignore = false }) => {
    const insurancePlanId = get(project, "fields.selectedToCompare.0");

    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id"),
          fields: selectedValue,
        },
        fields: {
          ...project.fields,
          upsells: {
            [insurancePlanId]: [
              ...get(project, `fields.upsells.${insurancePlanId}`, []).filter(
                ({ insurancePlan }) =>
                  !selectedInsurancePlans
                    ?.map((module) => get(module, "insurancePlan.id"))
                    ?.includes(insurancePlan?.id)
              ),
              ...(ignore ? [] : selectedInsurancePlans),
            ],
          },
        },
      },
    });
  };

  if (offersLoading || loading) return <Loader />;
  return (
    <View
      contact={get(project, "contact", {})}
      onNext={onSubmit}
      onBack={onBack}
      upsells={selectedInsurancePlans}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
    />
  );
};

export default ProtectIncome;
