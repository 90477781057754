import { PlusOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { get, isEmpty, orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import DatePicker from "shared/components/Form/DatePicker";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import {
  ATTACHMENT_TYPES,
  INSURANCE_PLAN_PARAMS_RULES,
  SUPPORTING_INFORMATION_CATEGORIES,
} from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import Documents from "../Blocks/Documents";
import Upsells from "../Blocks/Upsells";
import validation from "./validation";

const RULES_OPTIONS = [
  INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_SIM_OR_ESIM,
  INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_NEW_OR_GRADE_A,
  INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_PURCHASED_INTERNALLY,
  INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_UNDER_THREE_YEARS,
  INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_FOR_PRIVATE_USE,
  INSURANCE_PLAN_PARAMS_RULES.IS_CUSTOMER_IN_FRENCH_POLYNESIA,
];

const SUPPORTING_INFORMATION_OPTIONS = [
  SUPPORTING_INFORMATION_CATEGORIES.DESCRIPTION,
  SUPPORTING_INFORMATION_CATEGORIES.LIMIT,
  SUPPORTING_INFORMATION_CATEGORIES.INCLUDED,
  SUPPORTING_INFORMATION_CATEGORIES.EXCLUDED,
  SUPPORTING_INFORMATION_CATEGORIES.REIMBURSEMENT,
];

const View = ({
  onSubmit,
  cancel,
  data,
  attachments,
  addAttachment,
  deleteAttachment,
  offers,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const { startDate, endDate, params, supportingInformation, ...rest } = data;

  const handleFileUpload = async (file, setFieldValue) => {
    const { base64, size, uid, ...fileData } = file;
    setFieldValue("iconAttachment", {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue("icon", file.base64);
  };

  return (
    <Formik
      initialValues={{
        ...rest,
        rules: params[0]?.rules,
        date: [startDate, endDate],
        attachments,
        supportingInformation: orderBy(
          supportingInformation,
          ["category", "weight"],
          ["asc", "desc"]
        ),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({
        isValid,
        handleSubmit,
        setFieldValue,
        setValues,
        values,
        ...formProps
      }) => (
        <div className="grid--1">
          <Header
            key={"header"}
            title={DETAIL.HEADER.TITLE}
            subtitle={DETAIL.HEADER.ACTION}
            actions={[
              {
                text: DETAIL.HEADER.DISCARD,
                onClick: cancel,
                type: "primary--outlined",
                icon: <BiChevronLeft size={20} />,
              },
              {
                text: DETAIL.HEADER.SAVE,
                onClick: handleSubmit,
                type: "primary",
                icon: <BiCheck size={20} />,
              },
            ]}
          />
          <Card key={"generalInfo"} divider={false} title={FORM.GENERAL_INFO}>
            <div className="grid--1">
              <div className="grid--3">
                <div className="d-flex">
                  <span>{FORM.ACTIVE}</span>
                  <Switch
                    className="button__switch"
                    checkedChildren={FORM.YES}
                    unCheckedChildren={FORM.NO}
                    defaultChecked={get(values, "active")}
                    onChange={(e, _) => setFieldValue("active", e)}
                  />
                </div>
              </div>
              <div className="grid--2">
                <Input
                  placeholder={FORM.NAME}
                  label={FORM.NAME}
                  {...bindInputProps({ name: "name", values, ...formProps })}
                />
                <Input
                  placeholder={FORM.ALIAS}
                  label={FORM.ALIAS}
                  {...bindInputProps({ name: "alias", values, ...formProps })}
                />
                <Input
                  placeholder={FORM.DESCRIPTION}
                  label={FORM.DESCRIPTION}
                  {...bindInputProps({
                    name: "description",
                    values,
                    ...formProps,
                  })}
                />
                <DatePicker
                  placeholder={FORM.DATE}
                  label={FORM.DATE}
                  placeholders={[FORM.START_DATE, FORM.END_DATE]}
                  onChange={(_, dates) => setFieldValue("date", dates)}
                />

                <Input
                  type="file"
                  variant="uploader"
                  listType="picture"
                  showUploadList={false}
                  maxCount={1}
                  onUpload={([file]) => handleFileUpload(file, setFieldValue)}
                  fileList={
                    isEmpty(get(values, "icon", []))
                      ? []
                      : [get(values, "icon")]
                  }
                >
                  <Button type="primary--link">{FORM.ICON}</Button>
                  <div>
                    {get(values, "icon") && (
                      <img
                        className="icon"
                        alt="icon"
                        src={get(values, "icon")}
                      />
                    )}
                  </div>
                </Input>
              </div>
            </div>
          </Card>
          <Card divider={false} title={FORM.CONDITIONS.TITLE} key="rules">
            <FieldArray
              name={"rules"}
              render={(arrayHelpers) => (
                <div className="grid--1">
                  {get(values, "rules", []).map((_, i) => (
                    <div key={_.id} className="d-flex">
                      <div className="flex--grow-1">
                        <Select
                          placeholder={FORM.CHOOSE_RULE}
                          options={RULES_OPTIONS.map((value) => ({
                            label: FORM.ELIGIBILITY_CHECK[value],
                            value,
                          }))}
                          {...bindArrayInputProps({
                            parent: "rules",
                            index: i,
                            name: `name`,
                            values,
                            ...formProps,
                          })}
                        />
                      </div>
                      <Input
                        name={`rules.${i}.value`}
                        type="switch"
                        defaultChecked={get(values, `rules.${i}.value`)}
                        onChange={(e) => setFieldValue(`rules.${i}.value`, e)}
                      />
                      <BiTrash
                        color={COLORS.C_DANGER}
                        size={24}
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      />
                    </div>
                  ))}
                  <Button
                    type="primary"
                    key="add-row"
                    onClick={() => {
                      arrayHelpers.push({
                        id: cuid(),
                        value: false,
                        name: null,
                      });
                    }}
                  >
                    <PlusOutlined color={COLORS.C_WHITE} />
                  </Button>
                </div>
              )}
            />
          </Card>
          <Card
            title={FORM.SUPPORTING_INFORMATION.TITLE}
            divider={false}
            key="additional_info"
          >
            <FieldArray
              name={"supportingInformation"}
              render={(arrayHelpers) => (
                <div className="grid--1">
                  {get(values, "supportingInformation", [])?.map((_, i) => (
                    <div className="d-flex" key={`additional_info-${i}`}>
                      <div className="flex--grow-1 grid--4">
                        <Select
                          options={SUPPORTING_INFORMATION_OPTIONS.map(
                            (value) => ({
                              value,
                              label: FORM.SUPPORTING_INFORMATION.OPTIONS[value],
                            })
                          )}
                          label={FORM.SUPPORTING_INFORMATION.CATEGORY}
                          placeholder={FORM.SUPPORTING_INFORMATION.CATEGORY}
                          {...bindArrayInputProps({
                            parent: "supportingInformation",
                            index: i,
                            name: `category`,
                            values,
                            ...formProps,
                          })}
                        />
                        <Input
                          type="advanced"
                          label={FORM.SUPPORTING_INFORMATION.VALUE}
                          placeholder={FORM.SUPPORTING_INFORMATION.VALUE}
                          {...bindArrayInputProps({
                            parent: "supportingInformation",
                            index: i,
                            name: `value.mainInfo`,
                            values,
                            ...formProps,
                          })}
                        />
                        <Input
                          type="advanced"
                          label={FORM.SUPPORTING_INFORMATION.EXTRA_DETAIL}
                          placeholder={FORM.SUPPORTING_INFORMATION.EXTRA_DETAIL}
                          {...bindArrayInputProps({
                            parent: "supportingInformation",
                            index: i,
                            name: `value.extraDetail`,
                            values,
                            ...formProps,
                          })}
                        />
                        <Input
                          type="number"
                          label={FORM.SUPPORTING_INFORMATION.WEIGHT}
                          placeholder={FORM.SUPPORTING_INFORMATION.WEIGHT}
                          {...bindArrayInputProps({
                            parent: "supportingInformation",
                            index: i,
                            name: `weight`,
                            values,
                            ...formProps,
                          })}
                        />
                      </div>
                      <BiTrash
                        color={COLORS.C_DANGER}
                        size={24}
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      />
                    </div>
                  ))}

                  <Button
                    type="primary"
                    key="add-row"
                    onClick={() => {
                      arrayHelpers.push({ id: cuid(), category: null });
                    }}
                  >
                    <PlusOutlined color={COLORS.C_WHITE} />
                  </Button>
                </div>
              )}
            />
          </Card>
          <Upsells
            translation={FORM.UPSELLS}
            values={values}
            setFieldValue={setFieldValue}
            formProps={formProps}
          />
          <Documents
            values={values}
            setFieldValue={setFieldValue}
            formProps={formProps}
            translation={FORM}
            addAttachment={addAttachment}
            deleteAttachment={deleteAttachment}
          />
        </div>
      )}
    </Formik>
  );
};

export default View;
