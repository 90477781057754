import { Col, Row } from "antd";
import { get, isEmpty, set } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineFreeCancellation, MdOutlinePayment } from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import {
  ANSET_SUBSCRIPTION_FORM_KEYS,
  SUBSCRIBER_TYPES,
} from "utils/constants";

const Subscriber = ({
  setPercent,
  isFieldRequired,
  setDisabled,
  initialValues,
  errors,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const BLOCK = t("ANSET_SUBSCRIPTION.ADDITIONAL_INFORMATION", {
    returnObjects: true,
  });

  useEffect(() => {
    const subscriberType = get(
      initialValues,
      "additionalInfo.terminalUser.subscriberType"
    );
    const firstName = get(initialValues, "project.contact.user.firstname");
    const lastName = get(initialValues, "project.contact.user.lastname");

    if (subscriberType === SUBSCRIBER_TYPES.OTHER) return;

    if (
      firstName &&
      firstName !== initialValues?.additionalInfo?.terminalUser?.firstName
    ) {
      set(initialValues, "additionalInfo.terminalUser.firstName", firstName);
    }

    if (
      lastName &&
      lastName !== initialValues?.additionalInfo?.terminalUser?.lastName
    ) {
      set(initialValues, "additionalInfo.terminalUser.lastName", lastName);
    }
  }, [
    initialValues?.project?.contact?.user?.firstname,
    initialValues?.project?.contact?.user?.lastname,
  ]);

  const SUBSCRIBER_TYPE = {
    [SUBSCRIBER_TYPES.ADHERENT]: () => {
      set(initialValues, "additionalInfo.terminalUser", {
        firstName: get(initialValues, "project.contact.user.firstname"),
        lastName: get(initialValues, "project.contact.user.lastname"),
        subscriberType: SUBSCRIBER_TYPE.ADHERENT,
      });
      setDisabled((prev) => ({
        ...prev,
        additionalInfo: {
          ...prev?.additionalInfo,
          terminalUser: {
            ...prev?.additionalInfo?.terminalUser,
            firstName: true,
            lastName: true,
          },
        },
      }));
    },
    [SUBSCRIBER_TYPES.OTHER]: () => {
      set(initialValues, "additionalInfo.terminalUser", {
        firstName: null,
        lastName: null,
        subscriberType: SUBSCRIBER_TYPE.OTHER,
      });
      setDisabled((prev) => ({
        ...prev,
        additionalInfo: {
          ...prev?.additionalInfo,
          terminalUser: {
            ...prev?.additionalInfo?.terminalUser,
            firstName: false,
            lastName: false,
          },
        },
      }));
    },
  };

  return (
    <div
      className="subscription-form--wrapper"
      id={`${ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.SUBSCRIBER}-section`}
    >
      <Form
        type="vertical"
        title={BLOCK.SUBSCRIBER.TITLE}
        subtitle={BLOCK.SUBSCRIBER.SUBTITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              type="radio"
              variant="group"
              icon={<MdOutlinePayment size={16} />}
              label={BLOCK.SUBSCRIBER.FIELDS.SUBSCRIBER_TYPE.LABEL}
              values={Object.values(SUBSCRIBER_TYPES).map((value) => ({
                value,
                label: BLOCK.SUBSCRIBER.FIELDS.SUBSCRIBER_TYPE.OPTIONS[value],
              }))}
              value={get(
                initialValues,
                "additionalInfo.terminalUser.subscriberType"
              )}
              errors={errors["additionalInfo.terminalUser.subscriberType"]}
              touched={
                !isEmpty(errors["additionalInfo.terminalUser.subscriberType"])
              }
              onBlur={() => setPercent(initialValues)}
              onChange={(value) => {
                SUBSCRIBER_TYPE[value]();
                set(
                  initialValues,
                  "additionalInfo.terminalUser.subscriberType",
                  value
                );
              }}
              required={isFieldRequired(
                "additionalInfo.terminalUser.subscriberType"
              )}
            />
          </Col>
          {get(initialValues, "additionalInfo.terminalUser.subscriberType") ===
            SUBSCRIBER_TYPES.OTHER && (
            <Col lg={24} xs={24}>
              <Form type="highlight" title={BLOCK.SUBSCRIBER.FIELDS.LABEL}>
                <div className="grid--1">
                  <Input
                    placeholder={BLOCK.SUBSCRIBER.FIELDS.FIRST_NAME.PLACEHOLDER}
                    label={BLOCK.SUBSCRIBER.FIELDS.FIRST_NAME.LABEL}
                    icon={<MdOutlineFreeCancellation size={16} />}
                    defaultValue={get(
                      initialValues,
                      "additionalInfo.terminalUser.firstName"
                    )}
                    errors={errors["additionalInfo.terminalUser.firstName"]}
                    touched={
                      !isEmpty(errors["additionalInfo.terminalUser.firstName"])
                    }
                    onChange={({ target: { value } }) => {
                      set(
                        initialValues,
                        "additionalInfo.terminalUser.firstName",
                        value
                      );
                    }}
                    onBlur={() => setPercent(initialValues)}
                    required={isFieldRequired(
                      "additionalInfo.terminalUser.firstName"
                    )}
                  />
                  <Input
                    placeholder={BLOCK.SUBSCRIBER.FIELDS.LAST_NAME.PLACEHOLDER}
                    label={BLOCK.SUBSCRIBER.FIELDS.LAST_NAME.LABEL}
                    icon={<MdOutlineFreeCancellation size={16} />}
                    defaultValue={get(
                      initialValues,
                      "additionalInfo.terminalUser.lastName"
                    )}
                    errors={errors["additionalInfo.terminalUser.lastName"]}
                    touched={
                      !isEmpty(errors["additionalInfo.terminalUser.lastName"])
                    }
                    onChange={({ target: { value } }) => {
                      set(
                        initialValues,
                        "additionalInfo.terminalUser.lastName",
                        value
                      );
                    }}
                    onBlur={() => setPercent(initialValues)}
                    required={isFieldRequired(
                      "additionalInfo.terminalUser.lastName"
                    )}
                  />
                </div>
              </Form>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default Subscriber;
