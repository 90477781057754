import * as yup from "yup";

const validation = (errors) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);
  return yup.object().shape({
    contact: yup.object().shape({
      fields: yup.object().shape({
        profile: requiredString,
      }),
    }),
  });
};

export default validation;
