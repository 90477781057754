const Progress = ({ value }) => {
  return (
    <div className="progress">
      <span className="progress--line" style={{ width: value }}></span>
      <span
        className="progress--indicator"
        style={{ left: "calc(" + value + " - 50px)" }}
      >
        {value}
      </span>
    </div>
  );
};

export default Progress;
