import Progress from "./Progress";
import Logo from "./svg/lmf-logo.svg";
const Header = ({ progressValue, View }) => {
  return (
    <>
      <div className="header">
        <img src={Logo} alt="LMF logo" />
        <Progress value={progressValue} />
        <div className="right">{View}</div>
      </div>
    </>
  );
};

export default Header;
