import { Button as AntButton } from "antd";
import PropTypes from "prop-types";

const ALIGNMENTS = ["left", "center", "right"];

const TYPES = [
  "default",
  "primary",
  "secondary",
  "primary-light",
  "gray-light",
];

const Button = ({
  type,
  alignment,
  children,
  size,
  width,
  className,
  ...rest
}) => (
  <div className={`solyon-button ${alignment} ${className}`}>
    <AntButton className={[type, size]} style={{ width }} {...rest}>
      {children}
    </AntButton>
  </div>
);

Button.propTypes = {
  alignment: PropTypes.oneOf(ALIGNMENTS),
  type: PropTypes.oneOf(TYPES),
};

Button.defaultProps = {
  alignment: "left",
  type: "default",
};

export default Button;
