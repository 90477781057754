import { Col, Row } from "antd";
import dayjs from "dayjs";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineSubtitles,
} from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import {
  DATE_FORMAT,
  GENDER,
  RELATIONSHIP,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";

const Partner = ({
  formProps,
  values,
  setFieldValue,
  setPercent,
  isFieldRequired,
  errors,
  initialValues,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });
  const birthDate = get(
    initialValues,
    "spouse.identity.user.birthDate",
    undefined
  );
  return (
    get(initialValues, "project.contact.relatedPersons", []).find(
      (person) => person.relationship === RELATIONSHIP.SPOUSE
    ) && (
      <div
        className="subscription-form--wrapper"
        id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PARTNER}-section`}
      >
        <Form
          type="vertical"
          title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PARTNER.TITLE}
          subtitle={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PARTNER.SUBTITLE}
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.BIRTH_NAME.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.BIRTH_NAME.LABEL}
                icon={<MdOutlineBadge size={16} />}
                defaultValue={get(
                  initialValues,
                  "spouse.identity.user.birthName"
                )}
                errors={errors["spouse.identity.user.birthName"]}
                touched={!isEmpty(errors["spouse.identity.user.birthName"])}
                onChange={({ target: { value } }) => {
                  set(initialValues, "spouse.identity.user.birthName", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("spouse.identity.user.birthName")}
                optional={!isFieldRequired("spouse.identity.user.birthName")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
                icon={<MdOutlineBadge size={16} />}
                defaultValue={get(
                  initialValues,
                  "spouse.identity.user.lastname"
                )}
                errors={errors["spouse.identity.user.lastname"]}
                touched={!isEmpty(errors["spouse.identity.user.lastname"])}
                onChange={({ target: { value } }) => {
                  set(initialValues, "spouse.identity.user.lastname", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("spouse.identity.user.lastname")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
                icon={<MdOutlineBadge size={16} />}
                placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                defaultValue={get(
                  initialValues,
                  "spouse.identity.user.firstname"
                )}
                errors={errors["spouse.identity.user.firstname"]}
                touched={!isEmpty(errors["spouse.identity.user.firstname"])}
                onChange={({ target: { value } }) => {
                  set(initialValues, "spouse.identity.user.firstname", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("spouse.identity.user.firstname")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                type="radio"
                variant="group"
                label={SUBSCRIPTION.FORM.GENDER.LABEL}
                values={[
                  {
                    label: SUBSCRIPTION.FORM.GENDER.OPTIONS.MR,
                    value: GENDER.MALE,
                  },
                  {
                    label: SUBSCRIPTION.FORM.GENDER.OPTIONS.MRS,
                    value: GENDER.FEMALE,
                  },
                ]}
                icon={<MdOutlineBadge size={16} />}
                value={get(initialValues, "spouse.identity.user.gender")}
                errors={errors["spouse.identity.user.gender"]}
                touched={!isEmpty(errors["spouse.identity.user.gender"])}
                onChange={(value) => {
                  set(initialValues, "spouse.identity.user.gender", value);
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired("spouse.identity.user.gender")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.LABEL}
                icon={<MdOutlineSubtitles size={16} />}
                placeholder={
                  SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.PLACEHOLDER
                }
                defaultValue={get(
                  initialValues,
                  "spouse.identity.socialSecurityNumber"
                )}
                errors={errors["spouse.identity.socialSecurityNumber"]}
                touched={
                  !isEmpty(errors["spouse.identity.socialSecurityNumber"])
                }
                onChange={({ target: { value } }) => {
                  set(
                    initialValues,
                    "spouse.identity.socialSecurityNumber",
                    value
                  );
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired(
                  "spouse.identity.socialSecurityNumber"
                )}
                optional={
                  !isFieldRequired("spouse.identity.socialSecurityNumber")
                }
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.BIRTH_DATE.LABEL}
                icon={<MdOutlineCake size={16} />}
                placeholder={SUBSCRIPTION.FORM.BIRTH_DATE.PLACEHOLDER}
                defaultValue={birthDate && dayjs(birthDate)}
                type="date"
                variant="simple"
                format={DATE_FORMAT}
                disabled
                required={isFieldRequired("spouse.identity.user.birthDate")}
              />
            </Col>
          </Row>
        </Form>
      </div>
    )
  );
};

export default Partner;
