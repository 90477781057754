import { Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import EmailSent from "./EmailSent";
import View from "./View";

const HelpModal = ({ open, setOpen }) => {
  const [submit, setSubmit] = useState(false);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.HELP_MODAL", { returnObjects: true });

  const initialValues = {
    contact: {
      fields: {
        acceptDigital: false,
        acceptContact: false,
      },
    },
  };

  const onSubmit = (values) => {
    setSubmit(true);
    // console.log(values);
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      width={submit ? 520 : 720}
      footer={null}
      className="LMF-help-modal"
    >
      {submit ? (
        <EmailSent setOpen={setOpen} TRANSLATION={TRANSLATION} />
      ) : (
        <View
          setOpen={setOpen}
          onSubmit={onSubmit}
          initialValues={initialValues}
          TRANSLATION={TRANSLATION}
          errorMessages={t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
            returnObjects: true,
          })}
        />
      )}
    </Modal>
  );
};

export default HelpModal;
