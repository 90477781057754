import { useState } from "react";
import { useMutation } from "shared/hooks/useApi";
import { EXPORT_SUBSCRIPTIONS } from "utils/api/graphql/mutations/subscription";
import { CONTENT_TYPES, SUBSCRIPTION_STATUSES } from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import View from "./View";

const List = () => {
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({
    where: {
      status: SUBSCRIPTION_STATUSES.SIGNED,
    },
  });

  const [exportSubscriptions, { loading: exportLoading }] = useMutation(
    EXPORT_SUBSCRIPTIONS,
    {
      variables: {
        where: {
          status: SUBSCRIPTION_STATUSES.SIGNED,
        },
      },
    }
  );

  const handleExportSubscriptions = async () => {
    const { data } = await exportSubscriptions();
    downloadDocument({
      base64: data.exportSubscriptions,
      contentType: CONTENT_TYPES.XLSX,
      name: "subscriptions.xlsx",
    });
  };

  const onSearch = (target) => {
    setPagination((prev) => ({ ...prev, skip: 0 }));
    setFilter((prev) => ({
      ...prev,
      like: {
        ...prev.like,
        OR: [
          {
            project: {
              contact: {
                user: {
                  firstname: `%${target.value}%`,
                },
              },
            },
          },
          {
            project: {
              contact: {
                user: {
                  lastname: `%${target.value}%`,
                },
              },
            },
          },
        ],
      },
    }));
  };

  return (
    <View
      filter={filter}
      pagination={pagination}
      setPagination={setPagination}
      onSearch={onSearch}
      exportLoading={exportLoading}
      exportSubscriptions={handleExportSubscriptions}
    />
  );
};

export default List;
